import React, { FC } from 'react';
import { scheduleFormConfig } from './constants';
import { FormField } from '~/components/FormField';
import { FormInputConfig } from '~/types/residentManagement';
import { IScheduleForm } from './interfaces';
import { useForm } from 'react-hook-form';
import Spacer from '~/components/Spacer/Spacer';
import { toast } from 'react-toastify';
import { IScheduleDetails } from '~/api/questionnair';
import moment from 'moment';

interface IProps {
  onRescheduleSubmit: (formData: IScheduleForm) => void;
  selectedScheduleDetails: IScheduleDetails | null;
  onCancel: () => void;
}

export const ScheduleForm: FC<IProps> = ({
  onRescheduleSubmit,
  selectedScheduleDetails,
  onCancel
}) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isDirty }
  } = useForm<IScheduleForm>({
    defaultValues: selectedScheduleDetails
      ? {
          uuid: selectedScheduleDetails.uuid,
          questionnaire: selectedScheduleDetails.questionnaire,
          schedule_option: selectedScheduleDetails.schedule_option,
          schedule_time: moment(selectedScheduleDetails.schedule_time).format(
            'YYYY-MM-DD'
          ),
          emailBody: selectedScheduleDetails.emailBody,
          emailSubject: selectedScheduleDetails.emailSubject
        }
      : {}
  });

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='float-spinner-parent'>
      <form
        id='form-container'
        onSubmit={handleSubmit((formData) => {
          if (!isDirty) {
            toast('please edit the form');

            return;
          }

          onRescheduleSubmit(formData);
        })}
      >
        <div>{scheduleFormConfig.map((field) => generateField(field))}</div>
        <Spacer height={20} />
        <div className='formButtons'>
          {selectedScheduleDetails && isDirty && (
            <button
              className='btn-standard btn-secondary'
              onClick={(event) => {
                event.preventDefault();
                reset();
              }}
            >
              {'Reset'}
            </button>
          )}
          <button
            className='btn-standard btn-secondary margin-left-16'
            onClick={(event) => {
              event.preventDefault();

              onCancel();
            }}
          >
            {'Cancel'}
          </button>
          <button
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          >
            {selectedScheduleDetails ? 'Update' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};
