import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import {
  fetchBuildingDetails,
  updateBuilding,
  updateBuildingImage,
  updateDashboardConfig,
  addGatheringTemplates,
  removeGatheringTemplates
} from '~/api/building';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import ImageDropzone from '~/components/ImageDropzone/ImageDropzone';
import Spinner from '~/components/Spinner/Spinner';
import { qrCodeGeneratorURL } from '~/helpers/constants';
import useIsMounted from '~/hooks/isMounted';
// @ts-ignore
import Switch from 'react-ios-switch';

import {
  BuildingDetails,
  UpdateBuildingDashboardConfig
} from '~/types/building';
import classes from './EditBuildingContainer.module.css';
import { RootState } from '~/redux/store';
import { connect } from 'react-redux';
import { Roles } from '~/enums/Roles';
import { useBuildingDashboardConfig } from '~/hooks/useBuildingDashboardConfig';
import GenericModal from '~/components/GenericModal/GenericModal';
import { EditBuildingSetting } from './EditBuildingSettings/EditBuildingSetting';
import Spacer from '~/components/Spacer/Spacer';
import { EditReviewSetting } from './EditBuildingSettings/EditReviewSetting';
import { EditBuildingKeys } from './EditBuildingKeys';
import useBuildingMobileConfig from '~/hooks/building/useBuildingMobileConfig';

type IProps = ReturnType<typeof mapStateToProps>;

const EditBuildingContainer = (props: IProps) => {
  const { userRole } = props;
  const { buildingId } = useParams<{ buildingId: string }>();
  const [buildingDetails, setBuildingDetails] = useState({} as BuildingDetails);
  const [image, setImage] = useState({} as File);
  const [loading, setLoading] = useState(true);
  const [showImageSpinner, setShowImageSpinner] = useState(false);
  const [snippetsText, setSnippetsText] = useState(false);
  const history = useHistory();

  const {
    hidePerks,
    showGoogleReview,
    updateBuildingConfigs,
    loadingMobileSettings
  } = useBuildingMobileConfig(buildingId);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showEditSetting = queryParams.get('showEditSetting') || false;

  const showEditBuildingSetting =
    userRole === Roles.Vcm || userRole === Roles.Superadmin;

  const isMounted = useIsMounted();

  const getValidCode = (): any => {
    return buildingDetails.codes.filter((c) => c.valid)[0];
  };

  const getValidBranchCode = (): any => {
    return buildingDetails.deeplink;
  };

  const queryClient = useQueryClient();
  const { data } = useBuildingDashboardConfig(buildingId);

  const { mutate: updateBDC, isLoading } = useMutation(
    async (bdc: UpdateBuildingDashboardConfig) => {
      const isGatheringToggled =
        data?.data?.config?.showGatheringTemplates !==
        bdc.showGatheringTemplates;

      if (!isGatheringToggled) {
        return updateDashboardConfig(buildingId, bdc);
      }

      await (bdc.showGatheringTemplates
        ? addGatheringTemplates(buildingId)
        : removeGatheringTemplates(buildingId));

      return updateDashboardConfig(buildingId, bdc);
    },
    {
      onSuccess: async (buildingDashboadConfigUpdated) => {
        queryClient.setQueryData(
          ['buildingDashboardConfig', buildingId],
          buildingDashboadConfigUpdated
        );

        toast('Building config has been successfully changed!');
      },
      onError: (err: Error) => {
        toast(err?.message || 'Something went wrong! Please try again later.');
      }
    }
  );

  const fetchBuilding = (): Promise<void> => {
    return fetchBuildingDetails(buildingId).then((res) => {
      if (isMounted()) {
        setBuildingDetails(res.data);
        setImage(res.data.image);
        setLoading(false);
        setSnippetsText(res.data.showCommentSnippets);
      }
    });
  };

  useEffect(() => {
    fetchBuilding();
  }, [buildingId, isMounted]);

  const onEditBuildingEmail = () => {
    history.push(`/building/${buildingId}/edit/emails`);
  };

  const onEditBuildingAddress = () => {
    history.push(`/building/${buildingId}/edit/address`);
  };

  const onEditBuildingImages = () => {
    history.push(`/building/${buildingId}/edit/images`);
  };

  const hasBuildingData = !!Object.keys(buildingDetails).length;

  return (
    <div className={`content-container`}>
      <Breadcrumbs />
      <div className={classes.headingContainer}>
        <h1 className='h1-title'>Building Settings</h1>
        {showEditBuildingSetting && hasBuildingData && (
          <div className={classes.headingContainer}>
            <EditBuildingKeys
              buildingId={buildingId}
              onEditBuilding={fetchBuilding}
              showEditSetting={showEditSetting as boolean}
            />
            <Spacer width={20} />
            <button
              className='btn-standard btn-primary'
              onClick={onEditBuildingAddress}
            >
              Edit Building Address
            </button>
            <Spacer width={20} />
            <button
              className='btn-standard btn-primary'
              onClick={onEditBuildingImages}
            >
              Edit Building Images
            </button>
            <Spacer width={20} />
            <button
              className='btn-standard btn-primary'
              onClick={onEditBuildingEmail}
            >
              Edit Building Emails
            </button>
            <Spacer width={20} />
            <EditBuildingSetting
              buildingId={buildingId}
              building={buildingDetails}
              onEditBuilding={fetchBuilding}
              showEditSetting={showEditSetting as boolean}
            />
            <Spacer width={20} />
            <EditReviewSetting
              building={buildingDetails}
              onEditBuilding={fetchBuilding}
            />
          </div>
        )}
      </div>
      {loading && <Spinner />}
      {!loading && (
        <div className={`${classes.container}`}>
          <div>
            <p className='small-text bold'>BUILDING</p>
            <Spacer height={8} />
            <p className='small-text '>Building Code</p>
            <Spacer height={8} />
            <div className='display-flex'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: '64px',
                  gap: '16px'
                }}
              >
                {(userRole === Roles.Vcm || userRole === Roles.Superadmin) &&
                  getValidBranchCode() && (
                    <div
                      className={`${classes.content} ${classes.newQRCode} margin-right-32`}
                    >
                      <p className='font-14 light-grey-text margin-top-8'>
                        QR code
                      </p>
                      <Spacer height={8} />
                      <div className={classes.codeWrap}>
                        <div>
                          <img
                            className={classes.qrCode}
                            src={qrCodeGeneratorURL(getValidBranchCode())}
                            alt='New QR Code'
                          />
                        </div>
                        <div className={classes.CodeDetail}>
                          <p className='small-text bold'>
                            <strong>
                              NEW QR Code. USE ON ALL MARKETING MATERIAL.
                            </strong>
                          </p>
                          <p className='small-text'>
                            Building Code:{' '}
                            <strong>{getValidCode().code}</strong>
                          </p>
                          <p className='small-text'>
                            Magic link:&nbsp;
                            <strong>
                              <a href={getValidBranchCode()}>
                                {getValidBranchCode()}
                              </a>
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                {/* Original QR Code Section - Visible to VCM & Superadmin */}

                <div
                  className={`${classes.content} ${classes.originalQRCode} margin-right-32`}
                >
                  <p className='font-14 light-grey-text margin-top-8'>
                    QR code
                  </p>
                  <Spacer height={8} />
                  <div className={classes.codeWrap}>
                    <div>
                      <img
                        className={classes.qrCode}
                        src={qrCodeGeneratorURL(getValidCode().link)}
                        alt='Original QR Code'
                      />
                    </div>
                    <div className={classes.CodeDetail}>
                      <p className='small-text bold'>
                        <strong>
                          Original QR Code. Only use for historical purposes.
                        </strong>
                      </p>
                      <p className='small-text'>
                        Building Code: <strong>{getValidCode().code}</strong>
                      </p>
                      <p className='small-text'>
                        Magic link:&nbsp;
                        <strong>
                          <a href={getValidCode().link}>
                            {getValidCode().link}
                          </a>
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className='font-14 light-grey-text'>Add a Photo</p>
                <ImageDropzone
                  image={image}
                  spinner={showImageSpinner}
                  handleDrop={async (acceptedFiles: File[]) => {
                    setShowImageSpinner(true);
                    updateBuildingImage(
                      { image: acceptedFiles[0] },
                      buildingId
                    ).then(() => {
                      toast('Image succesfully updated.');
                      setImage(acceptedFiles[0]);
                    });
                    setShowImageSpinner(false);
                  }}
                />
              </div>
            </div>
          </div>
          {(userRole === Roles.Vcm || userRole === Roles.Superadmin) && (
            <div>
              <p className='small-text bold'>PLAN</p>
              <div className={`display-flex`}>
                <div className={classes.toggleContainer}>
                  <Switch
                    checked={data?.data.config.isCobuPlus}
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        isCobuPlus: currentSwitchState
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Cobu Plus</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(userRole === Roles.Vcm || userRole === Roles.Superadmin) && (
            <div>
              <p className='small-text bold'>COMMENTS</p>
              <div className={`display-flex`}>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={snippetsText}
                    onChange={() => {
                      const currentSnippetsText = snippetsText;
                      setSnippetsText((prevState) => !prevState);
                      updateBuilding(
                        {
                          showCommentSnippets: !currentSnippetsText
                        },
                        buildingId
                      )
                        .then(() => {
                          toast('Snippets succesfully updated.');
                        })
                        .catch((e) => {
                          toast(
                            'Something went wrong! Please try again later.'
                          );
                          setSnippetsText(currentSnippetsText);
                        });
                    }}
                  />
                  <div className='margin-left-16'>
                    <p>Show comment snippets in push notifications</p>
                  </div>
                </div>
                <div className={classes.toggleContainer}>
                  <Switch
                    checked={!data?.data.config.showDeletedCommentsToPM}
                    onChange={(currentSwitchState: boolean) => {
                      if (currentSwitchState) {
                        updateBDC({
                          showDeletedCommentsToPM: !currentSwitchState,
                          showSentimentDataToPM: false
                        });
                      } else {
                        updateBDC({
                          showDeletedCommentsToPM: !currentSwitchState
                        });
                      }
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Hide hidden and deleted comments for PM role</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(userRole === Roles.Vcm || userRole === Roles.Superadmin) && (
            <div>
              <p className='small-text bold'>REVIEWS</p>
              <div className={`display-flex`}>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={data?.data.config.showGoogleReviewLinkInApp}
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        showGoogleReviewLinkInApp: currentSwitchState
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Show Google Review Link in the Me Section</p>
                  </div>
                </div>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={data?.data.config.showReviewsToPM}
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        showReviewsToPM: currentSwitchState
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Display reviews to PM roles</p>
                  </div>
                </div>
                <div className={classes.toggleContainer}>
                  <Switch
                    checked={data?.data.config.autoSendReviews}
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        autoSendReviews: currentSwitchState
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Auto invite Residents to leave Google reviews</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(userRole === Roles.Vcm || userRole === Roles.Superadmin) &&
            data?.data.config.showDeletedCommentsToPM && (
              <div>
                <p className='small-text bold'>SENTIMENT</p>
                <div className={`display-flex`}>
                  <div className={`${classes.toggleContainer} margin-right-24`}>
                    <Switch
                      checked={data?.data.config.showSentimentDataToPM}
                      onChange={(currentSwitchState: boolean) => {
                        updateBDC({
                          showSentimentDataToPM: currentSwitchState
                        });
                      }}
                      disabled={isLoading}
                    />
                    <div className='margin-left-16'>
                      <p>Display sentiment data to PM role</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {(userRole === Roles.Vcm || userRole === Roles.Superadmin) && (
            <div>
              <p className='small-text bold'>GATHERINGS</p>
              <div className={`display-flex margin-bottom-24`}>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={data?.data.config.showGatheringTemplates}
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        showGatheringTemplates: currentSwitchState,
                        gatheringTemplateLevel: true
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Allow PM role to see gathering templates</p>
                  </div>
                </div>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={data?.data.config.showGatheringEventReminder}
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        showGatheringEventReminder: currentSwitchState
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Allow gathering reminders</p>
                  </div>
                </div>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={data?.data.config.allowFlyerRequest}
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        allowFlyerRequest: currentSwitchState
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Allow gathering flyer request</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(userRole === Roles.Vcm || userRole === Roles.Superadmin) && (
            <div>
              <p className='small-text bold'>APP SETTINGS</p>
              <div className={`display-flex margin-bottom-32`}>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={!hidePerks}
                    onChange={(currentSwitchState: boolean) => {
                      updateBuildingConfigs({
                        hidePerks: !currentSwitchState,
                        showGoogleReview
                      });
                    }}
                    disabled={loadingMobileSettings}
                  />
                  <div className='margin-left-16'>
                    <p>Show Local in Mobile App</p>
                  </div>
                </div>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={showGoogleReview}
                    onChange={(currentSwitchState: boolean) => {
                      updateBuildingConfigs({
                        hidePerks,
                        showGoogleReview: currentSwitchState
                      });
                    }}
                    disabled={loadingMobileSettings}
                  />
                  <div className='margin-left-16'>
                    <p>Show Google Review Prompt in Gathering Feedback</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(userRole === Roles.Vcm || userRole === Roles.Superadmin) && (
            <div>
              <p className='small-text bold'>EMAILS</p>
              <div className={`display-flex margin-bottom-32`}>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={data?.data.config.enableBuildingReport}
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        enableBuildingReport: currentSwitchState
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Send B2B Monthly Report Emails</p>
                  </div>
                </div>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={data?.data.config.enableBuildingDigestEmail}
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        enableBuildingDigestEmail: currentSwitchState
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Send B2C Digest Emails</p>
                  </div>
                </div>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={
                      data?.data.config?.hasOwnProperty('enableNoEventReminder')
                        ? data?.data.config.enableNoEventReminder
                        : true
                    }
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        enableNoEventReminder: currentSwitchState
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Enable No Event Reminder - Monthly (2nd Tuesday)</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(userRole === Roles.Vcm || userRole === Roles.Superadmin) && (
            <div>
              <p className='small-text bold'>PERKS</p>
              <div className={`display-flex margin-bottom-32`}>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={data?.data.config.showPerks}
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        showPerks: currentSwitchState
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Enable Perks for the building</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {userRole === Roles.Superadmin && (
            <div>
              <p className='small-text bold'>UGC</p>
              <div className={`display-flex margin-bottom-32`}>
                <div className={`${classes.toggleContainer} margin-right-24`}>
                  <Switch
                    checked={data?.data.config.showUGC}
                    onChange={(currentSwitchState: boolean) => {
                      updateBDC({
                        showUGC: currentSwitchState
                      });
                    }}
                    disabled={isLoading}
                  />
                  <div className='margin-left-16'>
                    <p>Enable User Generated Content</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    userRole: state.auth.userRole
  };
}

export default connect(mapStateToProps)(EditBuildingContainer);
