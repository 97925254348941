import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { CellProps } from 'react-table';
import { SelectType } from '~/enums/SelectType';
import CobuSelect from '../CobuSelect/CobuSelect';
import CobuTable from '../CobuTable/CobuTable';
import classes from './AdminDetails.module.css';
import { SelectedOptionType } from '~/types/form';
import { AdminBuildingType } from '~/types/admin';

interface IProps {
  data: AdminBuildingType[];
  role: string;
  onAddBuilding: (buildingId: string) => void;
  onRemoveBuilding: (buildingId: string) => void;
  buildingsForDropdown: SelectedOptionType[];
}
const AdminDetails = (props: IProps) => {
  const {
    data,
    role,
    buildingsForDropdown,
    onRemoveBuilding,
    onAddBuilding
  } = props;

  const [selectedBuildingId, setSelectedBuildingId] = useState<string>('');
  const history = useHistory();
  const tableData = React.useMemo(() => data, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'BUILDING',
        accessor: 'building',
        Cell: (cell: CellProps<AdminBuildingType>) => (
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {cell.row.original.name}
          </div>
        )
      },
      {
        Header: 'ROLE',
        accessor: 'role',
        Cell: (cell: CellProps<AdminBuildingType>) => (
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            <div className={classes.roleContainer}>{role}</div>
          </div>
        )
      },
      {
        id: 'details',
        Cell: (cell: CellProps<AdminBuildingType>) => (
          <div
            className={`pointer ${classes.deleteButton}`}
            onClick={() => onRemoveBuilding(cell.row.original.uuid)}
          >
            REMOVE
          </div>
        )
      }
    ],
    [history, data]
  );
  return (
    <div>
      <div style={{ maxHeight: '700px', overflow: 'scroll' }}>
        {tableData && tableData.length > 0 && (
          <CobuTable columns={[...columns]} data={tableData} />
        )}
      </div>
      <div className={classes.selectBuildingContainer}>
        <div className={`${classes.selectContainer} height-50`}>
          <CobuSelect
            options={buildingsForDropdown}
            onChange={(option: SelectedOptionType | null) => {
              setSelectedBuildingId(option!.value);
            }}
            selectType={SelectType.Form}
          />
        </div>
        <button
          className={`btn-standard btn-secondary margin-left-16 pointer`}
          onClick={() => {
            onAddBuilding(selectedBuildingId);
          }}
          disabled={!selectedBuildingId}
        >
          Add new building
        </button>
      </div>
    </div>
  );
};
export default AdminDetails;
