
import {
  convertKeyValueToFormDataV2
} from '~/helpers/helpers';
import { AxiosResponse } from 'axios';
import { emperor } from '~/services';
import {
  GatheringTemplate,
  GatheringTemplateDTO,
  IEventWithFlyer,
  IFlyerStatuses,
  IFylerTemplate,
  RecurringType
} from '~/types/gathering';

export const getRecurringTypes = async () => {
  return await emperor
    .get<RecurringType[]>(`/recurringTypes`)
    .then((res) => res.data);
};

export const getGatheringTemplate = async (templateId: string) => {
  return await emperor.get(`gathering-template/${templateId}`);
};

export const getGatheringTemplates = async (
  buildingId: string
): Promise<GatheringTemplate[]> => {
  const res = await emperor.get(`building/gathering-template/${buildingId}`);
  return res.data;
};

export const createGatheringTemplate = async (
  templateData: GatheringTemplateDTO
): Promise<GatheringTemplate> => {
  return await emperor.post(
    'gathering-template',
    convertKeyValueToFormDataV2(templateData)
  );
};

export const updateGatheringTemplate = async ({
  templateId,
  templateData
}: {
  templateId: string;
  templateData: GatheringTemplateDTO;
}): Promise<GatheringTemplate> => {
  return await emperor.patch(
    `gathering-template/${templateId}`,
    convertKeyValueToFormDataV2(templateData)
  );
};

export const deleteGatheringTemplate = async (
  templateId: string
): Promise<void> => {
  return await emperor.delete(`gathering-template/${templateId}`);
};

export const getAllGatheringEventsWithFlyer = async (): Promise<{
  data: IEventWithFlyer[];
}> => {
  return await emperor.get(`flyer/events-with-flyer`);
};

export const patchFlyer = async (data: FormData): Promise<void> => {
  return await emperor.patch(`flyer/patch-flyer`, data);
};

export const getFlyerStatuses = async (): Promise<{
  data: IFlyerStatuses[];
}> => {
  return await emperor.get(`flyer/flyer-statuses`);
};

export const postFlyersOnsite = async (data: FormData): Promise<void> => {
  return await emperor.post('flyer/email-flyer-onsite', data);
};

export const getFlyerTemplates = async (
  templateType: string
): Promise<{ data: IFylerTemplate[] }> => {
  return await emperor.get('flyer/flyer-templates', {
    params: { templateType: templateType }
  });
};

export const getEmailsByFlyerGroup = async (): Promise<AxiosResponse<any>> => {
  return await emperor.get('flyer/get-emails-by-flyer-group');
};
