import React from 'react';

import { useParams } from 'react-router-dom';

import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spacer from '~/components/Spacer/Spacer';
import CobuConstants from '~/helpers/cobuConstants';
import style from './ResidentProfile.module.css';
import MoreActions from '~/components/MoreActions/MoreActions';

import Spinner from '~/components/Spinner/Spinner';
import CreateResidentForm from '~/components/CreateResidentForm/CreateResidentForm';
import GenericModal from '~/components/GenericModal/GenericModal';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';
import { getEditUserFormConfig } from '~/helpers/formHelpers';
import CobuSwitchList from '~/components/SwitchList/CobuSwitchList';
import useResidentProfile from '~/hooks/resident/useResidentProfile';
import moment from 'moment';
import CobuPaginatedTable from '~/components/CobuPaginatedTable/CobuPaginatedTable';
import CobuTable from '~/components/CobuTable/CobuTable';
import { ReactComponent as NotFound } from '~/utils/images/not-found.svg';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/store';
import { Roles } from '~/enums/Roles';

const ResidentProfile = () => {
  /* Params */
  const { buildingId, residentId } = useParams<{
    buildingId: string;
    residentId: string;
  }>();
  const selectedBuilding = getBuildingFromStore(buildingId);
  const { userRole } = useSelector((state: RootState) => ({
    userRole: state.auth.userRole
  }));


  /* Data logics */
  const {
    residentProfileState,

    updateResidentData,
    deleteProfilePic,
    showHideUserEditModal,

    commentTableConfig,
    rsvpTableConfig,
    notifTableConfig,
    loading,
    syncIntercomUserReferralDetails,
    referralLink
  } = useResidentProfile(residentId, buildingId);

  const {
    groups,
    createdAt,
    profileImage,
    displayName,
    email,
    phone,
    roomNumber,
    likesHelpingWith,
    needsHelpWith,
    bio,
    comments,
    residentRemoved
  } = residentProfileState.residentData;

  /* UI Components */
  const profileHeader = () => {
    return (
      <div className={`${style.spaceBtw} ${style.row}`}>
        <div className={`${style.row}`}>
          <img
            src={profileImage}
            alt={CobuConstants.residentProfile.profile}
            className={style.profileImage}
          />
          <Spacer width={10} />
          <div className={`${style.alignCenter}`}>
            <p className='small-text bold'>{displayName}</p>
            <p className='small-text '>{email}</p>
          </div>
        </div>
        <div className={`${style.phoneDetailsView} ${style.spaceBtw}`}>
          <div className={`${style.row}`}>
            <p className='small-text '>{`${CobuConstants.residentProfile.phoneNumber}`}</p>
            <Spacer width={10} />
            <p className='small-text bold'>{`${phone}`}</p>
          </div>
          <Spacer height={5} />
          <div className={`${style.row}`}>
            <p className='small-text '>{`${CobuConstants.residentProfile.unit}`}</p>
            <Spacer width={10} />
            <p className='small-text bold'>{`${roomNumber}`}</p>
          </div>
        </div>
      </div>
    );
  };

  const createCobuTableInstance = (data: any, columns: any) => {
    return data.length > 10 ? (
      <CobuPaginatedTable data={data} columns={columns} />
    ) : (
      <CobuTable data={data} columns={columns} />
    );
  };

  const editResidentModal = () => {
    return (
      residentProfileState.showUserEditModal && (
        <GenericModal
          showModal={residentProfileState.showUserEditModal}
          title={CobuConstants.residentProfile.editUser}
          content={
            <CreateResidentForm
              edit
              initialData={residentProfileState.residentData}
              cancel={showHideUserEditModal}
              createResident={(resident) => {
                updateResidentData({ ...resident, id: residentId });
              }}
              formConfig={getEditUserFormConfig()}
            />
          }
          hideActionButton
          canceActionButton
          actionText={CobuConstants.residentManagement.createActionTitle}
          actionFunction={() => { }}
          onChange={() => { }}
        />
      )
    );
  };

  const infoView = () => {
    return (
      <div className={style.container}>
        <div className={`${style.row}`}>
          <p className='small-text' style={{ display: 'flex', flex: '40%' }}>
            {CobuConstants.residentProfile.residentSince}
          </p>
          <Spacer width={10} />
          <p
            className='small-text bold'
            style={{ display: 'flex', flex: '60%' }}
          >{`${moment(createdAt).local().format('MM/DD/YYYY')}`}</p>
        </div>

        {groups.length ? (
          <div>
            <Spacer height={10} />
            <div className={`${style.row}`}>
              <p
                className='small-text'
                style={{ display: 'flex', flex: '40%' }}
              >
                {CobuConstants.residentProfile.groups}
              </p>
              <Spacer width={10} />
              <p
                className='small-text bold'
                style={{ display: 'flex', flex: '60%' }}
              >{`${groups}`}</p>
            </div>
          </div>
        ) : null}

        {likesHelpingWith.filter((item) => item).length ? (
          <div>
            <Spacer height={10} />
            <div className={`${style.row}`}>
              <p
                className='small-text'
                style={{ display: 'flex', flex: '40%' }}
              >
                {CobuConstants.residentProfile.likesHelpingWith}
              </p>
              <Spacer width={10} />
              <p
                className='small-text bold'
                style={{ display: 'flex', flex: '60%' }}
              >{`${likesHelpingWith.filter((item) => item).join(',')}`}</p>
            </div>
          </div>
        ) : null}

        {needsHelpWith.filter((item) => item).length ? (
          <div>
            <Spacer height={10} />
            <div className={`${style.row}`}>
              <p
                className='small-text'
                style={{ display: 'flex', flex: '40%' }}
              >
                {CobuConstants.residentProfile.needsHelpWith}
              </p>
              <Spacer width={10} />
              <p
                className='small-text bold'
                style={{ display: 'flex', flex: '60%' }}
              >{`${needsHelpWith.filter((item) => item).join(',')}`}</p>
            </div>
          </div>
        ) : null}

        {bio ? (
          <div>
            <Spacer height={10} />
            <div className={`${style.row}`}>
              <p
                className='small-text'
                style={{ display: 'flex', flex: '40%' }}
              >
                {CobuConstants.residentProfile.bio}
              </p>
              <Spacer width={10} />
              <p
                className='small-text bold'
                style={{ display: 'flex', flex: '60%' }}
              >{`${bio}`}</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const placeholderForData = (label: string) => {
    return (
      <div>
        <div className={style.noData}>
          <NotFound />
          <h3 className={`margin-top-24 ${style.titleLabel}`}>{label}</h3>
        </div>
        <Spacer height={50} />
      </div>
    );
  };

  const residentDashboards = () => {
    return (
      <div className={`${style.spaceBtw} ${style.row}`}>
        <div className={`${style.tableViewMin}`}>
          {infoView()}
          <Spacer height={10} />
          <CobuSwitchList
            data={residentProfileState.notificationTypes.map((n) => {
              return { key: n.name, id: n.uuid, value: n.isActiveForResident };
            })}
            title={CobuConstants.residentProfile.notificationSettings}
          />
          {referralLink && userRole !== Roles.PM && (
            <>
              <Spacer height={20} />
              <div
                style={{
                  padding: '12px',
                  border: '1px solid #D3D3D3',
                  borderRadius: '10px'
                }}
              >
                <p className='bold'>REFERRAL SETTINGS</p>
                <Spacer height={20} />
                <div>{`Referral Link: ${referralLink}`}</div>
                <Spacer height={4} />
                <button
                  onClick={syncIntercomUserReferralDetails}
                  className='btn-standard btn-primary cursor-pointer'
                >
                  Sync Intercom Referral Details
                </button>
              </div>
            </>
          )}
          <Spacer height={20} />
          {residentRemoved?.length ? (
            <div className='small-text'>
              {`Resident removed from outreach on ${moment(
                residentRemoved[0].createdAt
              ).format('MMM D, YYYY')}`}
            </div>
          ) : null}
        </div>
        <Spacer width={10} />
        <div className={`${style.tableViewMax}`}>
          <Spacer width={10} />
          {comments.length ? (
            <div>
              {createCobuTableInstance(comments, commentTableConfig)}
              <Spacer height={20} />
            </div>
          ) : (
            placeholderForData(CobuConstants.residentProfile.noComments)
          )}
          {residentProfileState.rsvpUIModel.length ? (
            <div>
              {createCobuTableInstance(
                residentProfileState.rsvpUIModel,
                rsvpTableConfig
              )}
              <Spacer height={20} />
            </div>
          ) : (
            placeholderForData(CobuConstants.residentProfile.noEvents)
          )}
          {residentProfileState.notifications.length ? (
            <div>
              {createCobuTableInstance(
                residentProfileState.notifications,
                notifTableConfig
              )}
              <Spacer height={20} />
            </div>
          ) : (
            placeholderForData(CobuConstants.residentProfile.noNotifs)
          )}
        </div>
      </div>
    );
  };

  if (residentProfileState.loading)
    return (
      <div className={style.loadingContainer}>
        <Spinner />
        <h3 style={{ textAlign: 'center' }}>
          {residentProfileState.loadingString}
        </h3>
      </div>
    );

  return (
    <div className='content-container float-spinner-parent'>
      {loading && <Spinner float />}
      <Breadcrumbs indexesToHide={[3]} />
      <Spacer height={10} />
      <div className={`${style.spaceBtw} ${style.row}`}>
        <h1>{CobuConstants.residentProfile.heading}</h1>
        {userRole !== Roles.PM && <div className={`${style.alignCenter}`}>
          <MoreActions
            title={CobuConstants.residentProfile.actions}
            options={[
              {
                label: CobuConstants.residentProfile.editUser,
                action: showHideUserEditModal
              },
              {
                label: CobuConstants.residentProfile.removeProfilePic,
                action: () => {
                  deleteProfilePic();
                }
              }
            ]}
          />
        </div>}
      </div>
      <Spacer height={20} />
      {profileHeader()}
      <Spacer height={20} />
      <div className={`${style.seperator}`} />
      <Spacer height={20} />
      {residentDashboards()}
      {editResidentModal()}
    </div>
  );
};

export default ResidentProfile;
