import { pick } from 'lodash';
import { FieldNamesMarkedBoolean } from 'react-hook-form';

export const getDirtyFormValues = <T>(
  dirtyFields: FieldNamesMarkedBoolean<any>,
  formData: T
): Partial<T> => {
  const pickedData: Partial<T> = pick(formData, Object.keys(dirtyFields));

  return pickedData;
};
