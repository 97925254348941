import React, { FC, useEffect, useRef } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import CobuConstants from '~/helpers/cobuConstants';
import { Building } from '~/types/building';
import lang from '~/assets/languages';
import './CreateBuildingForm.css';
import { FormInputConfig, FormInputTypes } from '~/types/residentManagement';
import {
  additionalGoogleReviewFormFields,
  buildingImageFormField,
  buildingLogoFormField,
  featureIdsFormFields,
  reviewUrlConfig
} from '../Header/CreateMenu/constants';
import useCreateBuilding from './hooks';
import Spinner from '../Spinner/Spinner';
import { trimData } from '~/helpers/helpers';
import { FormField } from '../FormField';
import { AutoGrowFormField } from '../AutoGrowFormField/AutoGrowFormFiled';
import moment from 'moment';
import { SectionId } from './constants';
import Spacer from '../Spacer/Spacer';
import { ISelectOption } from '~/helpers/interfaces';
import GenericModal from '../GenericModal/GenericModal';
import { Stack } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

interface IProps {}

const defaultValues = {
  VCM: '1' //VCM number is constant for all the buildings
};

export const CreateBuildingForm: FC<IProps> = () => {
  const isMounted = useRef(false);

  const { register, handleSubmit, errors, control, watch, reset, getValues } =
    useForm<Building>({
      defaultValues: defaultValues
    });

  const searchQuery = watch('alnSearchQuery', undefined);
  const selectedALNProperty = watch('selectedALNProperty', undefined);

  const {
    loader,
    alnPropertyOptions,
    defaultFormData,
    createBuilding,
    informationConfig,
    showNextSteps,
    gotoOnBoarding,
    gotoDashboard,
    createNewBuilding
  } = useCreateBuilding(
    defaultValues,
    searchQuery,
    selectedALNProperty,
    reset,
    getValues
  );

  const { ref: informationRef, inView: informationInView } = useInView({
    /* Optional options */
    threshold: 0,
    initialInView: false,
    skip: window.innerHeight > 1350
  });

  const { ref: iamgeRef, inView: imageInView } = useInView({
    /* Optional options */
    threshold: 0.8,
    initialInView: false,
    skip: window.innerHeight > 1350
  });

  const { ref: reviewRef, inView: reviewInView } = useInView({
    /* Optional options */
    threshold: 0.8,
    initialInView: false,
    skip: window.innerHeight > 1350
  });

  useEffect(() => {
    if (isMounted.current && defaultFormData) {
      reset({ ...(defaultFormData || {}) });
    } else {
      isMounted.current = true;
    }
  }, [defaultFormData]);

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <Spacer height={5} />
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const onSubmit: SubmitHandler<Building> = (building) => {
    const trimmedData = trimData<Building>(building);
    if (trimmedData.hasOwnProperty('reportEmails')) {
      const commaSeperatedEmails = trimmedData.reportEmails
        ?.split('\n')
        .join(',');

      trimmedData.reportEmails = `{${commaSeperatedEmails}}`;
    }

    createBuilding(trimmedData);
  };

  const renderAdditionalGoogleReview = () => {
    return (
      <div className='additional-url-container'>
        <p className='label bold'>{lang.building.additionalGoogleReviewURLs}</p>
        <p className='review-description'>
          {lang.building.multiplePropertiesMergedIntoOneBuilding}
        </p>
        <AutoGrowFormField
          addNewRowText={lang.building.addNewGoogleReviewURL}
          errors={errors}
          control={control}
          register={register}
          formFieldName={'additional_reviewurls' as keyof Building}
          rowConfig={additionalGoogleReviewFormFields}
        />
      </div>
    );
  };

  const renderFeatureIds = () => {
    return (
      <div className='additional-url-container'>
        <p className='label bold'>{lang.building.featureIds}</p>
        <p className='review-description'>
          {lang.building.googleFeatureIdNeeded}
        </p>
        <AutoGrowFormField
          addNewRowText={lang.building.addNewFeatureId}
          errors={errors}
          control={control}
          register={register}
          formFieldName={'google_feature_ids' as keyof Building}
          rowConfig={featureIdsFormFields}
        />
      </div>
    );
  };

  const alnSearchField: FormInputConfig[] = [
    {
      label: 'Search via ALN property name, city and state',
      id: 'alnSearchQuery',
      isRequired: false
    },
    ...(alnPropertyOptions
      ? [
          {
            label: 'Search Result (Property Names)',
            id: 'selectedALNProperty',
            inputType: 'chip-list' as FormInputTypes,
            selectOptions: alnPropertyOptions as ISelectOption[],
            isRequired: false
          }
        ]
      : [])
  ];

  const onSectionClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const tabs = document.querySelectorAll('.header-list-item');
    tabs.forEach((tab) => tab.classList.remove('tab-focused'));
    e.currentTarget.classList.add('tab-focused');

    const sectionId = e.currentTarget.getAttribute('data-section');

    if (sectionId) {
      const sectionElement = document.getElementById(sectionId);

      if (sectionElement) {
        const offset = 70; // height of sticky header

        const elementPosition =
          sectionElement.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  const showImageHeader =
    document.body.getBoundingClientRect().top < -100 &&
    !reviewInView &&
    imageInView;

  return (
    <div className='float-spinner-parent create-container'>
      <Breadcrumbs />
      {loader && <Spinner float />}
      <div className='sticky-header-container'>
        <div className='sticky-header'>
          <div className='header-list'>
            <div
              onClick={onSectionClick}
              data-section={SectionId.Information}
              className={`header-list-item sub-heading semi-bold cursor-pointer ${
                !reviewInView && !showImageHeader && informationInView
                  ? 'tab-focused'
                  : ''
              }`}
            >
              Information
            </div>
            <div className='header-list-divider' />
            <div
              onClick={onSectionClick}
              data-section={SectionId.Images}
              className={`header-list-item sub-heading semi-bold cursor-pointer ${
                showImageHeader ? 'tab-focused' : ''
              }`}
            >
              Images
            </div>
            <div className='header-list-divider' />
            <div
              onClick={onSectionClick}
              data-section={SectionId.Reviews}
              className={`header-list-item sub-heading semi-bold cursor-pointer ${
                reviewInView ? 'tab-focused' : ''
              }`}
            >
              Reviews
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id='createEditBuildingForm'>
        <div>
          <Spacer height={80} />
          <p className='first-section-heading heading'>ALN Search</p>
          {alnSearchField.map(generateField)}
          {alnPropertyOptions && alnPropertyOptions.length <= 0 && (
            <div>ALN Properties Not Found</div>
          )}
        </div>
        <div ref={informationRef} id={SectionId.Information}>
          <p className='section-heading heading'>Information</p>
          <div className='grid section-container'>
            {informationConfig.map((field) => generateField(field))}
          </div>
        </div>
        <div ref={iamgeRef} id={SectionId.Images}>
          <p className='section-heading heading'>Images</p>
          <div className='grid section-container'>
            {generateField(buildingLogoFormField)}
            {generateField(buildingImageFormField)}
          </div>
        </div>
        <div ref={reviewRef} id={SectionId.Reviews}>
          <p className='section-heading heading'>Reviews</p>
          <div className='section-container'>
            {reviewUrlConfig.map(generateField)}
            {renderAdditionalGoogleReview()}
            {renderFeatureIds()}
          </div>
        </div>
        <Spacer height={100} />
        <div className='formButtons'>
          <button
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          >
            {'Create Building'}
          </button>
        </div>
      </form>
      <GenericModal
        showModal={showNextSteps}
        title={'Building Created. Next Steps:'}
        enableOverflowScrolls
        content={
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            spacing={5}
            style={{ marginTop: '40px' }}
          >
            <button
              className='btn-standard btn-primary'
              onClick={gotoOnBoarding}
            >
              On-Boarding
            </button>
            <button
              className='btn-standard btn-primary'
              onClick={createNewBuilding}
            >
              Create New Building
            </button>
            <button
              className='btn-standard btn-primary'
              onClick={gotoDashboard}
            >
              Building Dashboard
            </button>
          </Stack>
        }
        hideActionButton
        canceActionButton
        actionText={CobuConstants.createBuilding.createActionTitle}
        actionFunction={() => {}}
        onChange={() => {}}
      />
    </div>
  );
};
