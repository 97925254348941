import React, { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useGetBuidingQuestionnaires from './hooks';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import Spacer from '~/components/Spacer/Spacer';
import { CellProps } from 'react-table';
import { IQuestionnaireWithResult } from '~/api/questionnair';
import Spinner from '~/components/Spinner/Spinner';
import GenericModal from '~/components/GenericModal/GenericModal';
import { FormInputConfig } from '~/types/residentManagement';
import { FormField } from '~/components/FormField';
import classes from './QuestionnaireList.module.scss';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import moment from 'moment';
import useGetBuildingDetails from '~/hooks/building/useGetBuildingDetails';
import { Building } from '~/types/building';
import { IScheduleForm } from './interfaces';
import { ScheduleForm } from './ScheduleForm';

export const QuestionnaireList = () => {
  const history = useHistory();

  const { buildingId } = useParams<{
    buildingId: string;
  }>();

  const questionnaireId = useRef<null | string>(null);
  const buildingDetails: null | Partial<Building> =
    useGetBuildingDetails(buildingId);

  const {
    questionnairesWithResult,
    isLoading,
    showScheduleModal,
    setShowScheduleModal,
    setSelectedScheduleDetails,
    selectedScheduleDetails,
    onPutScheduleDetails
  } = useGetBuidingQuestionnaires(buildingId);

  const isGlobaQuestionnaire = buildingId === 'cobu';

  const onCreateSurvey = () => {
    history.push(`/building/${buildingId}/questionnaires/create-edit`);
  };

  const onCreateEditSchedule = (questionnaire: IQuestionnaireWithResult) => {
    setSelectedScheduleDetails(
      questionnaire.scheduledDetails ? questionnaire.scheduledDetails : null
    );

    setShowScheduleModal(true);
  };

  const onViewResults = (questionnaire: IQuestionnaireWithResult) => {
    const queryParams = new URLSearchParams({
      questionnaireId: questionnaire.uuid as string
    }).toString();

    history.push(
      `/building/${buildingId}/questionnaires/result?${queryParams}`
    );
  };

  const onEditSurvey = (questionnaire: IQuestionnaireWithResult) => {
    const queryParams = new URLSearchParams({
      questionnaireId: questionnaire.uuid as string
    }).toString();

    history.push(
      `/building/${buildingId}/questionnaires/create-edit?${queryParams}`
    );
  };

  const columns = [
    {
      Header: 'SURVEY',
      accessor: 'name',
      Cell: (cell: CellProps<IQuestionnaireWithResult>) => {
        return <div>{cell.row.original.name}</div>;
      }
    },
    {
      Header: 'QUESTIONS',
      accessor: 'numberOfQuestions',
      Cell: (cell: CellProps<IQuestionnaireWithResult>) => {
        return <div>{cell.row.original.numberOfQuestions}</div>;
      }
    },
    {
      Header: 'SCHEDULE',
      accessor: 'scheduledDetails',
      Cell: (cell: CellProps<IQuestionnaireWithResult>) => {
        return (
          <div>
            {cell.row.original.scheduledDetails?.schedule_time
              ? moment(
                  cell.row.original.scheduledDetails?.schedule_time
                ).format('MM-DD-YYYY')
              : '--'}
          </div>
        );
      }
    },
    {
      Header: 'RESULTS',
      accessor: 'results',
      Cell: (cell: CellProps<IQuestionnaireWithResult>) => {
        return <div>{cell.row.original.results}</div>;
      }
    },
    {
      Header: 'ACTIONS',
      accessor: '',
      Cell: (cell: CellProps<IQuestionnaireWithResult>) => {
        const scheduledBuildingTime =
          cell.row.original.scheduledDetails && buildingDetails
            ? moment(cell.row.original.scheduledDetails.schedule_time).tz(
                (buildingDetails as Building)?.timezone
              )
            : null;

        const currentBuildingTime =
          cell.row.original.scheduledDetails && buildingDetails
            ? moment(new Date()).tz((buildingDetails as Building)?.timezone)
            : null;

        const isScheduleCompleted =
          scheduledBuildingTime && currentBuildingTime
            ? currentBuildingTime.isAfter(scheduledBuildingTime)
            : false;

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0 20px'
            }}
          >
            {cell.row.original.enableQuestionnaireEdit && (
              <div
                className='cursor-pointer'
                onClick={() => onEditSurvey(cell.row.original)}
              >
                Edit Survey
              </div>
            )}
            {!isGlobaQuestionnaire && !isScheduleCompleted && (
              <div
                className='cursor-pointer'
                onClick={() => {
                  questionnaireId.current = cell.row.original.uuid as string;

                  onCreateEditSchedule(cell.row.original);
                }}
              >
                {cell.row.original.scheduledDetails
                  ? 'Edit Schedule Send'
                  : 'Create Schedule Send'}
              </div>
            )}
            {!cell.row.original.enableQuestionnaireEdit && (
              <div
                className='cursor-pointer'
                onClick={() => onViewResults(cell.row.original)}
              >
                View Results
              </div>
            )}
          </div>
        );
      }
    }
  ];

  const onRescheduleSubmit = (scheduleData: IScheduleForm) => {
    onPutScheduleDetails(scheduleData, questionnaireId.current as string);
  };

  return (
    <div className={`'float-spinner-parent' ${classes.container}`}>
      <Breadcrumbs {...(isGlobaQuestionnaire ? { indexesToHide: [1] } : {})} />
      <Spacer height={20} />
      <h2>Survey List</h2>
      {isLoading && <Spinner float />}
      {showScheduleModal && (
        <GenericModal
          showModal={showScheduleModal}
          title={'Configure schedule time'}
          enableOverflowScrolls
          content={
            <ScheduleForm
              onCancel={() => {
                questionnaireId.current = null;
                setSelectedScheduleDetails(null);
                setShowScheduleModal(false);
              }}
              onRescheduleSubmit={onRescheduleSubmit}
              selectedScheduleDetails={selectedScheduleDetails}
            />
          }
          hideActionButton
          canceActionButton
          actionText={''}
          actionFunction={() => {}}
          onChange={() => {}}
        />
      )}
      <CobuTableAdvanced
        searchSibling={
          <div className={classes.createSurvey}>
            <button
              className='btn-standard btn-primary'
              onClick={onCreateSurvey}
            >
              {isGlobaQuestionnaire
                ? 'Create cobu survey'
                : 'Create building wide survey'}
            </button>
          </div>
        }
        enableDeferredSearch
        centerAlignedHeaderAndContent
        noData={{
          label: 'No Data',
          action: undefined,
          image: undefined
        }}
        actionsStyle={{
          position: 'static',
          justifyContent: 'flex-end',
          marginBottom: '10px'
        }}
        search={{
          searchField: 'name',
          placeholder: 'Questionnaire Name'
        }}
        columns={columns}
        data={questionnairesWithResult}
        sort={{
          id: 'createdAt',
          descending: true
        }}
      />
    </div>
  );
};
