import { useEffect, useState } from 'react';
import {
  getUGCSetting,
  ICreateUGCSetting,
  IUGCSetting,
  putUGCSetting
} from '~/api/userGeneratedContent';
import { defaultTableData, TableKeys } from './constants';
import { ITableData } from './interfaces';
import { toast } from 'react-toastify';

export default (buildingId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ugcTableData, setTableData] = useState<null | ITableData[]>(
    defaultTableData
  );
  const [ugcSetting, setUGCSetting] = useState<null | IUGCSetting>(null);

  const fetchUGCSetting = async () => {
    try {
      setIsLoading(true);
      const { data } = await getUGCSetting(buildingId);

      const tableData = [
        {
          [TableKeys.Source]: {
            key: 'chat_on',
            placeholder: 'General Chat'
          },
          [TableKeys.DaysInPast]: {
            key: 'chat_past_days'
          },
          [TableKeys.PageSnippet]: {
            key: 'chat_snippet'
          }
        },
        {
          [TableKeys.Source]: {
            key: 'marketplace_on',
            placeholder: 'Marketplace'
          },
          [TableKeys.DaysInPast]: {
            key: 'marketplace_past_days'
          },
          [TableKeys.PageSnippet]: {
            key: 'marketplace_snippet'
          }
        },
        {
          [TableKeys.Source]: {
            key: 'events_on',
            placeholder: 'Events'
          },
          [TableKeys.DaysInPast]: {
            key: 'events_past_days'
          },
          [TableKeys.DaysInFuture]: {
            key: 'events_future_days'
          },
          [TableKeys.PageSnippet]: {
            key: 'events_snippet'
          }
        },
        {
          [TableKeys.Source]: {
            key: 'groups_on',
            placeholder: 'Groups'
          },
          [TableKeys.PageSnippet]: {
            key: 'groups_snippet'
          }
        },
        {
          [TableKeys.Source]: {
            key: 'video_on',
            placeholder: 'Videos'
          },
          [TableKeys.PageSnippet]: {
            key: 'videos_snippet'
          }
        }
      ];

      setIsLoading(false);
      setTableData(tableData);
      setUGCSetting(data);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onSubmit = async (formData: IUGCSetting | ICreateUGCSetting) => {
    try {
      setIsLoading(true);

      await putUGCSetting({ building: buildingId, ...formData });

      toast('Changes Saved Successfully');

      fetchUGCSetting();

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast('Failed to save UGC data');
    }
  };

  useEffect(() => {
    fetchUGCSetting();
  }, []);

  return { isLoading, ugcTableData, ugcSetting, onSubmit };
};
