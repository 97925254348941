import React from 'react';
import { useParams } from 'react-router-dom';
import { BuildingOnBoardingForm } from '~/components/BuildingOnBoardingForm/BuildingOnBoardingForm';

interface IBuildingOnboardingProps {}

const BuildingOnboarding: React.FC<IBuildingOnboardingProps> = () => {
  const { buildingId } = useParams<{ buildingId: string }>();

  return (
    <div className='container'>
      <BuildingOnBoardingForm buildingId={buildingId} />
    </div>
  );
};

export default BuildingOnboarding;
