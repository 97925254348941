import React, { FC } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import CobuConstants from '~/helpers/cobuConstants';
import { Building } from '~/types/building';
import './BuildingOnBoardingForm.css';
import { FormInputConfig } from '~/types/residentManagement';
import { boardingOptionsFormField } from '../Header/CreateMenu/constants';
import useBuildingOnBoarding from './hooks';
import Spinner from '../Spinner/Spinner';
import { trimData } from '~/helpers/helpers';
import { FormField } from '../FormField';
import { IBuildingOnBoarding } from '~/api/building';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Spacer from '../Spacer/Spacer';

interface IProps {
  buildingId: string;
}

let formDefaultData = {
  raffleAmount: '200',
  onboardingSkippable: true
};

export const BuildingOnBoardingForm: FC<IProps> = ({ buildingId }) => {
  const { loader, postBuildingOnBoarding } = useBuildingOnBoarding(buildingId);

  const { register, handleSubmit, errors, control } = useForm<Building>({
    defaultValues: formDefaultData
  });

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const onSubmit: SubmitHandler<IBuildingOnBoarding> = (building) => {
    const trimmedData = trimData<IBuildingOnBoarding>(building);

    postBuildingOnBoarding(trimmedData);
  };

  return (
    <div className='float-spinner-parent create-edit-container'>
      <Breadcrumbs />
      {loader && <Spinner float />}
      <form onSubmit={handleSubmit(onSubmit)} id='createEditBuildingForm'>
        <p id='onBoardingHeading' className='heading'>
          {CobuConstants.createBuilding.onBoarding}
        </p>
        {boardingOptionsFormField.map((field) => generateField(field))}
        <Spacer height={40} />
        <div className='formButtons'>
          <input
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          />
        </div>
      </form>
    </div>
  );
};
