import React from 'react';
import { CellProps } from 'react-table';
import { ITableData } from './interfaces';
import { FormInputConfig } from '~/types/residentManagement';
import { uriRegex } from '~/helpers/helpers';

export enum TableKeys {
  Source = 'source',
  DaysInPast = 'daysInPast',
  DaysInFuture = 'daysInFuture',
  PageSnippet = 'pageSnippet'
}

export const defaultTableData: ITableData[] = [
  {
    [TableKeys.Source]: {
      key: 'chat_on',
      placeholder: 'General Chat'
    },
    [TableKeys.DaysInPast]: { key: 'chat_past_days' },
    [TableKeys.PageSnippet]: { key: 'chat_snippet' }
  },
  {
    [TableKeys.Source]: {
      key: 'marketplace_on',
      placeholder: 'Marketplace'
    },
    [TableKeys.DaysInPast]: { key: 'marketplace_past_days' },
    [TableKeys.PageSnippet]: { key: 'marketplace_snippet' }
  },
  {
    [TableKeys.Source]: {
      key: 'events_on',
      placeholder: 'Events'
    },
    [TableKeys.DaysInPast]: { key: 'events_past_days' },
    [TableKeys.DaysInFuture]: { key: 'events_future_days' },
    [TableKeys.PageSnippet]: { key: 'events_snippet' }
  },
  {
    [TableKeys.Source]: {
      key: 'groups_on',
      placeholder: 'Groups'
    },
    [TableKeys.PageSnippet]: { key: 'groups_snippet' }
  }
];

export const otherFormFields: FormInputConfig[] = [
  {
    id: 'uuid',
    label: '',
    isRequired: false,
    displayNone: true
  },
  {
    id: 'blurb_label',
    label: 'Call to Action Label',
    isRequired: false
  },
  {
    id: 'blurb_link',
    label: 'Call to Action URL',
    pattern: uriRegex(),
    patternErrorMsg: 'Please enter a valid url',
    isRequired: false
  },
  {
    id: 'blurb_paragraph',
    label: 'Call to Action Blurb',
    inputType: 'textarea',
    isRequired: false
  }
];

export const jsSnippetFormField: FormInputConfig = {
  id: 'snippet',
  label: 'JS Snippet',
  inputType: 'textarea',
  isRequired: false
};

export const getManageContentFormFields = (fieldId: string) => {
  return [
    {
      id: 'uuid',
      label: '',
      isRequired: false,
      displayNone: true
    },
    {
      id: fieldId,
      label: 'HOW MANY DAYS IN THE PAST',
      isRequired: false
    }
  ];
};

export const avatarSize = 50;
