import React, { ChangeEvent, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import DraggableCobuTable from '~/components/CobuTable/DraggableCobuTable';
import useCreateQuestionnair from './hooks';
import Spacer from '~/components/Spacer/Spacer';
import classes from './CreateQuestionnaire.module.scss';
import { CreateQuestionsForm } from './CreateQuestionForm';
import Spinner from '~/components/Spinner/Spinner';
import { v4 as uuidv4 } from 'uuid';
import { mergeFields } from '~/containers/EventReminder/constants';
import { toast } from 'react-toastify';

export const CreateEditQuestionnaire = () => {
  const { buildingId } = useParams<{ buildingId: string }>();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const questionnaireId = queryParams.get('questionnaireId') as string;

  const isGlobaQuestionnaire = buildingId === 'cobu';

  const {
    isLoading,
    columns,
    draftedQuestions,
    showQuestionForm,
    selectedQuestion,
    questionTypeAndSubTypes,
    onSortDraftQuestions,
    onAddNewQuestion,
    onCancelQuestionForm,
    onAppendDraftedQuestion,
    patchQuestionnair,
    postQuestionnair,
    questionnaireName,
    setQuestionnaireName,
    onCancelCreateEdit,
    questionnaireIntroParagraph,
    setQuestionnaireIntroParagraph
  } = useCreateQuestionnair(buildingId, questionnaireId);

  const identifiedDraftQuestions = useMemo(() => {
    return draftedQuestions.map((draftedQuestion) => {
      if (draftedQuestion.id) {
        return draftedQuestion;
      }

      draftedQuestion.id = uuidv4();

      return draftedQuestion;
    });
  }, [draftedQuestions]);

  const disableSubmit = !questionnaireName || draftedQuestions.length <= 0;

  return (
    <div className={`float-spinner-parent ${classes.container}`}>
      {isLoading && <Spinner float />}
      <Breadcrumbs {...(isGlobaQuestionnaire ? { indexesToHide: [1] } : {})} />
      <Spacer height={20} />
      <h2>{questionnaireId ? 'Edit Survey' : 'Create Survey'}</h2>
      <Spacer height={40} />
      <div className={classes.questionNameContainer}>
        <h3>Survey Name:</h3>
        <input
          type='text'
          value={questionnaireName}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setQuestionnaireName(e.target.value)
          }
        />
      </div>
      <Spacer height={40} />
      <div>
        <div>
          <h3>Intro Paragraph</h3>
          <Spacer height={10} />
          <div className={classes.questionNameContainer}>
            <div>{`Merge Fields (click to copy) :`}</div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px 0' }}
            >
              {[mergeFields.buildingName].map((mergeField) => (
                <div
                  key={mergeField}
                  className='cursor-pointer'
                  onClick={() => {
                    navigator.clipboard.writeText(`{{${mergeField}}}`);
                    toast('Merge field copied!');
                  }}
                >{`{{${mergeField}}}`}</div>
              ))}
            </div>
          </div>
        </div>
        <Spacer height={10} />
        <textarea
          value={questionnaireIntroParagraph}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setQuestionnaireIntroParagraph(e.target.value)
          }
          cols={50}
          rows={6}
          placeholder={'Intro paragraph'}
        />
      </div>
      <Spacer height={10} />
      {draftedQuestions && draftedQuestions.length > 0 && (
        <DraggableCobuTable
          columns={columns}
          data={identifiedDraftQuestions}
          ignoreDefaultSortBehaviour
          isDraggable={true}
          onDragEnd={onSortDraftQuestions}
          dragTimeout={0}
          showPagination
          paginationPageSize={50}
        />
      )}
      {showQuestionForm && (
        <CreateQuestionsForm
          onCancel={onCancelQuestionForm}
          onFormSubmit={onAppendDraftedQuestion}
          showFormModal={showQuestionForm}
          selectedQuestion={selectedQuestion}
          questionTypeAndSubTypes={questionTypeAndSubTypes}
          modalTitle={selectedQuestion ? 'Edit Question' : 'Create Question'}
        />
      )}
      <Spacer height={20} />
      <div className={classes.addNewQuestion} onClick={onAddNewQuestion}>
        Add a New Question
      </div>
      <Spacer height={90} />
      <div className={classes.submitSurvey}>
        <button
          className={`btn-standard btn-primary`}
          disabled={!questionnaireName || draftedQuestions.length <= 0}
          onClick={onCancelCreateEdit}
        >
          {'Cancel'}
        </button>
        <button
          className={`btn-standard btn-primary`}
          disabled={disableSubmit}
          onClick={questionnaireId ? patchQuestionnair : postQuestionnair}
        >
          {questionnaireId ? 'Update Survey' : 'Create Survey'}
        </button>
      </div>
    </div>
  );
};
