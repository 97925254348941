import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  ICommunityLead,
  getReferralLeads,
  patchLeadManagementDetails
} from '~/api/referral';
import { Roles } from '~/enums/Roles';
import { RootState } from '~/redux/store';

export default (buildigId: string) => {
  const [loading, setLoading] = useState(false);
  const [referralLeads, setReferralLead] = useState<ICommunityLead[] | null>(
    null
  );
  const [showLeadManagementModal, setShowLeadManagementModal] = useState(false);

  const userRole = useSelector((state: RootState) => state.auth.userRole);

  const fetchCommunityLeads = async () => {
    try {
      setLoading(true);
      const res = await getReferralLeads(buildigId);

      setReferralLead(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast(e.message || 'Failed to fetch community leads');
    }
  };

  useEffect(() => {
    fetchCommunityLeads();
  }, []);

  const toggleLeadManagementModal = () => {
    setShowLeadManagementModal(!showLeadManagementModal);
  };

  const onSaveLeadManagementDetails = async (
    leadUuid: string,
    formData: Partial<ICommunityLead>
  ) => {
    try {
      setLoading(true);
      await patchLeadManagementDetails(leadUuid, formData);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast('Failed to save lead management details');
    }
  };

  return {
    loading,
    referralLeads,
    isPMUser: userRole === Roles.PM,
    showLeadManagementModal,
    toggleLeadManagementModal,
    onSaveLeadManagementDetails,
    fetchCommunityLeads
  };
};
