import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormField } from '~/components/FormField';
import { FormInputConfig } from '~/types/residentManagement';
import { ICommunityLead } from '~/api/referral';
import classes from './leadManagementForm.module.scss';
import Spacer from '~/components/Spacer/Spacer';
import { getDirtyFormValues } from '~/utils/formUtils';

interface IProps {
  onCancel: () => void;
  onSubmit: (formData: Partial<ICommunityLead>) => void;
  selectedLead: ICommunityLead;
}

export const LeadManagementForm: FC<IProps> = ({
  onCancel,
  onSubmit,
  selectedLead
}) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    formState: { dirtyFields, isDirty }
  } = useForm<Partial<ICommunityLead>>({
    defaultValues: selectedLead
  });

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <Spacer height={10} />
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const onFormSubmit = (formData: Partial<ICommunityLead>) => {
    onSubmit(getDirtyFormValues(dirtyFields, formData));
  };

  const isReceiveCobuCredit = watch('cobu_receive_credit');

  const formConfig: FormInputConfig[] = useMemo(
    () => [
      {
        label: 'Signed Lease ?',
        id: 'signed_lease',
        inputType: 'switch',
        isRequired: false
      },
      {
        label: 'Referral Bonus Paid ?',
        id: 'referral_bonus_paid',
        inputType: 'switch',
        isRequired: false
      },
      {
        label: 'Cobu Receive Credit For Lead ?',
        id: 'cobu_receive_credit',
        inputType: 'switch',
        isRequired: false
      },
      {
        label: 'Lease Amount',
        id: 'lease_amount',
        isRequired: isReceiveCobuCredit
      },
      {
        label: 'Comments',
        id: 'comments',
        inputType: 'textarea',
        isRequired: false
      }
    ],
    [isReceiveCobuCredit]
  );

  return (
    <div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className='grid'>
          {formConfig.map((field) => generateField(field))}
        </div>
        <div className={classes.formButtons}>
          <button className='btn-standard btn-secondary' onClick={onCancel}>
            Cancel
          </button>
          <button
            disabled={!isDirty}
            className='btn-standard btn-primary'
            type='submit'
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};
