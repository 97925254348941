import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getBuildingQuestionnaireWithResults,
  IQuestionnaireWithResult,
  IScheduleDetails,
  putQuestionnairScheduleDetails
} from '~/api/questionnair';
import { IScheduleForm } from './interfaces';

export default (buildingId: string) => {
  const [questionnairesWithResult, setQuestionnairesWithResult] = useState<
    IQuestionnaireWithResult[]
  >([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  const [selectedScheduleDetails, setSelectedScheduleDetails] =
    useState<null | IScheduleDetails>(null);

  const fetchBuildingQuestionnaire = async () => {
    try {
      setLoading(true);
      const { data } = await getBuildingQuestionnaireWithResults(buildingId);

      setQuestionnairesWithResult(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onPutScheduleDetails = async (
    scheduleDetails: IScheduleForm,
    questionnaire: string
  ) => {
    try {
      setLoading(true);

      await putQuestionnairScheduleDetails({
        ...scheduleDetails,
        questionnaire
      });

      toast(
        `Schedule details ${
          scheduleDetails.uuid ? 'updated' : 'saved'
        } successfully`
      );

      setShowScheduleModal(false);
      setLoading(false);
      fetchBuildingQuestionnaire();
    } catch (e) {
      setLoading(false);
      toast('Failed to put schedule details');
    }
  };

  useEffect(() => {
    fetchBuildingQuestionnaire();
  }, [buildingId]);

  return {
    questionnairesWithResult,
    isLoading,
    showScheduleModal,
    setShowScheduleModal,
    selectedScheduleDetails,
    setSelectedScheduleDetails,
    onPutScheduleDetails
  };
};
