import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import CobuTable from '~/components/CobuTable/CobuTable';
import Spinner from '~/components/Spinner/Spinner';
import useCommunityLeads from '~/hooks/building/referral/useCommunityLeads';
import { ICommunityLead } from '~/api/referral';
import { ReactComponent as Community } from '~/utils/images/community.svg';
import './ReferralLeads.css';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import { CellProps } from 'react-table';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import useGetBuildingDetails from '~/hooks/building/useGetBuildingDetails';
import { Building } from '~/types/building';
import { Tabs } from '~/components/Tabs';
import Spacer from '~/components/Spacer/Spacer';
import GenericModal from '~/components/GenericModal/GenericModal';
import { LeadManagementForm } from './LeadManagementForm';
import { toast } from 'react-toastify';

export const ReferralLeads = () => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const {
    loading,
    referralLeads,
    isPMUser,
    showLeadManagementModal,
    toggleLeadManagementModal,
    onSaveLeadManagementDetails,
    fetchCommunityLeads
  } = useCommunityLeads(buildingId);
  const [offerType, setOfferType] = useState('all');
  const selectedLead = useRef<ICommunityLead | null>(null);

  const buildingDetails = useGetBuildingDetails(buildingId);

  const filteredLeads = useMemo(() => {
    if (!referralLeads) {
      return null;
    }

    if (offerType === 'employee') {
      return referralLeads.filter((lead: ICommunityLead) => !!lead.isEmployee);
    }

    if (offerType === 'resident') {
      return referralLeads.filter((lead: ICommunityLead) => !lead.isEmployee);
    }

    return referralLeads;
  }, [referralLeads, offerType]);

  if (referralLeads && referralLeads.length <= 0) {
    return (
      <div className='container'>
        <Breadcrumbs />
        <div className='no-referral-container'>
          <Community />
          <h2>No Referral found!</h2>
        </div>
      </div>
    );
  }

  const buildingTimezone = (buildingDetails as unknown as Partial<Building>)
    ?.timezone;

  const referralLeadColumns = [
    {
      Header: 'LEAD NAME',
      accessor: 'leadName'
    },
    {
      Header: 'LEAD EMAIL',
      accessor: 'leadEmail'
    },
    {
      Header: 'LEAD PHONE NUMBER',
      accessor: 'leadPhoneNumber'
    },
    {
      Header: 'STATUS',
      accessor: 'status'
    },
    {
      Header: 'CREATED',
      accessor: 'createdAt',
      Cell: (cell: CellProps<ICommunityLead>) => (
        <div>
          {momentTimezone
            .tz(cell.row.original.createdAt, buildingTimezone ?? '')
            .format('MM/DD/YYYY hh:mm A')}
        </div>
      )
    },
    {
      Header: 'REFERRED BY',
      accessor: 'referredBy',
      Cell: (cell: CellProps<ICommunityLead>) => (
        <div>{cell.row.original.isEmployee ? '' : cell.value}</div>
      )
    },
    {
      Header: 'REFERRER EMAIL',
      accessor: 'referrerEmail',
      Cell: (cell: CellProps<ICommunityLead>) => (
        <div>
          {cell.row.original.isEmployee
            ? 'preferred employee program'
            : cell.value}
        </div>
      )
    },
    {
      Header: 'UNIT',
      accessor: 'roomNumber',
      Cell: (cell: CellProps<ICommunityLead>) => (
        <div>{cell.row.original.isEmployee ? '' : cell.value}</div>
      )
    },

    ...(isPMUser
      ? [
          {
            Header: 'Comment',
            accessor: 'comments',
            Cell: (cell: CellProps<ICommunityLead>) => {
              const onManage = () => {
                selectedLead.current = cell.row.original;

                toggleLeadManagementModal();
              };

              return (
                <div
                  onClick={cell.row.original?.comments ? undefined : onManage}
                  className={`${
                    cell.row.original?.comments
                      ? ''
                      : 'underline-text cursor-pointer'
                  }`}
                  style={
                    cell.value
                      ? { overflow: 'scroll', whiteSpace: 'initial' }
                      : {}
                  }
                >
                  {cell.value ?? 'Click To Attribute'}
                </div>
              );
            }
          },
          {
            Header: 'Lead Attribution',
            accessor: '',
            Cell: (cell: CellProps<ICommunityLead>) => {
              const onManage = () => {
                selectedLead.current = cell.row.original;

                toggleLeadManagementModal();
              };

              return (
                <div>
                  <div className='lead-management-row'>
                    <div>Is Signed Lease ?</div>
                    <div
                      onClick={
                        cell.row.original?.signed_lease ? undefined : onManage
                      }
                      className={`${
                        cell.row.original?.signed_lease
                          ? ''
                          : 'underline-text cursor-pointer'
                      }`}
                    >{`${cell.row.original?.signed_lease || 'Attribute'}`}</div>
                  </div>
                  <Spacer height={8} />
                  <div className='lead-management-row'>
                    <div>Lease Amount</div>
                    <div
                      onClick={
                        cell.row.original?.lease_amount ? undefined : onManage
                      }
                      className={`${
                        cell.row.original?.lease_amount
                          ? ''
                          : 'underline-text cursor-pointer'
                      }`}
                    >
                      {cell.row.original?.lease_amount
                        ? `$${cell.row.original?.lease_amount}`
                        : 'Attribute'}
                    </div>
                  </div>
                  <Spacer height={8} />
                  <div className='lead-management-row'>
                    <div>Is Referral Bonus Paid ?</div>
                    <div
                      onClick={
                        cell.row.original?.referral_bonus_paid
                          ? undefined
                          : onManage
                      }
                      className={`${
                        cell.row.original?.referral_bonus_paid
                          ? ''
                          : 'underline-text cursor-pointer'
                      }`}
                    >{`${
                      cell.row.original?.referral_bonus_paid || 'Attribute'
                    }`}</div>
                  </div>
                  <Spacer height={8} />
                  <div className='lead-management-row'>
                    <div>Comments</div>
                    <div
                      onClick={
                        cell.row.original?.comments ? undefined : onManage
                      }
                      className={`${
                        cell.row.original?.comments
                          ? 'manage-lead-comment'
                          : 'underline-text cursor-pointer'
                      }`}
                    >{`${cell.row.original?.comments || 'Attribute'}`}</div>
                  </div>
                  <Spacer height={8} />
                  <div className='lead-management-row'>
                    <div>Cobu Receive Credit For Lead</div>
                    <div
                      onClick={
                        cell.row.original?.cobu_receive_credit
                          ? undefined
                          : onManage
                      }
                      className={`${
                        cell.row.original?.cobu_receive_credit
                          ? ''
                          : 'underline-text cursor-pointer'
                      }`}
                    >{`${
                      cell.row.original?.cobu_receive_credit || 'Attribute'
                    }`}</div>
                  </div>
                  <Spacer height={20} />
                  <div
                    className='btn-standard btn-primary cursor-pointer text-align-center'
                    style={{ minHeight: 'unset' }}
                    onClick={onManage}
                  >
                    Lead attribution
                  </div>
                </div>
              );
            }
          }
        ]
      : [])
  ];

  return (
    <div className='container float-spinner-parent'>
      {loading && <Spinner float verticalAlign={'start'} />}
      <Breadcrumbs />
      <div className='margin-top-24'>
        <Tabs.Container
          isOnChangePreventDefault
          onChange={(value) => {
            setOfferType(value);
          }}
          value={offerType}
          styles={{ width: '50%' }}
        >
          <Tabs.Option selected={offerType === 'all'} key={'all'} value={'all'}>
            All
          </Tabs.Option>
          <Tabs.Option
            selected={offerType === 'resident'}
            key={'resident'}
            value={'resident'}
          >
            Resident Referrals
          </Tabs.Option>
          <Tabs.Option
            selected={offerType === 'employee'}
            key={'employee'}
            value={'employee'}
          >
            Employee preferred program
          </Tabs.Option>
        </Tabs.Container>
        <Spacer height={24} />
        {!!filteredLeads && (
          <CobuTable
            columns={referralLeadColumns}
            data={filteredLeads as ICommunityLead[]}
            sort={{
              id: 'createdAt',
              descending: true
            }}
          />
        )}
        {showLeadManagementModal && (
          <GenericModal
            showModal={showLeadManagementModal}
            title={'Lead Attribution'}
            enableOverflowScrolls
            content={
              <LeadManagementForm
                onCancel={() => {
                  selectedLead.current = null;

                  toggleLeadManagementModal();
                }}
                onSubmit={async (formData) => {
                  if (!selectedLead.current) {
                    toast('lead not selected');
                    return;
                  }

                  await onSaveLeadManagementDetails(
                    selectedLead.current.uuid,
                    formData
                  );

                  selectedLead.current = null;
                  toggleLeadManagementModal();
                  fetchCommunityLeads();
                }}
                selectedLead={selectedLead.current as ICommunityLead}
              />
            }
            hideActionButton
            canceActionButton
            actionText={''}
            actionFunction={() => {}}
            onChange={() => {}}
          />
        )}
      </div>
    </div>
  );
};
