import React, { FC, useMemo, useState } from 'react';
import { CreateBuildingForm } from '~/components/CreateBuildingForm/CreateBuildingForm';
import { formatBuildingData } from '~/components/CreateBuildingForm/helpers';
import { CreateEditBuildingForm } from '~/components/CreateEditBuildingForm/CreateEditBuildingForm';
import GenericModal from '~/components/GenericModal/GenericModal';
import {
  getCreateBuildingFormConfig,
  getEditBuildingFormConfig,
  timezoneOptions
} from '~/components/Header/CreateMenu/constants';
import CobuConstants from '~/helpers/cobuConstants';
import { Building } from '~/types/building';

interface IProps {
  buildingId: string;
  building: Building;
  onEditBuilding: () => Promise<void>;
  showEditSetting?: boolean;
}

export const EditBuildingSetting: FC<IProps> = ({
  building,
  buildingId,
  onEditBuilding,
  showEditSetting = false
}) => {
  const [showEditBuildingForm, setShowEditBuildingForm] =
    useState(showEditSetting);

  const toggleShowBuildingForm = () => {
    setShowEditBuildingForm((prevState) => !prevState);
  };

  const defaultData = useMemo(() => {
    const buildingDetails = formatBuildingData(building);

    return {
      ...buildingDetails,
      google_feature_ids: buildingDetails?.google_feature_ids || [
        { building: '', featureId: '' }
      ],
      additional_reviewurls: buildingDetails?.additional_reviewurls || [
        { building: '', url: '' }
      ],
      propertyEmails: buildingDetails?.propertyEmails || [
        { email: '', type: '' }
      ]
    };
  }, [building]);

  return (
    <>
      <button
        className='btn-standard btn-primary'
        onClick={toggleShowBuildingForm}
      >
        Edit Building Settings
      </button>
      <GenericModal
        showModal={showEditBuildingForm}
        title={'Edit Building'}
        enableOverflowScrolls
        content={
          showEditBuildingForm ? (
            <CreateEditBuildingForm
              isEditMode
              defaultData={defaultData}
              buildingId={buildingId}
              formConfig={getEditBuildingFormConfig(timezoneOptions)}
              onCancel={toggleShowBuildingForm}
              onEditBuilding={onEditBuilding}
            />
          ) : null
        }
        hideActionButton
        canceActionButton
        actionText={CobuConstants.createBuilding.editActionTitle}
        actionFunction={() => {}}
        onChange={() => {}}
      />
    </>
  );
};
