import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createGatheringTemplate,
  getGatheringTemplate,
  updateGatheringTemplate
} from '~/api/gatherings';
import CobuSelect from '~/components/CobuSelect/CobuSelect';
import ImageDropzone from '~/components/ImageDropzone/ImageDropzone';
import { SelectType } from '~/enums/SelectType';
import { SelectedOptionType } from '~/types/form';
import { GatheringTemplateDTO } from '~/types/gathering';

import styles from './styles.module.css';

const recommendedMonthOptions = [
  {
    label: 'January',
    value: 'January'
  },
  {
    label: 'February',
    value: 'February'
  },
  {
    label: 'March',
    value: 'March'
  },
  {
    label: 'April',
    value: 'April'
  },
  {
    label: 'May',
    value: 'May'
  },
  {
    label: 'June',
    value: 'June'
  },
  {
    label: 'July',
    value: 'July'
  },
  {
    label: 'August',
    value: 'August'
  },
  {
    label: 'September',
    value: 'September'
  },
  {
    label: 'October',
    value: 'October'
  },
  {
    label: 'November',
    value: 'November'
  },
  {
    label: 'December',
    value: 'December'
  }
];

const GatheringTemplateForm = () => {
  const { templateId, buildingId } = useParams<{
    templateId?: string;
    buildingId: string;
  }>();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [image, setImage] = useState<File | string>('');

  const { register, handleSubmit, errors, control } =
    useForm<GatheringTemplateDTO>();

  const { data } = useQuery(
    ['gathering-template', templateId],
    () => getGatheringTemplate(templateId || ''),
    {
      enabled: !!templateId
    }
  );

  useEffect(() => {
    setSelectedMonth(data?.data.recommendedMonth);
  }, [data]);

  const [selectedMonth, setSelectedMonth] = useState<string>();

  const createTemplateMutation = useMutation(createGatheringTemplate, {
    onSuccess: () => {
      toast('Template successfully created');
      queryClient.invalidateQueries(['gathering-template', templateId]);
      history.push(`/building/${buildingId}/gathering`);
    },
    onError: (e: any) => {
      toast(
        `There was an error creating the gathering: ${e.response.data.message.join(
          ','
        )}`
      );
    }
  });

  const updateTemplateMutation = useMutation(updateGatheringTemplate, {
    onSuccess: () => {
      toast('Template successfully updated');
      queryClient.invalidateQueries(['gathering-template', templateId]);
      history.push(`/building/${buildingId}/gathering`);
    }
  });

  const submitHandler = async (newTemplateData: GatheringTemplateDTO) => {
    const completedTemplateData = {
      ...newTemplateData,
      whatToBring: newTemplateData.whatToBring || '',
      howToUrl: newTemplateData.howToUrl || '',
      recommendedMonth: selectedMonth || '',
      buildingUuid: buildingId
    };

    if (image) {
      completedTemplateData.image = image;
    }

    if (!!templateId) {
      updateTemplateMutation.mutate({
        templateId,
        templateData: completedTemplateData
      });
    } else {
      createTemplateMutation.mutate(completedTemplateData);
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setImage(acceptedFiles[0]);
  };

  return (
    <div className='content-container'>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className={styles.header}>
          <h1>Gathering Template</h1>
          <div className={styles.formButtons}>
            <button
              // disabled={isSubmitting || isSuccessfullySubmitted}
              className='btn-standard btn-primary margin-left-16 weight-500'
              type='submit'
            >
              {templateId ? 'Save' : 'Create'}
            </button>
            <button
              className='btn-standard btn-seconday weight-500'
              onClick={(e) => {
                e.preventDefault();
                history.push(`/building/${buildingId}/gathering`);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <label className='margin-top-16' htmlFor='title'>
          Gathering name
        </label>
        <input
          type='text'
          id='title'
          name='title'
          placeholder={'E.g. Wine night in the Lobby'}
          ref={register({
            required: 'This field is required'
          })}
          className={`${errors.title && 'border-red'}`}
          defaultValue={data?.data.title}
        />
        <div className={styles.row}>
          <div className={styles.input}>
            <label className='margin-top-16' htmlFor='description'>
              Gathering description
            </label>
            <textarea
              name='description'
              id='description'
              className={`${errors.description && 'border-red'}`}
              cols={30}
              rows={3}
              ref={register({
                required: 'This field is required'
              })}
              placeholder='E.g. Details about the gathering'
              defaultValue={data?.data.description}
            ></textarea>
          </div>
          <div className={styles.input}>
            <label className='margin-top-16' htmlFor='description'>
              What to bring
            </label>
            <textarea
              name='whatToBring'
              id='whatToBring'
              cols={30}
              rows={3}
              ref={register}
              placeholder='E.g. Also, bring joy and good will.'
              defaultValue={data?.data.whatToBring}
            ></textarea>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.input}>
            <label className='margin-top-16' htmlFor='description'>
              How to link
            </label>
            <input
              type='text'
              id='howToUrl'
              name='howToUrl'
              ref={register}
              className={`${errors.title && 'border-red'}`}
              defaultValue={data?.data.howToUrl}
            />
          </div>
          <div className={styles.input}>
            <label
              className='margin-top-16 margin-bottom-8'
              htmlFor='description'
            >
              Recommended month
            </label>
            <Controller
              name='recommendedMonth'
              control={control}
              render={({ ref, onChange, ...props }) => (
                <CobuSelect
                  options={recommendedMonthOptions}
                  {...props}
                  onChange={(selectedOption: SelectedOptionType | null) => {
                    onChange(selectedOption?.value);
                    setSelectedMonth(selectedOption?.value);
                  }}
                  selectType={SelectType.Form}
                  defaultValue={
                    selectedMonth
                      ? {
                          value: selectedMonth,
                          label: selectedMonth
                        }
                      : null
                  }
                />
              )}
            />
          </div>
        </div>
        <div>
          <label className='margin-top-16' htmlFor='image'>
            Add a photo
          </label>
          <div className={`${styles.imagePicker} margin-top-8`}>
            <ImageDropzone
              image={image || data?.data.image}
              handleDrop={handleDrop}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default GatheringTemplateForm;
