import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spacer from '~/components/Spacer/Spacer';
import classes from './UGC.module.scss';
import useGetBuildingDetails from '~/hooks/building/useGetBuildingDetails';
import { useHistory, useParams } from 'react-router-dom';
import { Building } from '~/types/building';
import CobuTable from '~/components/CobuTable/CobuTable';
import { useForm } from 'react-hook-form';
import { IKeyWithPlaceholder, ITableData, IUGCForm } from './interfaces';
import { jsSnippetFormField, otherFormFields, TableKeys } from './constants';
import { FormField } from '~/components/FormField';
import { FormInputConfig } from '~/types/residentManagement';
import { CellProps } from 'react-table';
import useUGC from './hooks';
import Spinner from '~/components/Spinner/Spinner';
import { IUGCDefault, IUGCSetting } from '~/api/userGeneratedContent';
import { Colors } from '~/enums/Colors';
import { toast } from 'react-toastify';
import { pick } from 'lodash';

const env = process.env.NODE_ENV;

const domain = {
  production: 'api.doorbell.me',
  development: 'staging.doorbell.me',
  test: 'localhost:1337'
};

export const UGC = () => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const buildingDetails: null | Partial<Building> = useGetBuildingDetails(
    buildingId,
    true
  );

  const history = useHistory();

  const { isLoading, ugcTableData, ugcSetting, onSubmit } = useUGC(buildingId);

  const {
    register,
    handleSubmit,
    errors,
    formState: { isDirty, dirtyFields },
    control,
    reset
  } = useForm<IUGCForm>({
    defaultValues: {
      chat_on: true,
      marketplace_on: true,
      events_on: true,
      groups_on: true,
      video_on: true,
      chat_past_days: 90,
      marketplace_past_days: 90,
      events_past_days: 90,
      events_future_days: 90,
      snippet: `<script>
    window.highlightcolor = "#EBAD39";
    window.buttoncolor = "#EBAD39";
    window.accentcolor = "#7F7F7F";
    window.building_id = "${buildingId}";
</script>
<script src="http://${domain[env]}/js/mycommunity.js" defer></script>
<div id="cobu-community"></div>`
    }
  });

  useEffect(() => {
    if (ugcSetting) {
      reset(ugcSetting);
    }
  }, [ugcSetting]);

  const cacheError = useRef(errors);

  cacheError.current = errors;

  const generateField = useCallback((fieldConfig: FormInputConfig) => {
    return (
      <div
        key={fieldConfig.id}
        style={fieldConfig.displayNone ? { display: 'none' } : {}}
      >
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={{ color: Colors.cobuDarkBlue }}
              className={`${fieldConfig?.styles?.lableClassName} sub-heading semi-bold`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={cacheError.current}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'SECTIONS',
        accessor: 'source',
        Cell: (cell: CellProps<ITableData>) => {
          if (!cell.value) {
            return null;
          }

          const { key, placeholder } = cell.value;

          return (
            <div key={key} className={classes.sourceContainer}>
              {generateField({
                id: key,
                inputType: 'switch',
                isRequired: false
              })}
              {placeholder}
            </div>
          );
        }
      },
      {
        Header: 'HOW MANY DAYS IN THE PAST',
        accessor: TableKeys.DaysInPast,
        Cell: (cell: CellProps<ITableData>) => {
          if (!cell.value) {
            return null;
          }

          const { key } = cell.value;

          //@ts-ignore
          const isRequired = !!dirtyFields[cell.row.original.source.key];

          return generateField({
            id: key,
            inputType: 'text',
            isRequired: isRequired
          });
        }
      },
      {
        Header: 'HOW MANY DAYS IN THE FUTURE',
        accessor: TableKeys.DaysInFuture,
        Cell: (cell: CellProps<ITableData>) => {
          if (!cell.value) {
            return null;
          }

          const { key } = cell.value;

          //@ts-ignore
          const isRequired = !!dirtyFields[cell.row.original.source.key];

          return generateField({
            id: key,
            inputType: 'text',
            isRequired: isRequired
          });
        }
      },
      {
        Header: 'PAGE SNIPPET',
        accessor: TableKeys.PageSnippet,
        Cell: (cell: CellProps<ITableData>) => {
          if (!cell.value) {
            return null;
          }

          const { key }: IKeyWithPlaceholder = cell.value;

          //@ts-ignore
          const filledData = ugcSetting ? ugcSetting[key] : '';

          return generateField({
            id: key,
            inputType: 'textarea',
            isRequired: false,
            inputTypeProps: { rows: 2, cols: 40 }
          });

        }
      },
      {
        Header: 'ACTIONS',
        accessor: '',
        Cell: (cell: CellProps<ITableData>) => {
          const {
            [TableKeys.Source]: { key },
            daysInPast
          } = cell.row.original;

          const dayInPastKey = daysInPast?.key || '';
          const isChatOrMarketplaceorVideos =
            key.includes('chat') ||
            key.includes('marketplace') ||
            key.includes('video');

          if (!isChatOrMarketplaceorVideos) {
            return null;
          }

          const isMarketplace = key.includes('marketplace');
          const isVideo = key.includes('video');
          return (
            <div
              className={`btn-standard btn-secondary underline-text cursor-pointer ${classes.textButton}`}
              onClick={() => {
                if (isMarketplace) {
                  if (!ugcSetting?.marketplaceGroupId) {
                    toast('Group doesnt exist!');

                    return;
                  }
                }

                const filledData =
                  //@ts-ignore
                  ugcSetting && dayInPastKey ? ugcSetting[dayInPastKey] : '';

                const queryParams = new URLSearchParams({
                  ...(isMarketplace && ugcSetting?.marketplaceGroupId
                    ? { groupId: ugcSetting?.marketplaceGroupId }
                    : {}),
                  ...(filledData ? { days: filledData } : {}),
                  ...(dayInPastKey ? { id: dayInPastKey } : {}),
                  ...(isVideo ? { type: 'video' } : {})
                }).toString();

                history.push(
                  `/building/${buildingId}/UGC/${ugcSetting?.uuid}?${queryParams}`
                );
              }}
            >
              Manage Visible Content
            </div>
          );
        }
      }
    ],
    [ugcSetting, dirtyFields]
  );

  return (
    <div className={`${classes.container} float-spinner-parent`}>
      {isLoading && <Spinner float />}
      <Breadcrumbs />
      <Spacer height={20} />
      {buildingDetails && (
        <h2>{(buildingDetails as Partial<Building>).name}</h2>
      )}
      <form
        onSubmit={handleSubmit((formData) => {
          if (!isDirty) {
            toast('Please edit the form');
            return;
          }

          const { uuid, shortcode, ...rest } = formData as IUGCSetting;

          //@ts-ignore
          const pickedData: IUGCDefault = pick(rest, Object.keys(dirtyFields));

          onSubmit({ uuid, ...pickedData });
        })}
      >
        <CobuTable
          ignoreDefaultSortBehaviour
          columns={columns}
          data={ugcTableData ?? []}
        />
        <Spacer height={100} />
        <div className={classes.grid}>
          <div className={classes.grid}>
            {otherFormFields.map(generateField)}
          </div>
          <div>{generateField(jsSnippetFormField)}</div>
        </div>
        <button
          className='btn-standard btn-primary margin-left-16 '
          type='submit'
          style={{ alignSelf: 'flex-end' }}
        >
          {ugcSetting ? 'Save' : 'Create'}
        </button>
      </form>
    </div>
  );
};
