import React from 'react';
import { CreateBuildingForm } from '~/components/CreateBuildingForm/CreateBuildingForm';

interface CreateEditBuildingProps {}

const CreateBuildingScreen: React.FC<CreateEditBuildingProps> = () => {
  return (
    <div>
      <CreateBuildingForm />
    </div>
  );
};

export default CreateBuildingScreen;
