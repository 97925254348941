import { CellProps } from 'react-table';
import { QuestionSubType } from '../constants';

export const npsColumns = [
  {
    Header: 'Total Responses',
    accessor: 'totalRes'
  },
  {
    Header: 'Average Rating',
    accessor: 'averageRating'
  },
  {
    Header: 'Total Promoter',
    accessor: 'totalPromoter'
  },
  {
    Header: 'Total Detractor',
    accessor: 'totalDetractor'
  },
  {
    Header: 'NPS Score',
    accessor: 'npsScore'
  }
];

export const QuestionTypeColorCodes = {
  [QuestionSubType.CONNECTION]: '#B5B5B5',
  [QuestionSubType.INFLUENCE]: '#A7DE72',
  [QuestionSubType.MEMBERSHIP]: '#25BCD3',
  [QuestionSubType.NEEDS]: '#DC5B58'
};
