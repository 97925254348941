import CobuConstants, { CobuFormErrors } from '~/helpers/cobuConstants';
import {
  emailRegex,
  featureIdRegex,
  isValidBuildingName,
  nameRegex,
  uriRegex
} from '~/helpers/helpers';
import { ISelectOption } from '~/helpers/interfaces';
import {
  Building,
  IBuildingAdditionalURL,
  IPropertyEmail
} from '~/types/building';
import { FormInputConfig } from '~/types/residentManagement';
import React, { ReactNode } from 'react';

export const timezoneOptions = [
  { label: 'Eastern', value: 'America/New_York' },
  { label: 'Central', value: 'America/Chicago' },
  { label: 'Mountain', value: 'America/Denver' },
  { label: 'Pacific', value: 'America/Los_Angeles' },
  { label: 'Alaska', value: 'America/Anchorage' },
  { label: 'Hawaii–Aleutian', value: 'Pacific/Honolulu' },
  { label: 'Arizona', value: 'America/Phoenix' }
];

export const getCreateBuildingFormConfig = (
  timeZones: any[]
): FormInputConfig[] => [
  {
    label: CobuConstants.createBuilding.buildingName,
    id: 'name' as keyof Building,
    pattern: isValidBuildingName(),
    patternErrorMsg: CobuFormErrors.namePatternError,
    placeholder: 'Building Name'
  },
  {
    label: CobuConstants.createBuilding.displayShortName,
    id: 'shortName' as keyof Building,
    pattern: isValidBuildingName(),
    patternErrorMsg: CobuFormErrors.namePatternError,
    placeholder: 'Building Short Name'
  },
  {
    label: CobuConstants.createBuilding.timeZone,
    id: 'timezone' as keyof Building,
    inputType: 'select',
    selectOptions: timeZones
  },
  {
    label: CobuConstants.createBuilding.vcm,
    id: 'VCM' as keyof Building,
    readOnly: true,
    isRequired: true,
    placeholder: 'Number of VCM'
  },
  {
    label: CobuConstants.createBuilding.residentPortalLink,
    id: 'residentPortalURL' as keyof Building,
    pattern: uriRegex(),
    isRequired: false,
    patternErrorMsg: CobuFormErrors.uriPatternError,
    placeholder: 'Resident portal link'
  },
  {
    label: CobuConstants.createBuilding.contactInfoUrl,
    id: 'contactInfoURL' as keyof Building,
    pattern: uriRegex(),
    isRequired: false,
    patternErrorMsg: CobuFormErrors.uriPatternError,
    placeholder: 'Contact info link'
  },
  {
    label: CobuConstants.createBuilding.pollsLink,
    id: 'pollsLink' as keyof Building,
    pattern: uriRegex(),
    isRequired: false,
    patternErrorMsg: CobuFormErrors.uriPatternError,
    placeholder: 'Polls link'
  },
  {
    label: CobuConstants.createBuilding.rafflesLink,
    id: 'rafflesLink' as keyof Building,
    pattern: uriRegex(),
    isRequired: false,
    patternErrorMsg: CobuFormErrors.uriPatternError,
    placeholder: 'Raffles link'
  },
  {
    label: 'PMS Software',
    id: 'pm_software' as keyof Building,
    isRequired: false
  },
  {
    label: 'Property Type',
    id: 'property_type' as keyof Building,
    isRequired: false
  },
  {
    label: 'Property Website URL',
    id: 'propertyWebsiteURL' as keyof Building,
    inputType: 'date',
    isRequired: false
  }
];

export const getEditBuildingFormConfig = (
  timeZones: any[]
): FormInputConfig[] => [
  {
    label: CobuConstants.createBuilding.buildingName,
    id: 'name' as keyof Building,
    pattern: isValidBuildingName(),
    patternErrorMsg: CobuFormErrors.namePatternError,
    placeholder: 'Building Name'
  },
  {
    label: CobuConstants.createBuilding.displayShortName,
    id: 'shortName' as keyof Building,
    pattern: isValidBuildingName(),
    patternErrorMsg: CobuFormErrors.namePatternError,
    placeholder: 'Building Short Name'
  },
  {
    label: CobuConstants.createBuilding.timeZone,
    id: 'timezone' as keyof Building,
    inputType: 'select',
    selectOptions: timeZones
  },
  {
    label: CobuConstants.createBuilding.vcm,
    id: 'VCM' as keyof Building,
    readOnly: true,
    isRequired: true,
    placeholder: 'Number of VCM'
  },
  {
    label: CobuConstants.createBuilding.residentPortalLink,
    id: 'residentPortalURL' as keyof Building,
    pattern: uriRegex(),
    isRequired: false,
    patternErrorMsg: CobuFormErrors.uriPatternError,
    placeholder: 'Resident portal link'
  },
  {
    label: CobuConstants.createBuilding.contactInfoUrl,
    id: 'contactInfoURL' as keyof Building,
    pattern: uriRegex(),
    isRequired: false,
    patternErrorMsg: CobuFormErrors.uriPatternError,
    placeholder: 'Contact info link'
  },
  {
    label: CobuConstants.createBuilding.pollsLink,
    id: 'pollsLink' as keyof Building,
    pattern: uriRegex(),
    isRequired: false,
    patternErrorMsg: CobuFormErrors.uriPatternError,
    placeholder: 'Polls link'
  },
  {
    label: CobuConstants.createBuilding.rafflesLink,
    id: 'rafflesLink' as keyof Building,
    pattern: uriRegex(),
    isRequired: false,
    patternErrorMsg: CobuFormErrors.uriPatternError,
    placeholder: 'Raffles link'
  },
  {
    label: 'Building Go Live Date',
    id: 'buildingGoLive' as keyof Building,
    inputType: 'date',
    isRequired: false
  },
  {
    label: 'Review Outreach Start Date',
    id: 'startReviewOutreach' as keyof Building,
    inputType: 'date',
    isRequired: false
  }
];

export const reviewUrlConfig: FormInputConfig[] = [
  {
    label: 'Review Outreach Start Date',
    id: 'startReviewOutreach' as keyof Building,
    inputType: 'date',
    isRequired: false
  },
  {
    label: CobuConstants.createBuilding.reviewUrl,
    id: 'reviewUrl' as keyof Building,
    pattern: uriRegex(),
    isRequired: false,
    patternErrorMsg: CobuFormErrors.uriPatternError,
    placeholder: 'Review URL'
  }
];

export const editReviewUrlConfig: FormInputConfig = {
  label: CobuConstants.createBuilding.reviewUrl,
  id: 'reviewUrl' as keyof Building,
  pattern: uriRegex(),
  isRequired: false,
  patternErrorMsg: CobuFormErrors.uriPatternError,
  placeholder: 'Review URL'
};

export const buildingEmailsFormFiled: FormInputConfig = {
  label: `${CobuConstants.createBuilding.reportEmails} \u00A0\u00A0\u00A0 ${CobuConstants.createBuilding.oneRecipientPerLine}`,
  id: 'reportEmails' as keyof Building,
  isRequired: false,
  inputType: 'textarea',
  placeholder: 'Recipient emails',
  validations: (emails) => {
    if (!emails) {
      return true;
    }

    const emailRegExpression = emailRegex();

    const isValidEmails = emails
      .split('\n')
      .every((email: string) => emailRegExpression.test(email));

    return isValidEmails ? true : 'Please enter valid emails';
  }
};

export const emailRoles = {
  owner: 'Owner',
  onsite: 'On-Site',
  regional: 'Regional',
  marketing: 'Marketing'
};

export const buildingImageFormField: FormInputConfig = {
  label: CobuConstants.createBuilding.buildingImage,
  id: 'image' as keyof Building,
  isRequired: true,
  inputType: 'imageDropzone'
};

export const editBuildingImageFormField: FormInputConfig = {
  label: CobuConstants.createBuilding.buildingImage,
  id: 'image' as keyof Building,
  isRequired: true,
  inputType: 'imageDropzone',
  styles: { lableStyles: { margin: '10px 0' } }
};

export const buildingLogoFormField: FormInputConfig = {
  label: CobuConstants.createBuilding.buildingLogo,
  id: 'propertyLogoUrl' as keyof Building,
  inputType: 'imageDropzone'
};

export const editBuildingLogoFormField: FormInputConfig = {
  label: CobuConstants.createBuilding.buildingLogo,
  id: 'propertyLogoUrl' as keyof Building,
  inputType: 'imageDropzone',
  styles: { lableStyles: { margin: '20px 0 10px 0' } }
};

export const boardingOptionsFormField: FormInputConfig[] = [
  {
    label: 'Onboarding Skippable',
    id: 'onboardingSkippable' as keyof Building,
    isRequired: false,
    placeholder: 'Onboarding Skippable',
    styles: { lableStyles: { fontSize: '18px', marginBottom: '10px' } },
    inputType: 'switch'
  },
  {
    label: CobuConstants.createBuilding.raffleDescription,
    id: 'raffleDescription' as keyof Building,
    isRequired: true,
    placeholder: 'Raffle Description',
    styles: { lableStyles: { fontSize: '18px' } },
    inputType: 'textarea'
  },
  {
    label: 'Raffle Amount',
    id: 'raffleAmount' as keyof Building,
    isRequired: true,
    placeholder: 'eg: 200',
    styles: {
      lableStyles: { fontSize: '18px' },
      contentStyles: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '10px',
        alignItems: 'center'
      },
      inputStyles: { width: '200px' }
    },
    sibling: (
      <div style={{ fontSize: 16 }}>
        When amount is set, automatically posts about the raffle will be
        configured
      </div>
    )
  },
  {
    label: 'Building Go Live Date',
    id: 'buildingGoLive' as keyof Building,
    styles: {
      lableStyles: { fontSize: '18px' },
      contentStyles: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '10px',
        alignItems: 'center'
      },
      inputStyles: { width: '200px' }
    },
    sibling: (
      <div style={{ fontSize: 16 }}>
        When Live Date is set, automatically posts about the raffle will be
        configured
      </div>
    ),
    inputType: 'date',
    isRequired: false
  },
  {
    label: CobuConstants.createBuilding.endDateOfRaffle,
    id: 'raffleEndDate' as keyof Building,
    styles: {
      lableStyles: { fontSize: '18px' },
      inputStyles: { width: '200px' }
    },
    inputType: 'date',
    isRequired: true,
    validations: (value) => {
      const currentDate = new Date();
      return (
        (new Date(value) < currentDate && 'Please select a future date.') ||
        true
      );
    }
  }
];

export const additionalGoogleReviewFormFields: FormInputConfig[] = [
  {
    id: 'building' as keyof IBuildingAdditionalURL,
    isRequired: false,
    placeholder: 'Propery Name'
  },
  {
    id: 'url' as keyof IBuildingAdditionalURL,
    isRequired: false,
    placeholder: 'Review URL',
    pattern: uriRegex(),
    patternErrorMsg: CobuFormErrors.uriPatternError
  }
];

export const featureIdsFormFields: FormInputConfig[] = [
  {
    id: 'building' as keyof IBuildingAdditionalURL,
    isRequired: false,
    placeholder: 'Propery Name'
  },
  {
    id: 'featureId' as keyof IBuildingAdditionalURL,
    isRequired: false,
    placeholder: 'Feature ID',
    validations: (value: string) => {
      return value
        ? (!featureIdRegex().test(value) &&
            'Please enter a valid feature ID') ||
            true
        : true;
    }
  }
];
