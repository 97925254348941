import React, { FC } from 'react';
import { PacmanLoader } from 'react-spinners';

import classes from './Spinner.module.css';

interface IProps {
  float?: boolean;
  verticalAlign?: string;
}

const Spinner: FC<IProps> = ({ float = false, verticalAlign = 'center' }) => {
  //TODO color in enum
  return (
    <div
      style={{ alignItems: verticalAlign }}
      className={`${classes.spinner} ${float ? classes.floatSpinner : ''}`}
    >
      <PacmanLoader color={'#fec100'} />
    </div>
  );
};

export default Spinner;
