import { AxiosResponse } from 'axios';
import {
  IReferralConfigSetting,
  IReferralSettingEntrataPMS,
  IReferralSettingForm,
  IReferralSettingYardiPMS
} from '~/containers/Referral/interfaces';
import { emperor } from '~/services';
import { Building } from '~/types/building';
import { UserMin } from '~/types/user';
import {
  addNewEntrataIntegration,
  updateBuildingEntrataIntegration
} from './buildingEntrataIntegration';
import { convertKeyValueToFormData } from '~/helpers/helpers';
import { BuildingEntrataIntegration } from '~/types/buildingEntrataIntegration';

interface IReferralDetails {
  logoBackgroundColor?: string;
}

export interface IReferralPageOption {
  uuid: string;
  name: string;
  details: JSON;
}

export interface IReferralOffer {
  uuid: string;
  building: string;
  residentPayout: string;
  cobuPayout: string;
  referralStatus: string;
  payoutType: string;
  referralLandingPageOption: IReferralPageOption | string;
  title: string;
  bannerTitle: string;
  description: string;
  rulesAndEligibility: string;
  images: string[];
  startDate: string;
  endDate: string;
  landingpageIntroParagraph: string;
  employeeLandingPageIntroParagraph: string;
  details?: IReferralDetails;
  landingPageTitleAction?: string;
  employeeLandingPageTitleAction?: string;
  landingPageLocationBlurb?: string;
  popupTitle?: string;
  employeePopupTitle?: string;
  popupDescription?: string;
  employeePopupDescription?: string;
  landingPageFormButton?: string;
  landingPageCongratsDescription?: string;
  employeeLandingPagecongratsDescription?: string;
  landingPageCongratsTitle?: string;
  landingPageCongratsNext?: string;
  incentiveType?: string;
}

export interface IReferralLead {
  buildingId: string;
  firstName: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  leadReferredBy: string;
  referral_offer: string;
  confirmationEmail?: boolean;
  desiredMoveInDate?: string;
}

export interface ICommunityLead {
  uuid: string;
  referredBy: string;
  roomNumber: string;
  createdAt: string;
  leadName: string;
  leadEmail: string;
  status: string;
  isEmployee: boolean;
  signed_lease: boolean;
  lease_amount?: string;
  referral_bonus_paid: boolean;
  comments?: string;
  cobu_receive_credit: boolean;
}

export interface IReferralHelloData {
  address: string;
  building_phone_number: string;
  review_analysis: {
    avg_score: number;
    count_reviews: string;
  };
  building_availability: {
    exit_market: null;
    bed: number;
    min_price: number;
    price: number;
  }[];
  details: {
    building_amenities: Array<string>;
    cats_deposit?: number;
    cats_monthly_rent?: number;
    cats_one_time_fee?: number;
    dogs_deposit?: number;
    dogs_monthly_rent?: number;
    dogs_one_time_fee?: number;
    concessions_history: Record<string, any>[];
  };
}

export interface IReferralFormData {
  referralOffer: IReferralOffer;
  referredUser: UserMin;
  building: Building;
  helloData: IReferralHelloData;
  avgReviewRating?: { avgReviewRating: string; totalReviews: string };
  userCustomization: {
    details: { includeProfileImage: boolean };
    welcome_message: string;
  };
}

export interface IPayoutTypes {
  uuid: string;
  name: string;
}
export interface IReferralStatus {
  uuid: string;
  name: string;
}
export interface IIncentiveType {
  uuid: string;
  name: string;
}

export interface IReferralExternalEmail {
  uuid: string;
  building: string;
  email: string;
}
export interface IReferralKnockEmail {
  uuid: string;
  building: string;
  email: string;
}

export interface IReferralKnockEmail {
  uuid: string;
  building: string;
  email: string;
}

export const postReferralLead = async (
  payload: IReferralLead,
  referralLandingPage: string,
  details = {}
): Promise<AxiosResponse<IReferralLead>> => {
  return await emperor.post('/referral/create-referral-lead', {
    ...payload,
    details: details,
    referralLandingPage
  });
};

export const getReferralOffer = async (
  buildingId: string
): Promise<AxiosResponse<IReferralOffer>> => {
  return await emperor.get('/referral/referral-offer', {
    params: { buildingId, platform: 'admin' }
  });
};

export const postReferralOffer = async (
  payload: FormData
): Promise<AxiosResponse<IReferralOffer>> => {
  return await emperor.post('/referral/create-referral-offer', payload);
};

export const getPayoutOptions = async (): Promise<
  AxiosResponse<IPayoutTypes[]>
> => {
  return await emperor.get('/referral/get-payout-types');
};

export const getIncentiveOptions = async (): Promise<
  AxiosResponse<IIncentiveType[]>
> => {
  return await emperor.get('/referral/incentive-options');
};

export const getReferralStatusOptions = async (): Promise<
  AxiosResponse<IReferralStatus[]>
> => {
  return await emperor.get('/referral/get-referral-status');
};

export const getReferralLeads = async (
  buildingId: string
): Promise<AxiosResponse<ICommunityLead[]>> => {
  return await emperor.get('/referral/referral-leads', {
    params: { buildingId }
  });
};

export const getReferralFormData = async (
  referralCode: string
): Promise<AxiosResponse<IReferralFormData>> => {
  return await emperor.get('/referral/get-referral-form', {
    params: { referralCode }
  });
};

export const postCommunityLeadSetting = async (
  buildingId: string,
  settings: IReferralConfigSetting
): Promise<void> => {
  return await emperor.post('/referral/community-lead-setting', {
    buildingId,
    ...settings
  });
};

export const postReferralYardiIntegration = async (payload: {
  buildingId: string;
  agentName?: string;
  credentials?: string;
  leadSourceName?: string;
  yardiVersion?: string;
  settings: IReferralSettingYardiPMS;
}): Promise<void> => {
  const {
    agentName,
    settings,
    credentials,
    leadSourceName,
    buildingId,
    yardiVersion
  } = payload;

  return await emperor.put('put-yardi-integration', {
    ...(settings.yardiUuid ? { uuid: settings.yardiUuid } : {}),
    building: buildingId,
    yardi_property_id: settings.yardi_property_id,
    leadSourceId: settings.yardiLeadSourceId,
    credentials: credentials,
    default: true,
    details: {
      agentName,
      leadSourceName,
      yardiVersion
    },
    unmarkOtherDefault: true
  });
};

export const postReferralEntrataIntegration = async (
  buildingId: string,
  integration: IReferralSettingEntrataPMS,
  enableSlackNotification = false
): Promise<void> => {
  if (integration.entrataUuid) {
    await updateBuildingEntrataIntegration({
      ...integration,
      uuid: integration.entrataUuid,
      building: buildingId,
      leadSourceId: integration.entrataLeadSourceId,
      default: true,
      unmarkOtherDefault: true
    });

    return;
  }

  await addNewEntrataIntegration(
    convertKeyValueToFormData({
      domain: integration.entrata_domain,
      propertyId: integration.entrata_property_id,
      username: integration.entrata_username,
      password: integration.entrata_password,
      leadSourceId: integration.entrataLeadSourceId,
      building: buildingId,
      default: true,
      unmarkOtherDefault: true,
      enableSlackNotification
    })
  );
};

export const postReferralExternalEmails = async (
  buildingId: string,
  emails: string
): Promise<void> => {
  return await emperor.put('/put-referral-external-emails', {
    buildingUuid: buildingId,
    emails
  });
};

export const putReferralKnockEmail = async (
  buildingId: string,
  email: string
): Promise<void> => {
  return await emperor.put('/put-referral-knock-email', {
    buildingUuid: buildingId,
    email
  });
};

export const getReferralExternalEmails = async (
  buildingId: string
): Promise<AxiosResponse<IReferralExternalEmail>> => {
  return await emperor.get('/get-referral-external-emails', {
    params: { buildingUuid: buildingId }
  });
};

export const getReferralKnockEmail = async (
  buildingId: string
): Promise<AxiosResponse<IReferralKnockEmail>> => {
  return await emperor.get('/get-referral-knock-email', {
    params: { buildingUuid: buildingId }
  });
};

export const getLandingPageOptions = async (): Promise<
  AxiosResponse<IPayoutTypes[]>
> => {
  return await emperor.get('/referral/landing-page-options');
};

export const syncReferralIntercomForBuildingUsers = async (
  buildingId: string
): Promise<void> => {
  return await emperor.patch('/intercom/building-users-referral-attributes', {
    buildingId: buildingId
  });
};

export const patchIntercomUserReferralDetails = async (
  uuid: string //user uuid
): Promise<void> => {
  return await emperor.patch('/intercom/building-user-referral-attributes', {
    uuid
  });
};

export const getUserReferralLink = async (
  buildingId: string, //user uuid
  userId: string //user uuid
): Promise<AxiosResponse<string>> => {
  return await emperor.get('/referral/get-referral-link', {
    params: {
      buildingId,
      userId
    }
  });
};

export const patchLeadManagementDetails = async (
  leadUuid: string,
  formData: Partial<ICommunityLead>
): Promise<AxiosResponse<string>> => {
  return await emperor.patch('/referral/lead-management', {
    leadUuid,
    ...formData
  });
};
