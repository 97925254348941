import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import Switch from 'react-ios-switch';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Building } from '~/types/building';
import {
  GatheringFormType,
  GatheringType,
  RecurringType,
  EventType
} from '~/types/gathering';
import { GroupType } from '~/types/group';
import classes from './GatheringForm.module.css';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import { fetchBuildingEventTypes, fetchBuildingGroups } from '~/api/building';
import { FormActions } from '~/enums/FormActions';
import { PostDataResult } from '~/types/postDataResult';
import RadioButtons from '../RadioButtons/RadioButtons';
import useIsMounted from '~/hooks/isMounted';
import { SelectedOptionType } from '~/types/form';
import CobuSelect from '../CobuSelect/CobuSelect';
import { SelectType } from '~/enums/SelectType';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/store';
import { buildingSelector } from '~/redux/selectors';
import { useBuildingDashboardConfig } from '~/hooks/useBuildingDashboardConfig';
import { Roles } from '~/enums/Roles';
import { Colors } from '~/enums/Colors';
import { getEventDuration } from '../GatheringsTable/GatheringsTable';
import { toast } from 'react-toastify';
import { getRecurringTypes } from '~/api/gatherings';

interface IProps {
  gathering?: GatheringType | null;
  buildings: Building[];
  onSubmit: (
    gathering: GatheringFormType
  ) => Promise<PostDataResult<GatheringFormType>> | void;
  formAction: FormActions;
  buildingUserOptions: SelectedOptionType[];
}

const GatheringForm = (props: IProps) => {
  const { gathering, onSubmit, formAction, buildings, buildingUserOptions } =
    props;
  const { buildingId } = useParams<{ buildingId: string }>();
  const [groups, setGroups] = useState<any[]>([]);
  const [eventTypes, setEventTypes] = useState<any[]>([]);
  
  const commitTypes = [
    {
      id: 'e8a642e9-7fe0-4f5a-9279-4cec3db10dd2',
      name: 'RSVP'
    },
    {
      id: '45890325-4e1d-4364-8aea-a13b2cf4c02c',
      name: 'Interested',
    },
    {
      id: 'b4a735d1-581b-4321-85a9-818ce6b2ff0a',
      name: 'Register'
    }
  ]


  const rsvpID = 'e8a642e9-7fe0-4f5a-9279-4cec3db10dd2';
  const registerID = 'b4a735d1-581b-4321-85a9-818ce6b2ff0a';
  const interestedID = '45890325-4e1d-4364-8aea-a13b2cf4c02c';



  const imageForm = gathering ? gathering.image : '';
  const [image, setImage] = useState<File | string>(imageForm);
  const [customEventDates, setcustomEventDate] = useState<string[]>([]);
  const [eventExceptionDates, seteventExceptionDates] = useState<
    { date: string; notes: string }[]
  >([]);
  const [endTimeSelectorVisible, setendTimeSelectorVisible] =
    useState<boolean>(false);
  const [customDatesEnabled, setcustomDatesEnabled] = useState<boolean>(false);
  const [exceptionDatesEnabled, setexceptionDatesEnabled] =
    useState<boolean>(false);
  const [recurringTypes, setrecurringTypes] = useState<RecurringType[]>([]);
  const isMounted = useIsMounted();
  const history = useHistory();

  const userRole = useSelector((state: RootState) => state.auth.userRole);

  const currentBuilding = useSelector((state: RootState) =>
    buildingSelector(state, buildingId)
  );

  const isVcmOrSuperAdmin =
    userRole === Roles.Vcm || userRole === Roles.Superadmin;

  const { data: buildingDashboardConfigData } =
    useBuildingDashboardConfig(buildingId);

  const buildingTimezone =
    gathering?.buildingTimezone || currentBuilding?.timezone;

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    getValues,
    formState,
    control
  } = useForm<GatheringFormType>({
    defaultValues: {
      title: gathering?.title,
      description: gathering?.description,
      building: buildingId,
      eventType: gathering?.eventType,
      group: gathering?.groupId,
      locationString: gathering?.locationString,
      startTime: gathering?.scheduledStartTime
        ? moment(gathering?.scheduledStartTime)
          .tz(buildingTimezone!)
          .format('YYYY-MM-DDTHH:mm')
        : undefined,
      endTime: undefined,
      customEventDatetime: undefined,
      eventExceptionDate: undefined,
      eventExceptionDateNote: '',
      recurrentEndDate: moment(gathering?.startTime)
        .tz(buildingTimezone!)
        .add(4, 'months')
        .format('YYYY-MM-DD'),
      maxEventAttendees: gathering?.maxEventAttendees,
      maxGuests: gathering?.maxGuests,
      image: gathering?.image,
      whatToBring: gathering?.whatToBring,
      sendEventReminder: gathering ? gathering.sendEventReminder : false,
      reminder_3_day: gathering ? gathering.reminder_3_day : false,
      reminder_1_day: gathering ? gathering.reminder_1_day : false,
      flyerRequest: gathering && gathering.flyerRequest ? gathering.flyerRequest : false,
      reqAutoReminder: gathering && gathering.reqAutoReminder ? gathering.reqAutoReminder : false,
      recurringType: undefined,
      registrationURL: gathering?.registrationURL,
      commitType: gathering?.commitType,
    }
  });

  const watchBuilding = watch('building');
  const watchCommitID = watch('commitType');
  const watchRecurringType = watch('recurringType');

  const isRecurringEvent = useMemo(() => {
    const selectedRecurringType = recurringTypes.find(
      (el) => el.uuid === watchRecurringType
    );

    return selectedRecurringType?.name != 'One Time';
  }, [watchRecurringType]);

  const isCustomEvent = useMemo(() => {
    const selectedRecurringType = recurringTypes.find(
      (el) => el.uuid === watchRecurringType
    );

    return selectedRecurringType?.name == 'Custom';
  }, [watchRecurringType]);

  const isWeekly = useMemo(() => {
    const selectedRecurringType = recurringTypes.find(
      (el) => el.uuid === watchRecurringType
    );

    return selectedRecurringType?.name == 'Weekly';
  }, [watchRecurringType]);

  const startTime = watch('startTime');
  const endTime = watch('endTime');
  const recurrentEndDate = watch('recurrentEndDate');

  const getRecurringLabel = () => {
    if (!isRecurringEvent || isCustomEvent) return <div></div>;

    const momentStartTime =
      formAction == FormActions.Edit ? moment(startTime) : moment(startTime);

    const momentEndrecurrentEndDate =
      formAction == FormActions.Edit
        ? moment(gathering?.recurrentEndDate).utc(true)
        : moment(recurrentEndDate);

    if (!momentStartTime.isValid()) return;

    return (
      <p>
        Event occurs every week on <b>{momentStartTime.format('dddd')}</b> at
        {'  '}
        <b>{momentStartTime.format('hh:mm A')}</b>
        {'  '}, starting from
        <b> {momentStartTime.format('ddd, DD MMM YYYY')}</b>
        {'  '}
        till <b>{momentEndrecurrentEndDate.format('ddd, DD MMM YYYY')}</b>
      </p>
    );
  };

  const watchEventType = watch('eventType');

  const fetchRecurringTypes = () => {
    getRecurringTypes().then((data) => {
      setrecurringTypes(data);
      if (formAction == FormActions.Create) {
        setValue(
          'recurringType',
          data.find((el) => el.name == 'One Time')?.uuid
        );
      }
    });
  };

  useEffect(() => {
    if (watchEventType && eventTypes.length && !gathering) {
      let eventType = eventTypes.find((e) => e.uuid === watchEventType);
      setValue('reqAutoReminder', eventType.autoReminder);
    }
  }, [watchEventType]);

  useEffect(() => {
    if (isCustomEvent) {
      setcustomDatesEnabled(true);
    }
  }, [isCustomEvent]);

  useEffect(() => {
    fetchRecurringTypes();
  }, []);

  const { isSubmitting } = formState;

  useEffect(() => {

    fetchBuildingGroups(watchBuilding || buildingId).then((res) => {
      if (isMounted()) {
        let groups = res.data;
        groups.sort(
          (
            x: { isDefault: any; name: string },
            y: { isDefault: any; name: any }
          ) => {
            if (x.isDefault !== y.isDefault) {
              return x.isDefault ? -1 : 1;
            }
            return x.name.localeCompare(y.name);
          }
        );
        setGroups(groups);

        if (
          formAction === FormActions.Create ||
          (groups.some((g: GroupType) => g.uuid !== getValues('group')) &&
            formAction === FormActions.Duplicate)
        ) {
          setValue('group', res.data[0].uuid);
        } else {
          setValue('group', gathering?.groupId);
        }

        if (formAction != FormActions.Create && gathering?.recurringType) {
          setValue('recurringType', gathering?.recurringType);
        }

        if (!gathering?.scheduledEndTime && formAction == FormActions.Edit) {
          setValue('endTime', undefined);
          setendTimeSelectorVisible(false);
        }

        if (gathering?.scheduledEndTime && formAction == FormActions.Edit) {
          setendTimeSelectorVisible(true);
          setValue(
            'endTime',
            moment(gathering?.scheduledEndTime)
              .tz(buildingTimezone!)
              .format('YYYY-MM-DDTHH:mm')
          );
        }

        if (gathering?.isRecurring && formAction == FormActions.Edit) {
          if (gathering.eventCustomDates?.length > 0) {
            setcustomDatesEnabled(true);
          }

          if (gathering.eventExceptionDates?.length > 0) {
            setexceptionDatesEnabled(true);
          }

          gathering?.isRecurring &&
            setValue(
              'recurrentEndDate',
              moment.utc(gathering.recurrentEndDate).format('YYYY-MM-DD')
            );
        }

        if (
          gathering &&
          Array.isArray(gathering?.eventCustomDates) &&
          gathering?.eventCustomDates.length > 0
        ) {
          setcustomEventDate(
            gathering?.eventCustomDates.map((custom) =>
              moment(custom.eventDatetime).utc(true).format('YYYY-MM-DD')
            )
          );
        }

        if (
          gathering &&
          Array.isArray(gathering?.eventExceptionDates) &&
          gathering?.eventExceptionDates?.length > 0
        ) {
          seteventExceptionDates(
            gathering?.eventExceptionDates.map((exception) => ({
              date: moment(exception.exceptionDate)
                .utc(true)
                .format('YYYY-MM-DD'),
              notes: exception.notes
            }))
          );
        }
      }
    });

    fetchBuildingEventTypes(watchBuilding || buildingId).then((res) => {
      if (isMounted()) {
        let eventTypes = res?.data?.eventTypes;
        if (formAction === FormActions.Create) {
          let modifiedEventTypes = eventTypes.filter(
            (e: { uuid: string }) =>
              e.uuid != '43bf1791-3191-4675-b4aa-0d41de217d6f'
          );
          setValue('eventType', modifiedEventTypes[0].uuid);
          setEventTypes(modifiedEventTypes);
        } else {
          if (gathering?.eventType === '43bf1791-3191-4675-b4aa-0d41de217d6f') {
            setEventTypes(eventTypes);
          } else {
            let modifiedEventTypes = eventTypes.filter(
              (e: { uuid: string }) =>
                e.uuid != '43bf1791-3191-4675-b4aa-0d41de217d6f'
            );
            setEventTypes(modifiedEventTypes);
          }
        }
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchBuilding,
    buildingId,
    formAction,
    setValue,
    getValues,
    gathering,
    isMounted
  ]);

  useEffect(() => {
    if (endTimeSelectorVisible && !moment(startTime).isBefore(endTime)) {
      setValue(
        'endTime',
        moment(startTime).add(2, 'hour').format('YYYY-MM-DDTHH:mm')
      );
    }
  }, [startTime]);

  const handleDrop = (acceptedFiles: File[]) => {
    setImage(acceptedFiles[0]);
  };

  const submitHandler = async (data: GatheringFormType) => {
    let modifiedDescription = data.description;

    console.log("********************************************");
    if (!endTimeSelectorVisible && gathering?.scheduledEndTime) {
      data.endTime = null;
    }

    if (formAction == FormActions.Create) {
      modifiedDescription = data.isRSVP
        ? `${data.description}\n\nPlease RSVP - it helps us plan better! Plus, you’ll get reminders.`
        : `${data.description}\n\nPlease express interest - it helps us plan better! Plus, you’ll get reminders.`;
    }

    const formData =
      formAction === FormActions.Create
        ? { ...data, description: modifiedDescription }
        : data;

    console.log(formData);


    try {
      onSubmit({
        ...formData,
        image,
        ...(customDatesEnabled &&
          isRecurringEvent && { eventCustomDates: customEventDates }),
        ...(exceptionDatesEnabled &&
          isRecurringEvent && { eventExceptionDates })
      });
    } catch (error) {
      console.error(error);
      toast.error(error.message ?? 'Something went wrong');
    }
  };

  const onCustomDateSelected: MouseEventHandler<any> = (e) => {
    e.preventDefault();
    const date = getValues('customEventDatetime');
    const dateMoment = moment.utc(date);
    const dateString = dateMoment.format('YYYY-MM-DD');

    const dateWithStartingTime = `${date}T${startTime.split('T')[1]}`;
    const dateWithEndingTime = endTime
      ? `${date}T${endTime.split('T')[1]}`
      : undefined;

    if (dateMoment.isBefore(moment())) {
      toast('"Custom Date" cant be in past');
      return;
    } else if (moment(dateWithStartingTime).isBefore(startTime)) {
      toast('"Custom Date" cant be before "Start Time"');
      return;
    }

    if (customEventDates.includes(dateString)) {
      toast('Selected date has already been added');
      return;
    }

    if (dateString == 'Invalid date') {
      toast('Please select a valid date');
      return;
    }

    setcustomEventDate([...customEventDates, dateString]);

    setValue('customEventDatetime', undefined);
  };

  const onExceptionDateSelected: MouseEventHandler<any> = (e) => {
    e.preventDefault();
    const date = getValues('eventExceptionDate');
    const dateMoment = moment.utc(date);
    const notes = getValues('eventExceptionDateNote');
    const dateString = dateMoment.format('YYYY-MM-DD');

    const dateWithStartingTime = `${date}T${startTime.split('T')[1]}`;
    const dateWithEndingTime = endTime
      ? `${date}T${endTime.split('T')[1]}`
      : undefined;

    const timeNow = moment();

    if (dateMoment.isBefore(timeNow)) {
      toast('"Exception Date" cant be in past');
      return;
    } else if (moment(dateWithStartingTime).isBefore(startTime)) {
      toast('"Exception Date" cant be before "Start Time"');
      return;
    }

    const dateAlreadyAdded = eventExceptionDates.find(
      (exception) => dateString == exception.date
    );

    if (dateAlreadyAdded) {
      toast('Select date has already been added');
      return;
    }

    if (dateString == 'Invalid date') {
      toast('Please select a valid date');
      return;
    }

    seteventExceptionDates([
      ...eventExceptionDates,
      { date: dateString, notes: notes }
    ]);

    setValue('eventExceptionDate', undefined);
    setValue('eventExceptionDateNote', '');
  };

  const removeCustomDate = (dateToRemove: string) => {
    setcustomEventDate(customEventDates.filter((date) => date != dateToRemove));
  };

  const removeExceptionDate = (dateToRemove: string) => {
    seteventExceptionDates(
      eventExceptionDates.filter((exception) => exception.date != dateToRemove)
    );
  };

  const canDisplayExceptionDateSelector = () =>
    isRecurringEvent && !isCustomEvent;

  const canDisplayCustomDateSelector = () => isRecurringEvent;

  const canDisplayStartAndEndDateSelector = () => {
    return (
      formAction == FormActions.Create ||
      (formAction == FormActions.Edit && !isRecurringEvent) ||
      formAction === FormActions.Duplicate
    );
  };

  const onEndTimeToggleChange = (currentSwitchState: boolean) => {
    if (!currentSwitchState) {
      setValue('endTime', null);
    } else if (currentSwitchState && !endTime && gathering?.scheduledEndTime) {
      setTimeout(() => {
        setValue(
          'endTime',
          moment(gathering?.scheduledEndTime)
            .tz(buildingTimezone!)
            .format('YYYY-MM-DDTHH:mm')
        );
      }, 0);
    } else if (currentSwitchState && !endTime && !gathering?.scheduledEndTime) {
      setTimeout(() => {
        setValue(
          'endTime',
          moment(startTime).add(2, 'hours').format('YYYY-MM-DDTHH:mm')
        );
      }, 0);
    }

    setendTimeSelectorVisible(currentSwitchState);
  };

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className={classes.formHeader}>
          <h1 className={classes.title}>Gathering</h1>
          <div className={classes.formButtons}>
            <button
              disabled={isSubmitting}
              className='btn-standard btn-primary margin-left-16 weight-500'
              type='submit'
            >
              {gathering ? 'Save' : 'Create'}
            </button>
            <button
              className='btn-standard btn-seconday weight-500'
              onClick={(e) => {
                e.preventDefault();
                formAction === FormActions.Duplicate
                  ? history.push(`/building/${gathering?.buildingId}/gathering`)
                  : history.push(`/building/${buildingId}/gathering`);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className={classes.gridContainer}>
          <div className={classes.item}>
            <label className='margin-top-16 font-16' htmlFor='building'>
              Choose Building *
            </label>
            <select
              disabled={
                formAction === FormActions.Create ||
                formAction === FormActions.Edit
              }
              name='building'
              ref={register}
              className='height-50'
            >
              {buildings.map((building: Building) => {
                return (
                  <option value={building.uuid} key={building.uuid}>
                    {building.name}
                  </option>
                );
              })}
            </select>
            <label className='margin-top-16 font-16' htmlFor='title'>
              Gathering Name *
            </label>
            <input
              type='text'
              id='title'
              name='title'
              placeholder={'E.g. Wine night in the Lobby'}
              ref={register({
                required: 'This field is required'
              })}
              className={`${errors.title && 'border-red'}`}
            />
            <span className={`color-warning ${classes.span}`}>
              {errors.title?.message}
            </span>
            <label className='margin-top-16 font-16' htmlFor='description'>
              Gathering Description *
            </label>
            <textarea
              name='description'
              id='description'
              className={`${errors.description && 'border-red'}`}
              cols={30}
              rows={3}
              ref={register({
                required: 'This field is required'
              })}
              placeholder='E.g. Also, bring joy and good will. '
            ></textarea>
            <span className={`color-warning ${classes.span}`}>
              {errors.description?.message}
            </span>
            <>
              <label className='margin-top-16 font-16' htmlFor='group'>
                Event Recurring Frequency
              </label>

              <select
                disabled={formAction === FormActions.Edit}
                name='recurringType'
                ref={register}
                className={`${errors.recurringType && 'border-red'} height-50`}
              >
                {recurringTypes.map((option: RecurringType) => {
                  return (
                    <option
                      label={option.name}
                      value={option.uuid}
                      key={option.uuid}
                    ></option>
                  );
                })}
              </select>
              {watchRecurringType && getRecurringLabel()}
            </>
            {canDisplayStartAndEndDateSelector() && (
              <>
                <>
                  <label className='margin-top-24 font-16' htmlFor='time'>
                    Select Start Date & Time *
                  </label>
                  <input
                    type='datetime-local'
                    min={moment().format('YYYY-MM-DDTHH:mm')}
                    id='startTime'
                    name='startTime'
                    ref={register({
                      required: 'This field is required',
                      validate: (value) => {
                        if (moment(value).isBefore(moment())) {
                          return '"Start time" must be in the future';
                        }
                      }
                    })}
                    className={`event-datetime-selector ${errors.startTime && 'border-red'
                      }`}
                  />
                  <span className={`color-warning ${classes.span}`}>
                    {errors.startTime?.message}
                  </span>
                </>
                <div
                  className={`margin-top-8 ${endTimeSelectorVisible
                    ? classes.borderedSection
                    : classes.unborderedSection
                    }`}
                >
                  <div className='flex-row align-center'>
                    <Switch
                      checked={endTimeSelectorVisible}
                      onChange={onEndTimeToggleChange}
                      ref={register}
                    />
                    <p className='margin-left-8 font-16'>
                      Do you want to set End Date/Time for this Event
                    </p>
                  </div>
                  {endTimeSelectorVisible && (
                    <div className='margin-top-16'>
                      <label
                        className='margin-top-16 margin-right-8 font-16'
                        htmlFor='time'
                      >
                        End Date & Time
                      </label>
                      <input
                        type='datetime-local'
                        id='endTime'
                        name='endTime'
                        min={startTime}
                        ref={register({
                          required: 'This field is required',
                          validate: (value) => {
                            if (moment(value).isBefore(startTime)) {
                              return '"End time" must be after "Start Time"';
                            } else if (moment(value).isBefore(moment())) {
                              return '"End time" must be in the future';
                            }
                          }
                        })}
                        className={`event-datetime-selector ${errors.endTime && 'border-red'
                          }`}
                      />
                    </div>
                  )}
                  <span className={`color-warning ${classes.span}`}>
                    {errors.endTime?.message}
                  </span>
                </div>
              </>
            )}
            {!canDisplayStartAndEndDateSelector() && (
              <>
                <div className='margin-top-24'>
                  <p className='font-16'>
                    Start Date - {moment(startTime).format('dddd, DD MMM YYYY')}
                  </p>
                </div>
                <div className='margin-top-16'>
                  <p className='font-16'>
                    Event Start Time - {moment(startTime).format('hh:mm A')}
                  </p>
                </div>
                <p className='margin-top-16 font-16'>
                  Event Duration -{' '}
                  {getEventDuration(gathering!.startTime, gathering!.endTime)}
                </p>
              </>
            )}
            {isWeekly && (
              <div className={`margin-top-24 ${classes.borderedSection}`}>
                <div>
                  <label className='color-dark-blue font-16' htmlFor='time'>
                    Recurring Event End Date
                  </label>
                  <p className='margin-bottom-8'>
                    Maximum allowed reccuring end date is 4 month from the date
                    of creating/editing this event
                  </p>
                </div>
                <div className='flex-row align-center'>
                  <input
                    type='date'
                    id='recurrentEndDate'
                    name='recurrentEndDate'
                    max={moment().add(6, 'month').format('YYYY-MM-DD')}
                    style={{ width: '200px' }}
                    ref={register({
                      required: 'This field is required',
                      validate: (value) => {
                        if (moment(value).isBefore(moment())) {
                          return '"Recurring Event End Date" must be in the future';
                        }
                      }
                    })}
                    className={`event-datetime-selector ${errors.recurrentEndDate && 'border-red'
                      }`}
                  />
                  <span className={`color-warning ${classes.span}`}>
                    {errors.recurrentEndDate?.message}
                  </span>
                </div>
              </div>
            )}
            {canDisplayExceptionDateSelector() && (
              <div
                className={`margin-top-24 ${exceptionDatesEnabled
                  ? classes.borderedSection
                  : classes.unborderedSection
                  }`}
              >
                <div className='flex-row align-center'>
                  <Switch
                    checked={exceptionDatesEnabled}
                    onChange={setexceptionDatesEnabled}
                    ref={register}
                  />
                  <p className='margin-left-8 font-16 font-16'>
                    Select Exception Dates
                  </p>
                  <br />
                  <p className='margin-left-8'>
                    (If you wish to excluded few dates from this gathering)
                  </p>
                </div>
                {exceptionDatesEnabled && (
                  <div className={`margin-top-16 ${classes.borderedSection}`}>
                    <label className='margin-top-16 font-16' htmlFor='time'>
                      <b>Exception Date</b>
                    </label>
                    <div className='flex-row align-center'>
                      <input
                        type='date'
                        id='eventExceptionDate'
                        name='eventExceptionDate'
                        min={moment().format('YYYY-MM-DD')}
                        style={{ width: '200px' }}
                        ref={register}
                        className={`event-datetime-selector ${errors.eventExceptionDate && 'border-red'
                          }`}
                      />
                      <input
                        type='textarea'
                        id='eventExceptionDateNote'
                        name='eventExceptionDateNote'
                        style={{ width: '240px' }}
                        placeholder='Why this date is excluded ?'
                        ref={register}
                        className={`margin-left-16 ${errors.eventExceptionDateNote && 'border-red'
                          }`}
                      />
                      <button
                        onClick={onExceptionDateSelected}
                        style={{ width: '80px' }}
                        className='btn btn-primary btn-small margin-left-16'
                      >
                        ADD
                      </button>
                    </div>
                    <div
                      className='flex-row'
                      style={{
                        display:
                          eventExceptionDates.length > 0 ? 'flex' : 'none',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start'
                      }}
                    >
                      {eventExceptionDates.length > 0 && (
                        <p className='margin-top-16 margin-bottom-8 font-16'>
                          <u>Selected Exception Dates</u>
                        </p>
                      )}
                      {eventExceptionDates.map((exception) => (
                        <div
                          className='flex-row align-center'
                          style={{ width: '100%' }}
                        >
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              removeExceptionDate(exception.date);
                            }}
                            style={{
                              visibility:
                                moment().isBefore(exception.date) ||
                                  formAction === FormActions.Create
                                  ? 'visible'
                                  : 'hidden',
                              height: '16px',
                              color: Colors.cobuRed
                            }}
                            className='btn btn-small btn-ghost margin-right-16'
                          >
                            remove
                          </button>
                          <div
                            className='flex-row align-center'
                            style={{ width: '30%' }}
                          >
                            <p>
                              {moment(exception.date)
                                .utc(true)
                                .format('ddd, DD MMM YYYY')}
                            </p>
                          </div>
                          <p
                            className='margin-left-16'
                            style={{ width: '70%' }}
                          >
                            {exception.notes}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {canDisplayCustomDateSelector() && (
              <div
                className={`margin-top-24 ${customDatesEnabled
                  ? classes.borderedSection
                  : classes.unborderedSection
                  }`}
              >
                <div className='flex-row align-center'>
                  <Switch
                    checked={customDatesEnabled}
                    onChange={setcustomDatesEnabled}
                    ref={register}
                  />
                  <p className='margin-left-8 font-16'>Select Custom Dates</p>
                  <p className='margin-left-8'>
                    (If you wish to include other dates to this gathering)
                  </p>
                </div>
                {customDatesEnabled && (
                  <div className={`margin-top-16 ${classes.borderedSection}`}>
                    <label className='margin-top-24' htmlFor='time'>
                      <b>Custom Date</b>
                    </label>
                    <div className='flex-row align-center'>
                      <input
                        type='date'
                        id='customEventDatetime'
                        name='customEventDatetime'
                        min={moment().format('YYYY-MM-DD')}
                        style={{ width: '200' }}
                        ref={register}
                        className={`event-datetime-selector ${errors.customEventDatetime && 'border-red'
                          }`}
                      />
                      <button
                        onClick={onCustomDateSelected}
                        style={{ width: '80px' }}
                        className='btn btn-primary btn-small margin-left-16'
                      >
                        ADD
                      </button>
                    </div>
                    <div
                      className='flex'
                      style={{
                        display: customEventDates.length > 0 ? 'flex' : 'none',
                        flexWrap: 'wrap'
                      }}
                    >
                      {customEventDates.length != 0 && (
                        <p className='margin-top-16'>
                          <u>Selected Custom Dates</u>
                        </p>
                      )}
                      <div
                        style={{ flexWrap: 'wrap' }}
                        className='flex-row align-center justify-start margin-top-8'
                      >
                        {customEventDates.map((date) => (
                          <div
                            className='flex-row align-center justify-start margin-right-24'
                            style={{ width: '100%' }}
                          >
                            {(moment().isBefore(date) ||
                              formAction === FormActions.Create) && (
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    removeCustomDate(date);
                                  }}
                                  style={{
                                    height: '18px',
                                    color: Colors.cobuRed
                                  }}
                                  className='btn btn-small btn-ghost margin-right-16'
                                >
                                  remove
                                </button>
                              )}
                            <p style={{ width: '116px' }}>
                              {moment(date).format('ddd, DD MMM YYYY')}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={classes.item}>
            <label className='margin-top-16 font-16' htmlFor='group'>
              Choose Group *
            </label>

            <select
              disabled={formAction === FormActions.Edit}
              name='group'
              ref={register({
                required: 'This field is required'
              })}
              className={`${errors.group && 'border-red'} height-50`}
            >
              {groups.map((option: GroupType) => {
                return (
                  <option
                    value={option.uuid}
                    key={option.uuid}
                    selected={
                      formAction === FormActions.Edit &&
                      option.uuid === gathering?.groupId
                    }
                  >
                    {option.name}
                  </option>
                );
              })}
            </select>

            <label className='margin-top-16 font-16' htmlFor='group'>
              Choose Event Type
            </label>

            <select
              // disabled={formAction === FormActions.Edit}
              name='eventType'
              ref={register({
                required: 'This field is required'
              })}
              className={`${errors.eventType && 'border-red'} height-50`}
            >
              {eventTypes.map((option: EventType) => {
                return (
                  <option
                    value={option.uuid}
                    key={option.uuid}
                    selected={
                      formAction === FormActions.Edit &&
                      option.uuid === gathering?.eventType
                    }
                  >
                    {option.name}
                  </option>
                );
              })}
            </select>
            <div className='margin-top-16'>
              <label className='font-16' htmlFor='image'>
                Add a Photo
              </label>
              <div className='margin-top-8'>
                <ImageDropzone image={image} handleDrop={handleDrop} />
              </div>
            </div>
            <label className='margin-top-16 font-16' htmlFor='locationString'>
              Add Location *
            </label>
            <input
              type='text'
              id='locationString'
              name='locationString'
              placeholder={'E.g. Lobby'}
              ref={register({
                required: 'This field is required'
              })}
              className={`${errors.locationString && 'border-red'}`}
            />
            <span className={`color-warning ${classes.span}`}>
              {errors.locationString?.message}
            </span>



            <label className='margin-top-16 font-16' htmlFor='commitType'>
              Commitment Type
            </label>

            <select

              name='commitType'
              ref={register({
                required: 'This field is required'
              })}
              className={`${errors.commitType && 'border-red'} height-50`}
            >
              {commitTypes.map((cType) => {
                return (
                  <option
                    value={cType.id}
                    key={cType.id}
                    selected={
                      formAction === FormActions.Edit &&
                      cType.id === gathering?.commitType
                    }
                  >
                    {cType.name}
                  </option>
                );
              })}
            </select>



            {(watchCommitID !== interestedID) && (
              <div className={`${classes.formHeader} ${classes.attendees}`}>
                <div className={`${classes.flexColumn} margin-right-24`}>
                  <label
                    className='margin-top-16 font-16'
                    htmlFor='maxEventAttendees'
                  >
                    Total Attendees *
                  </label>
                  <input
                    type='text'
                    id='maxEventAttendees'
                    name='maxEventAttendees'
                    ref={register({
                      required: 'This field is required'
                    })}
                    className={`${errors.maxEventAttendees && 'border-red'}`}
                  />
                </div>
                <div className={classes.flexColumn}>
                  <label
                    className='margin-top-16 font-16'
                    htmlFor='maxGuests'
                  >
                    Guests allowed per resident *
                  </label>
                  <input
                    type='text'
                    id='maxGuests'
                    name='maxGuests'
                    ref={register({
                      required: 'This field is required'
                    })}
                    className={`${errors.maxGuests && 'border-red'}`}
                  />
                </div>
              </div>
            )}




            {watchCommitID == registerID && (
              <>
                <label className='margin-top-16 font-16' htmlFor='registrationURL'>
                  Registration URL - <em>use if your residents need to purchase a ticket or register with a third party.</em>
                </label>
                <input
                  type='text'
                  id='registrationURL'
                  name='registrationURL'
                  placeholder={'E.g. https://url.com/register'}
                  className={`${errors.registrationURL && 'border-red'}`}
                  ref={register({
                    required: 'This field is required',
                    validate: (value) => {
                      let resp = value.match(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i);
                      if (resp) {
                        return true;
                      }
                      return false;
                    }
                  })}

                />
              </>

            )}

            <label className='margin-top-16 font-16' htmlFor='whatToBring'>
              What to Bring
            </label>
            <textarea
              name='whatToBring'
              id='whatToBring'
              cols={30}
              rows={3}
              ref={register}
              placeholder='E.g. Also, bring joy and good will. '
            ></textarea>

            {buildingDashboardConfigData?.data.config
              .showGatheringEventReminder && (
                <div className=''>
                  <div className={`${classes.flexColumn} `}>
                    <label
                      className='margin-top-16 font-16'
                      htmlFor='notificationDays'
                    >
                      Send Event Reminder Email
                    </label>
                    <div className={`${classes.radioInpuContainer}`}>
                      <div style={{ display: 'flex' }}>
                        <div className='RadioButtons_radioButtonContainer__GZEFU'>
                          <input
                            name='notificationDuration'
                            id='reminder_1_day'
                            type='checkbox'
                            value='1'
                            className='height-20 cobu-checkbox'
                            ref={register}
                            defaultChecked={
                              gathering?.reminder_1_day ? true : false
                            }
                          />
                          <label
                            className='RadioButtons_radioButtonLabel__1GACa'
                            htmlFor='reminder_1_day'
                          >
                            1 days
                          </label>
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div className='RadioButtons_radioButtonContainer__GZEFU'>
                          <input
                            name='notificationDuration'
                            id='reminder_3_day'
                            type='checkbox'
                            value='3'
                            className='height-20 cobu-checkbox'
                            ref={register}
                            defaultChecked={
                              gathering?.reminder_3_day ? true : false
                            }
                          />
                          <label
                            className='RadioButtons_radioButtonLabel__1GACa'
                            htmlFor='reminder_3_day'
                          >
                            3 days
                          </label>
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div className='RadioButtons_radioButtonContainer__GZEFU'>
                          <input
                            name='notificationDuration'
                            id='reminder_5_day'
                            type='checkbox'
                            value='5'
                            className='height-20 cobu-checkbox'
                            ref={register}
                            defaultChecked={
                              gathering?.sendEventReminder ? true : false
                            }
                          />
                          <label
                            className='RadioButtons_radioButtonLabel__1GACa'
                            htmlFor='reminder_5_day'
                          >
                            5 days
                          </label>
                        </div>
                      </div>
                    </div>
                    <em>
                      If selected, email reminders are sent to residents, 1/3/5
                      days before the event unless the event is full.
                    </em>
                  </div>
                </div>
              )}

            {/*
                {buildingDashboardConfigData?.data.config
                .showGatheringEventReminder && (
                <div>
                  <div
                    className={`margin-top-16 ${classes.flexRow} margin-bottom-4`}
                  >
                    <Controller
                      name='reminder_1_day'
                      control={control}
                      render={({ ref, onChange, value, ...props }) => {
                        return (
                          <Switch
                            checked={value}
                            onChange={(currentSwitchState: boolean) => {
                              onChange(currentSwitchState);
                            }}
                          />
                        );
                      }}
                    />
                    <div className='margin-left-16'>
                      <p className='margin-top-8 font-18'>
                        Send event reminder email before event
                      </p>
                    </div>
                  </div>
                  <span className='font-16'>
                    Email reminders are sent to residents 1 day before the
                    event unless the event is full
                  </span>
                </div>
              )}
                    */}

            {buildingDashboardConfigData?.data.config.allowFlyerRequest &&
              (formAction === FormActions.Create ||
                formAction === FormActions.Duplicate) && (
                <div>
                  <div
                    className={`margin-top-16 ${classes.flexRow} margin-bottom-4`}
                  >
                    <Controller
                      name='flyerRequest'
                      control={control}
                      render={({ ref, onChange, value, ...props }) => {
                        return (
                          <Switch
                            checked={value}
                            onChange={(currentSwitchState: boolean) => {
                              onChange(currentSwitchState);
                            }}
                            ref={register}
                          />
                        );
                      }}
                    />
                    <div className='margin-left-16'>
                      <p className='margin-top-8 font-18'>Send event flyer</p>
                    </div>
                  </div>
                  <span className={`font-16 margin-top-4 ${classes.span}`}>
                    Event flyer in PDF format will be sent to the email address
                    on file within two business days (Monday - Friday)
                  </span>
                </div>
              )}
            {isVcmOrSuperAdmin && (
              <div
                className={`margin-top-16 ${classes.flexRow} margin-bottom-4`}
              >
                <Controller
                  name='reqAutoReminder'
                  control={control}
                  render={({ onChange, value }) => {

                    return (
                      <Switch
                        checked={value}
                        onChange={(currentSwitchState: boolean) => {
                          // onChange(currentSwitchState);
                        }}
                        ref={register}
                      />
                    );
                  }}
                />
                <div className='margin-left-16'>
                  <p className='margin-top-8 font-18'>
                    Send Event Reminder Post, 3 Days Prior to event
                  </p>
                </div>
              </div>
            )}
            {(formAction === FormActions.Create ||
              formAction === FormActions.Duplicate) && (
                <div className={`${classes.flexColumn} margin-top-16`}>
                  <label htmlFor='proposedBy'>Creator's Email *</label>
                  <div className='margin-top-8'>
                    <Controller
                      name='proposedBy'
                      control={control}
                      rules={{ required: 'Creator is required' }}
                      render={({ ref, onChange, ...props }) => (
                        <CobuSelect
                          options={buildingUserOptions}
                          {...props}
                          onChange={(
                            selectedOption: SelectedOptionType | null
                          ) => {
                            onChange(selectedOption?.value);
                          }}
                          selectType={SelectType.Form}
                          isError={errors.proposedBy !== undefined}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default GatheringForm;
