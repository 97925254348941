import CobuConstants from '~/helpers/cobuConstants';
import { IMultiSelectOptionList } from '~/helpers/interfaces';
import { FormInputConfig } from '~/types/residentManagement';

export const onBoardingOptions: IMultiSelectOptionList[] = [
  {
    value: 'includeRaffle',
    text: CobuConstants.createBuilding.includeOnboardingRaffle,
    containerStyle: {
      padding: '10px',
      border: '1px grey dashed',
      borderRadius: '10px',
      backgroundColor: '#F8F8FA'
    },
    textStyle: {
      fontSize: '18px',
      color: '#272163'
    }
  },
  {
    value: 'onBoardingSkippable',
    text: CobuConstants.createBuilding.enableSkipDuringOnboarding,
    isSelected: true,
    textStyle: {
      fontSize: '18px',
      color: '#272163'
    }
  }
];

export const editOnBoardingOptions: IMultiSelectOptionList[] = [
  {
    value: 'skippable',
    text: CobuConstants.createBuilding.enableSkipDuringOnboarding
  }
];

export enum SectionId {
  Information = 'information',
  Images = 'images',
  Reviews = 'reviews'
}
