import { useState } from 'react';
import {
  IBuildingOnBoarding,
  patchEditBuilding,
  postCreateBuilding,
  postPropertyOnBoarding
} from '~/api/building';
import {
  editOnBoardingOptions,
  onBoardingOptions
} from '~/components/CreateEditBuildingForm/constants';
import { IMultiSelectOptionList } from '~/helpers/interfaces';
import { Building } from '~/types/building';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';

const getBoardingOptions = (onBoardingOptions: IMultiSelectOptionList[]) => {
  onBoardingOptions[0].showSibling = false;

  return onBoardingOptions;
};

export default (buildingId: string) => {
  const getEditBoardingOptions = (
    onBoardingOptions: IMultiSelectOptionList[]
  ) => {
    onBoardingOptions[0].isSelected = false;
    return onBoardingOptions;
  };

  const [boardingOptions, setBoardingOptions] = useState(() =>
    getBoardingOptions(cloneDeep(onBoardingOptions))
  );

  const [editBoardingOptions, setEditBoardingOptions] = useState(() =>
    getEditBoardingOptions(cloneDeep(editOnBoardingOptions))
  );

  const history = useHistory();
  const [loader, showLoader] = useState(false);

  const postBuildingOnBoarding = async (
    onBoardingData: IBuildingOnBoarding
  ) => {
    showLoader(true);

    const formData = new FormData();

    Object.entries(onBoardingData).forEach(([key, value]) => {
      const type = typeof value;

      console.log(onBoardingData, 'onBoardingData@@');

      switch (type) {
        case 'string':
          formData.append(key, value as string);
          break;
        case 'boolean':
          formData.append(key, value as string);
          break;
      }
    });

    formData.append('buildingUuid', buildingId);

    try {
      const { data: building } = await postPropertyOnBoarding(formData);

      toast(`Building '${building.name}' updated successfully`);

      showLoader(false);

      history.goBack();
    } catch (e) {
      showLoader(false);
      toast(e.message || 'Failed to update building. Please try again');
    }
  };

  return {
    loader,
    postBuildingOnBoarding
  };
};
