import React, { lazy } from 'react';
import { CustomRoute } from '~/types/customRoute';
import {
  renderProtectedDashboardComponent,
  renderProtectedRSVPComponent,
  renderPublicComponent
} from './renderComponents';
import BuildingsListContainer from '~/containers/BuildingsListContainer/BuildingsListContainer';
//import BuildingsListViewContainer from '~/containers/BuildingsListContainer/BuildingsListViewContainer';
import ForgottenPasswordContainer from '~/containers/ForgottenPasswordContainer/ForgottenPasswordContainer';
import GatheringFormContainer from '~/containers/GatheringFormContainer/GatheringFormContainer';
import GatheringRSVPs from '~/containers/GatheringRSVPs/GatheringRSVPs';
import Gatherings from '~/containers/Gatherings/Gatherings';
import Comments from '~/containers/Comments/Comments';
import Groups from '~/containers/Groups/Groups';
import LoginContainer from '~/containers/LoginContainer/LoginContainer';
import RedirectComponent from '~/containers/RedirectComponent';
import RegisterPhoneContainer from '~/containers/RegisterPhoneContainer/RegisterPhoneContainer';
import RegisterSuccessContainer from '~/containers/RegisterSuccessContainer/RegisterSuccessContainer';
import RegisterVerificationContainer from '~/containers/RegisterVerificationContainer/RegisterVerificationContainer';
import ResetPasswordContainer from '~/containers/ResetPasswordContainer/ResetPasswordContainer';
import RSVPGatheringContainer from '~/containers/RSVPGatheringContainer/RSVPGatheringContainer';
import RSVPHomePageContainer from '~/containers/RSVPHomePageContainer/RSVPHomePageContainer';
import RSVPRegisterContainer from '~/containers/RSVPRegisterContainer/RSVPRegisterContainer';
import PerksContainer from '~/containers/PerksContainer/PerksContainer';
import PerkFormContainer from '~/containers/PerkFormContainer/PerkFormContainer';
import GatheringFeedback from '~/containers/GatheringFeedback/GatheringFeedback';
import Dashboard from '~/containers/Dashboard/Dashboard';
import VendorFormContainer from '~/containers/VendorFormContainer/VendorFormContainer';
import EditBuildingContainer from '~/containers/EditBuildingContainer/EditBuildingContainer';
import DeactivateBuildingContainer from '~/containers/DeactivateBuildingContainer/DeactivateBuildingContainer';
import Admins from '~/containers/Admins/Admins';
import Reviews from '~/containers/Reviews/Reviews';
import SentimentComments from '~/components/SentimentComments';
import GatheringTemplateForm from '~/containers/GatheringTemplateForm/GatheringTemplateForm';
import PostManagement from '~/containers/PostManagement/PostManagement';
import ResidentManagement from '~/containers/ResidentManagement/ResidentManagement';
import ResidentProfile from '~/containers/ResidentProfile/ResidentProfile';
import PinnedPostManagement from '~/containers/PinnedPostManagement/PinnedPostManagement';
import ActiveSurvey from '~/containers/ActiveSurveys/ActiveSurvey';
import GoogleReviewSummary from '~/containers/GoogleReviewManagement/GoogleReviewSummary';
import GoogleReviewManagement from '~/containers/GoogleReviewManagement/GoogleReviewList';
import GoogleReviewDetails from '~/containers/GoogleReviewManagement/GoogleReviewDetails';
import ContentModerationContainer from '~/containers/ContentModerationContainer';
import ReviewGroupCreationRequests from '~/containers/GroupsContainer/GroupsContainer';
import { AssetGoogleReview } from '~/containers/AssetGoogleReview/AssetGoogleReview';
import { PropertyAssignment } from '~/containers/PropertyAssignment/PropertyAssignment';
import { OwnerManagement } from '~/containers/OwnerManagement/OwnerManagement';
import UploadRentRoll from '~/containers/RentRoll';
import TenantMatching from '~/containers/TenantMatching';
import PmsIntegrations from '~/containers/PmsIntegrations';
import { FlyerManagement } from '~/containers/FlyerManagement/FlyerManagement';
import EventManagement from '~/containers/EventManagement/EventManagement';
import Reports from '~/containers/Reports/Reports';
import { ReferralForm } from '~/containers/Referral/DefaultReferralPage';
import { ReferralLeads } from '~/containers/Referral/ReferralLeads';
import { ReferralSetting } from '~/containers/Referral/ReferralSetting';
import { CreateEditReferralForm } from '~/containers/Referral/CreateEditReferralForm/CreateEditReferralForm';
import { EditBuildingEmails } from '~/containers/EditBuildingEmails/EditBuildingEmails';
import ActiveTooltips from '~/containers/ActiveSurveys/ActiveTooltips';
import Deeplinks from '~/containers/Deeplinks/Deeplinks';
import AddEntrataConfig from '~/containers/AddEntrataConfig/AddEntrataConfig';
import { EditBuildingAddress } from '~/containers/EditBuildingAddress/EditBuildingAddress';
import YardiCredentials from '~/containers/YardiCredentials';
import { EnableHelloData } from '~/containers/EnableHelloData/EnableHelloData';
import ActiveQueues from '~/containers/Queue/ActiveQueues';
import JobStatus from '~/containers/Queue/JobStatus';
import AddYardiConfig from '~/containers/AddYardiConfig/AddYardiConfig';
import { MyBuildingTiles } from '~/containers/MyBuildingTiles';
import EditBuildingImages from '~/containers/EditBuildingImages/EditBuildingImages';
import ScheduledComments from '~/containers/ScheduledComments/ScheduledComments';
import Audits from '~/containers/AuditContainer/Audits';
import ConfigAudits from '~/containers/AuditContainer/BuildingConfig/ConfigAudits';
import LeadAudits from '~/containers/AuditContainer/Leads/LeadAudits';
import { CreateEditQuestionnaire } from '~/containers/Questionnair/CreateEditQuestionnaire';
import { QuestionnaireList } from '~/containers/Questionnair/QuestionnaireList';
import { UGC } from '~/containers/UserGeneratedContent';
import { QuestionnaireResult } from '~/containers/Questionnair/QuestionnaireResult';
import CreateBuildingScreen from '~/containers/CreateBuilding';
import BuildingOnboarding from '~/containers/BuildingOnBoarding';

const BuildingKeys = lazy(() =>
  import(
    /* webpackChunkName: "BuildingKeys" */ '~/containers/BuildingKeys'
  ).then((module) => ({
    default: module.BuildingKeys
  }))
);

const EventReminder = lazy(() =>
  import(
    /* webpackChunkName: "EventReminder" */ '~/containers/EventReminder'
  ).then((module) => ({
    default: module.EventReminder
  }))
);

const Questionnair = lazy(() =>
  import(
    /* webpackChunkName: "EventReminder" */ '~/containers/Questionnair'
  ).then((module) => ({
    default: module.Questionnair
  }))
);

const ManageVisibleContent = lazy(() =>
  import(
    /* webpackChunkName: "ManageVisibleContent" */ '~/containers/UserGeneratedContent/ManageVisibleContent'
  ).then((module) => ({
    default: module.ManageVisibleContent
  }))
);

const routes: CustomRoute[] = [
  //public routes
  {
    path: '/',
    breadcrumb: null,
    exact: true,
    render: renderPublicComponent(LoginContainer)
  },
  {
    path: '/forgottenPassword',
    exact: true,
    render: renderPublicComponent(ForgottenPasswordContainer)
  },
  {
    path: '/resetPassword',
    exact: true,
    render: renderPublicComponent(ResetPasswordContainer)
  },
  {
    path: '/login',
    exact: true,
    render: renderPublicComponent(() => <LoginContainer isRSVPLogin={false} />)
  },
  {
    path: '/join/*',
    exact: true,
    render: renderPublicComponent(RedirectComponent)
  },

  {
    path: '/rsvp-register/phone',
    exact: true,

    render: renderPublicComponent(RegisterPhoneContainer)
  },
  {
    path: '/rsvp-register/verification',
    exact: true,
    render: renderPublicComponent(RegisterVerificationContainer)
  },

  {
    path: '/rsvp/:gatheringId',
    exact: true,
    render: renderPublicComponent(RSVPHomePageContainer)
  },

  {
    path: '/rsvp-login/:gatheringId',
    exact: true,
    render: renderPublicComponent(() => <LoginContainer isRSVPLogin={true} />)
  },
  {
    path: '/rsvp-register/success',
    exact: true,
    render: renderPublicComponent(RegisterSuccessContainer)
  },
  {
    path: '/rsvp-register/:gatheringId',
    exact: true,
    render: renderPublicComponent(RSVPRegisterContainer)
  },
  {
    path: '/proposal/:proposalId',
    exact: true,
    render: renderPublicComponent(VendorFormContainer)
  },
  {
    path: '/r/:referralCode',
    exact: true,
    breadcrumb: 'Referral Form',
    render: renderPublicComponent(ReferralForm)
  },
  {
    path: '/entrata-config/:buildingId',
    exact: true,
    render: renderPublicComponent(AddEntrataConfig)
  },
  {
    path: '/yardi-config/:buildingId',
    exact: true,
    render: renderPublicComponent(AddYardiConfig)
  },
  //protected dashboard routes
  {
    path: '/building',
    breadcrumb: 'Buildings',
    exact: true,
    render: renderProtectedDashboardComponent(BuildingsListContainer)
  },
  {
    path: '/building/create',
    breadcrumb: 'Create Building',
    exact: true,
    render: renderProtectedDashboardComponent(CreateBuildingScreen)
  },
  {
    path: '/building/:buildingId/on-boarding',
    breadcrumb: 'Onboard Building',
    exact: true,
    render: renderProtectedDashboardComponent(BuildingOnboarding)
  },
  //protected dashboard routes
  {
    path: '/building/admins',
    breadcrumb: 'User management',
    exact: true,
    render: renderProtectedDashboardComponent(Admins)
  },
  {
    path: '/building/pinned-post-management/post-management',
    breadcrumb: 'Post management',
    exact: true,
    render: renderProtectedDashboardComponent(PostManagement)
  },
  {
    path: '/building/event-management',
    breadcrumb: 'Event management',
    exact: true,
    render: renderProtectedDashboardComponent(EventManagement)
  },
  {
    path: '/building/pinned-post-management',
    breadcrumb: 'Pinned Post management',
    exact: true,
    render: renderProtectedDashboardComponent(PinnedPostManagement)
  },
  {
    path: '/building/surveys',
    breadcrumb: 'Active surveys',
    exact: true,
    render: renderProtectedDashboardComponent(ActiveSurvey)
  },
  {
    path: '/building/tooltips',
    breadcrumb: 'Active Tooltips',
    exact: true,
    render: renderProtectedDashboardComponent(ActiveTooltips)
  },
  {
    path: '/building/audits',
    breadcrumb: 'Audits',
    exact: true,
    render: renderProtectedDashboardComponent(Audits)
  },
  {
    path: '/building/audits/config',
    breadcrumb: 'Building Configurations',
    exact: true,
    render: renderProtectedDashboardComponent(ConfigAudits)
  },
  {
    path: '/building/audits/leads',
    breadcrumb: 'Cobu Leads',
    exact: true,
    render: renderProtectedDashboardComponent(LeadAudits)
  },
  {
    path: '/building/:buildingId/deeplinks',
    breadcrumb: 'Deeplinks',
    exact: true,
    render: renderProtectedDashboardComponent(Deeplinks)
  },
  {
    path: '/building/google-review-summary',
    breadcrumb: 'Google Review Summary',
    exact: true,
    render: renderProtectedDashboardComponent(GoogleReviewSummary)
  },
  {
    path: '/building/google-review-summary/:buildingId',
    breadcrumb: 'Google Review Management',
    exact: true,
    render: renderProtectedDashboardComponent(GoogleReviewManagement)
  },
  {
    path: '/building/google-review-summary/:buildingId/:reviewId',
    breadcrumb: 'Google Review Details',
    exact: true,
    render: renderProtectedDashboardComponent(GoogleReviewDetails)
  },
  //protected asset routes
  {
    path: '/building/assetOverview',
    breadcrumb: 'Group Google Review',
    exact: true,
    render: renderProtectedDashboardComponent(AssetGoogleReview)
  },
  {
    path: '/building/properyAssignment',
    breadcrumb: 'Property Assignment',
    exact: true,
    render: renderProtectedDashboardComponent(PropertyAssignment)
  },
  {
    path: '/building/ownerManagement',
    breadcrumb: 'Owner Management',
    exact: true,
    render: renderProtectedDashboardComponent(OwnerManagement)
  },
  {
    path: '/building/flyerManagement',
    breadcrumb: 'Flyers Management',
    exact: true,
    render: renderProtectedDashboardComponent(FlyerManagement)
  },
  {
    path: '/building/buildingKeys',
    breadcrumb: 'Building Keys',
    exact: true,
    render: renderProtectedDashboardComponent(BuildingKeys)
  },
  {
    path: '/building/eventReminder',
    breadcrumb: 'Event Reminder',
    exact: true,
    render: renderProtectedDashboardComponent(EventReminder)
  },
  {
    path: '/building/queue',
    breadcrumb: 'Queue Management',
    exact: true,
    render: renderProtectedDashboardComponent(ActiveQueues)
  },
  {
    path: '/building/queue/:queueId',
    breadcrumb: 'Job Management',
    exact: true,
    render: renderProtectedDashboardComponent(JobStatus)
  },
  {
    path: '/building/:buildingId',
    breadcrumb: 'Dashboard',
    exact: true,
    render: renderProtectedDashboardComponent(Dashboard)
  },
  {
    path: '/building/:buildingId/edit',
    breadcrumb: 'Building Settings',
    exact: true,
    render: renderProtectedDashboardComponent(EditBuildingContainer)
  },
  {
    path: '/building/:buildingId/edit/emails',
    breadcrumb: 'Edit Building Emails',
    exact: true,
    render: renderProtectedDashboardComponent(EditBuildingEmails)
  },
  {
    path: '/building/:buildingId/edit/address',
    breadcrumb: 'Edit Building Address',
    exact: true,
    render: renderProtectedDashboardComponent(EditBuildingAddress)
  },
  {
    path: '/building/:buildingId/edit/images',
    breadcrumb: 'Edit Building Images',
    exact: true,
    render: renderProtectedDashboardComponent(EditBuildingImages)
  },
  {
    path: '/building/:buildingId/pms-integration',
    breadcrumb: 'PMS Integration',
    exact: true,
    render: renderProtectedDashboardComponent(PmsIntegrations)
  },
  {
    path: '/building/:buildingId/perks',
    breadcrumb: 'Neighborhood Offers',
    exact: true,
    render: renderProtectedDashboardComponent(PerksContainer)
  },
  {
    path: '/building/:buildingId/perks/:perkId/edit',
    breadcrumb: 'Edit Offer',
    exact: true,
    render: renderProtectedDashboardComponent(PerkFormContainer)
  },
  {
    path: '/building/:buildingId/residents',
    breadcrumb: 'Resident Management',
    exact: true,
    render: renderProtectedDashboardComponent(ResidentManagement)
  },
  {
    path: '/building/:buildingId/residents/:residentId/profile',
    breadcrumb: 'Resident Profile',
    exact: true,
    render: renderProtectedDashboardComponent(ResidentProfile)
  },
  {
    path: '/building/:buildingId/comments',
    breadcrumb: 'Comments',
    exact: true,
    render: renderProtectedDashboardComponent(Comments)
  },
  {
    path: '/building/:buildingId/scheduledcomments',
    breadcrumb: 'Comments',
    exact: true,
    render: renderProtectedDashboardComponent(ScheduledComments)
  },
  {
    path: '/building/:buildingId/sentiment/negative',
    breadcrumb: 'Negative comments',
    exact: true,
    render: renderProtectedDashboardComponent(SentimentComments)
  },
  {
    path: '/building/:buildingId/sentiment/positive',
    breadcrumb: 'Positive comments',
    exact: true,
    render: renderProtectedDashboardComponent(SentimentComments)
  },
  {
    path: '/building/:buildingId/sentiment/neutral',
    breadcrumb: 'Neutral comments',
    exact: true,
    render: renderProtectedDashboardComponent(SentimentComments)
  },
  {
    path: '/building/:buildingId/groups',
    breadcrumb: 'Groups',
    exact: true,
    render: renderProtectedDashboardComponent(Groups)
  },
  {
    path: '/building/:building/deactivate',
    breadcrumb: 'Deactivate a Building',
    exact: true,
    render: renderProtectedDashboardComponent(DeactivateBuildingContainer)
  },
  {
    path: '/building/:buildingId/referralLeads',
    breadcrumb: 'Referral Leads',
    exact: true,
    render: renderProtectedDashboardComponent(ReferralLeads)
  },
  {
    path: '/building/:buildingId/referralSetting',
    exact: true,
    breadcrumb: 'Community Lead Setting',
    render: renderProtectedDashboardComponent(ReferralSetting)
  },
  {
    path: '/building/:buildingId/createReferral',
    exact: true,
    breadcrumb: 'Create Referral',
    render: renderProtectedDashboardComponent(CreateEditReferralForm)
  },
  {
    path: '/building/:buildingId/helloData',
    breadcrumb: 'Hello Data',
    exact: true,
    render: renderProtectedDashboardComponent(EnableHelloData)
  },
  {
    path: '/building/:buildingId/myBuildingTiles',
    breadcrumb: 'My Building Tiles',
    exact: true,
    render: renderProtectedDashboardComponent(MyBuildingTiles)
  },
  {
    path: '/building/:buildingId/questionnaires',
    breadcrumb: 'Survey List',
    exact: true,
    render: renderProtectedDashboardComponent(QuestionnaireList)
  },
  {
    path: '/building/:buildingId/questionnaires/create-edit',
    breadcrumb: 'Create Edit Survey',
    exact: true,
    render: renderProtectedDashboardComponent(CreateEditQuestionnaire)
  },
  {
    path: '/building/:buildingId/questionnaires/result',
    breadcrumb: 'Result',
    exact: true,
    render: renderProtectedDashboardComponent(QuestionnaireResult)
  },
  {
    path: '/building/:buildingId/UGC',
    breadcrumb: 'User Generated Content',
    exact: true,
    render: renderProtectedDashboardComponent(UGC)
  },
  {
    path: '/building/:buildingId/UGC/:ugcSettingId',
    breadcrumb: 'UGC Visible Content',
    exact: true,
    render: renderProtectedDashboardComponent(ManageVisibleContent)
  },
  {
    path: '/building/:buildingId/gathering',
    breadcrumb: 'Gatherings',
    exact: true,
    render: renderProtectedDashboardComponent(Gatherings)
  },
  {
    path: '/building/:buildingId/reviews',
    breadcrumb: 'Building Reviews',
    exact: true,
    render: renderProtectedDashboardComponent(Reviews)
  },
  {
    path: '/building/:buildingId/reports',
    breadcrumb: 'Building Reports',
    exact: true,
    render: renderProtectedDashboardComponent(Reports)
  },
  {
    path: '/building/:buildingId/:gatheringId',
    breadcrumb: 'Gatherings',
    exact: true,
    render: renderProtectedDashboardComponent(Gatherings)
  },
  {
    path: '/building/:buildingId/gathering/create',
    breadcrumb: 'New Gathering',
    exact: true,
    render: renderProtectedDashboardComponent(GatheringFormContainer)
  },
  {
    path: '/building/:buildingId/gathering/:gatheringId/edit',
    breadcrumb: 'Edit Gathering',
    exact: true,
    render: renderProtectedDashboardComponent(GatheringFormContainer)
  },
  {
    path: '/building/:buildingId/gathering/:gatheringId/duplicate',
    breadcrumb: 'Duplicate Gathering',
    exact: true,
    render: renderProtectedDashboardComponent(GatheringFormContainer)
  },
  {
    path: '/building/:buildingId/gathering-template/create',
    breadcrumb: 'Create Gathering Template',
    exact: true,
    render: renderProtectedDashboardComponent(GatheringTemplateForm)
  },
  {
    path: '/building/:buildingId/gathering-template/:templateId/edit',
    breadcrumb: 'Edit Gathering Template',
    exact: true,
    render: renderProtectedDashboardComponent(GatheringTemplateForm)
  },
  {
    path: '/building/:buildingId/:gatheringId/rsvp',
    breadcrumb: 'RSVP',
    exact: true,
    render: renderProtectedDashboardComponent(GatheringRSVPs)
  },
  {
    path: '/building/:buildingId/:gatheringId/feedback',
    breadcrumb: 'Event Feedback',
    exact: true,
    render: renderProtectedDashboardComponent(GatheringFeedback)
  },
  {
    path: '/moderation/user-content',
    breadcrumb: 'Moderation / User Content',
    exact: true,
    render: renderProtectedDashboardComponent(ContentModerationContainer)
  },
  {
    path: '/pms-integrations',
    breadcrumb: 'PMS Integration',
    exact: true,
    render: renderProtectedDashboardComponent(PmsIntegrations)
  },
  {
    path: '/pms-integrations/yardi-credentials',
    breadcrumb: 'Yardi Credentials',
    exact: true,
    render: renderProtectedDashboardComponent(YardiCredentials)
  },
  {
    path: '/tenant-management/tenant-matching',
    breadcrumb: 'Tenant Matching',
    exact: true,
    render: renderProtectedDashboardComponent(TenantMatching)
  },

  //protected RSVP routes
  {
    path: '/rsvp/gathering/:gatheringId',
    exact: true,
    render: renderProtectedRSVPComponent(RSVPGatheringContainer)
  },
  {
    path: '/groups-requests',
    exact: true,
    render: renderProtectedRSVPComponent(ReviewGroupCreationRequests)
  },
  {
    path: '/building/:buildingId/rentroll/upload',
    breadcrumb: 'Rent Roll / Upload',
    exact: true,
    render: renderProtectedDashboardComponent(UploadRentRoll)
  },
  {
    path: '/q/:questionnaireShortCode/:userShortCode',
    exact: true,
    render: renderPublicComponent(Questionnair)
  }
  // {
  //   path: '/building/:buildingId/cobu-questionnaires',
  //   breadcrumb: 'Survey List',
  //   exact: true,
  //   render: renderPublicComponent(QuestionnaireList)
  // }
];
export default routes;
