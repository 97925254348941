import { FormInputConfig } from '~/types/residentManagement';

export type QuestionnairComponentTypes =
  | 'default'
  | 'questions'
  | 'appStore'
  | 'completed'
  | 'thankYou'
  | 'error';

export const defaultRatings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const questionFormConfig: FormInputConfig[] = [
  {
    id: 'answer',
    label: '',
    inputType: 'textarea',
    inputTypeProps: { cols: 80, rows: 10 },
    styles: {
      inputStyles: {
        borderRadius: '26px',
        borderColor: 'rgba(221, 221, 221, 1)',
        borderWidth: '1px',
        padding: '1rem'
      }
    },
    isRequired: true
  },
  {
    id: 'uuid',
    label: '',
    displayNone: true,
    isRequired: false,
    inputType: 'text'
  }
];

export enum QuestionsTypes {
  NPS = 'NPS',
  OTHERS = 'Others',
  CCI = 'CCI'
}

export enum QuestionSubType {
  MEMBERSHIP = 'MEMBERSHIP',
  CONNECTION = 'CONNECTION',
  NEEDS = 'NEEDS',
  INFLUENCE = 'INFLUENCE',
  OVERALL_SATISFACTION = 'OVERALL SATISFACTION',
  COMMUNITY_IMPORTANCE = 'COMMUNITY IMPORTANCE'
}

export enum IntroParagraphMarkups {
  buildingName = '{{buildingName}}'
}

export const sortByOrder = (
  obj: Record<string, { value: string; label?: string; order: number }>
) => {
  return Object.fromEntries(
    Object.entries(obj).sort(([, a], [, b]) => a.order - b.order)
  );
};
