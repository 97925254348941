import { AxiosResponse } from 'axios';
import { emperor } from '~/services';

export interface IUGCDefault {
  chat_on: boolean;
  marketplace_on: boolean;
  events_on: boolean;
  groups_on: boolean;
  video_on: boolean;

  chat_past_days: number;
  marketplace_past_days: number;
  events_past_days: number;


  events_future_days: number;

  chat_snippet?: string;
  marketplace_snippet?: string;
  events_snippet?: string;
  groups_snippet?: string;
  videos_snippet?: string;

  blurb_paragraph?: string;
  blurb_label?: string;

  blurb_link?: string;
}

export interface IUGCSetting extends IUGCDefault {
  uuid: string;
  building: string;
  shortcode: string;
  marketplaceGroupId?: string;
}

export interface IUGCVideo {
  createdAt: string;
  updatedAt: string;
  uuid: string;
  video_url: string;
  visible: boolean;
  priority: number;
  building: string;
}

export interface ICreateUGCSetting extends IUGCDefault {}

export const getUGCSetting = async (
  buildingId: string
): Promise<AxiosResponse<IUGCSetting>> => {
  return await emperor.get('/ugc', {
    params: { buildingId }
  });
};

export const putUGCSetting = async (
  ugcSettings: ICreateUGCSetting | IUGCSetting
): Promise<AxiosResponse<IUGCSetting>> => {
  return await emperor.put('/ugc', ugcSettings);
};

export const getUGCVideos = async (
  buildingId: string
): Promise<AxiosResponse<IUGCVideo[]>> => {
  return await emperor.get(`/ugc/videos/${buildingId}`);
};

export const toggleUGCVideoVisibility = async (
  uuid: string
): Promise<AxiosResponse<IUGCVideo>> => {
  return await emperor.post('/ugc/video/visibility', { uuid });
};

export const updateUGCVideoPriorities = async (
  building: string,
  priorities: Record<string, number>
): Promise<AxiosResponse<IUGCVideo[]>> => {
  return await emperor.post('/ugc/video/priorities', { 
    building,
    priorities 
  });
};

export const createUGCVideo = async (
  building: string,
  video_url: string
): Promise<AxiosResponse<IUGCVideo>> => {
  return await emperor.post('/ugc/video', { 
    building,
    video_url 
  });
};

export const patchGroupComment = async (
  buildingId: string,
  commentId: string,
  isPublicFacing: boolean
): Promise<AxiosResponse<IUGCSetting>> => {
  return await emperor.post('/ugc/visible-content', {
    buildingId,
    commentId,
    isPublicFacing
  });
};
