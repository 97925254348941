import React from 'react';
import { toast } from 'react-toastify';
import Spacer from '~/components/Spacer/Spacer';
import { FormInputConfig } from '~/types/residentManagement';

export const mergeFields = {
  vcmFirstName: 'vcmFirstName',
  vcmLastName: 'vcmLastName',
  vcmTitle: 'vcmTitle',
  vcmEmail: 'vcmEmail',
  vcmCalendar: 'vcmCalendar',
  buildingName: 'buildingName',
  month: 'month'
};

export const eventReminderFormConfig: FormInputConfig[] = [
  {
    id: 'uuid',
    label: '',
    isRequired: false,
    displayNone: true
  },
  {
    id: 'subject',
    label: 'SUBJECT',
    isRequired: true,
    styles: {
      contentStyles: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '0 20px'
      },
      inputStyles: { width: '80%' }
    },
    sibling: (
      <div>
        <label className='sub-heading'>
          Subject Merge Fileds: (tap to copy them)
        </label>
        <Spacer height={12} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px 0' }}>
          {[mergeFields.buildingName, mergeFields.month].map((mergeField) => (
            <div
              key={mergeField}
              className='cursor-pointer'
              onClick={() => {
                navigator.clipboard.writeText(`{{${mergeField}}}`);
                toast('Merge field copied!');
              }}
            >{`{{${mergeField}}}`}</div>
          ))}
        </div>
      </div>
    )
  },
  {
    id: 'message',
    inputType: 'richEditor',
    label: 'MESSAGE',
    isRequired: true,
    inputTypeProps: { rows: 20 },
    styles: {
      contentStyles: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '0 20px'
      }
    },
    sibling: (
      <div>
        <label className='sub-heading'>
          Message Merge Fileds: (tap to copy them)
        </label>
        <Spacer height={12} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px 0' }}>
          {Object.values(mergeFields).map((mergeField) => (
            <div
              key={mergeField}
              className='cursor-pointer'
              onClick={() => {
                navigator.clipboard.writeText(`{{${mergeField}}}`);
                toast('Merge field copied!');
              }}
            >{`{{${mergeField}}}`}</div>
          ))}
        </div>
      </div>
    )
  }
];
