import { useEffect, useMemo, useRef, useState } from 'react';
import {
  getAlnProperties,
  getGenericBuildingKeyAttributes,
  getPropertyHelloData,
  postCreateBuilding
} from '~/api/building';
import { Building, IHelloData } from '~/types/building';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { IAlnData } from '~/containers/CreateBuilding/interfaces';
import { ISelectOption } from '~/helpers/interfaces';
import CobuConstants, { CobuFormErrors } from '~/helpers/cobuConstants';
import { isValidBuildingName, uriRegex } from '~/helpers/helpers';
import { timezoneOptions } from '~/components/Header/CreateMenu/constants';
import { FormInputConfig } from '~/types/residentManagement';
import React from 'react';

export default (
  defaultValues: Record<string, any>,
  searchQuery?: string,
  selectedALNProperty?: string,
  reset: (data: Record<string, any>) => void = () => {},
  getValues: () => Record<string, any> = () => ({})
) => {
  const history = useHistory();
  const [loader, showLoader] = useState(false);
  const [alnPropertyOptions, setAlnPropertyOptions] = useState<
    null | ISelectOption[]
  >(null);
  const alnProperties = useRef<null | Record<string, IAlnData>>(null);
  const alnSearchDebounce = useRef<null | NodeJS.Timeout>(null);
  const [propertyHelloData, setPropertyHelloData] = useState<null | IHelloData>(
    null
  );
  const [buildingKeys, setBuildingKeys] = useState<null | Record<
    string,
    string[]
  >>(null);
  const [showNextSteps, setShowNextSteps] = useState(false);
  const createdBuilding = useRef<null | Building>(null);

  const createBuilding = async (property: Building, callback?: () => void) => {
    //@ts-ignore
    const { alnSearchQuery, ...building } = property;

    const buildingFormData = new FormData();

    Object.entries(building).forEach(([key, value]) => {
      const type = typeof value;

      if (value) {
        switch (type) {
          case 'string':
            buildingFormData.append(key, value as string);
            break;
          case 'boolean':
            buildingFormData.append(key, value as string);
            break;
        }
      }
    });

    building.additional_reviewurls &&
      buildingFormData.append(
        'additional_reviewurls',
        JSON.stringify(building.additional_reviewurls)
      );

    building.google_feature_ids &&
      buildingFormData.append(
        'google_feature_ids',
        JSON.stringify(building.google_feature_ids)
      );

    buildingFormData.append('image', building.image);

    building.propertyLogoUrl &&
      buildingFormData.append('buildingLogo', building.propertyLogoUrl);

    showLoader(true);

    try {
      const res = await postCreateBuilding(buildingFormData);
      const {
        data: { record }
      } = res;

      showLoader(false);
      toast(`Building '${building.name}' created successfully`);
      if (callback) {
        callback();
      }

      createdBuilding.current = record;
      setShowNextSteps(true);
    } catch (e) {
      showLoader(false);
      toast(e.message || 'Failed to create building. Please try again');
    }
  };

  const fetchAlnProperties = async () => {
    if (!searchQuery) {
      return;
    }

    try {
      showLoader(true);
      const res = await getAlnProperties(searchQuery);
      const { data } = res;

      let dataWithLabel: ISelectOption[] = [];
      let byIdAlnData: Record<string, IAlnData> = {};

      data.forEach((item: IAlnData) => {
        byIdAlnData[item.aln_id] = item;

        dataWithLabel.push({
          label: item.property_name,
          value: item.aln_id
        });
      });

      alnProperties.current = byIdAlnData;
      setAlnPropertyOptions(dataWithLabel);
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast('ALN Properties not found');
    }
  };

  const fetchALNPropertyHelloData = async () => {
    if (!selectedALNProperty || !alnProperties.current) {
      return;
    }

    const foundProperty: IAlnData = alnProperties.current[selectedALNProperty];

    const { property_name, zip, state } = foundProperty;

    try {
      showLoader(true);

      const { data } = await getPropertyHelloData(property_name, zip, state);
      setPropertyHelloData(data);
      showLoader(false);
    } catch (e) {
      showLoader(false);
    }
  };

  const fetchBuildingKeys = async () => {
    try {
      const { data } = await getGenericBuildingKeyAttributes();

      setBuildingKeys(data);
    } catch (e) {}
  };

  useEffect(() => {
    fetchBuildingKeys();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      if (alnSearchDebounce.current) {
        clearTimeout(alnSearchDebounce.current);
      }

      alnSearchDebounce.current = setTimeout(() => {
        fetchAlnProperties();
      }, 500);
    } else {
      if (alnSearchDebounce.current) {
        clearTimeout(alnSearchDebounce.current);
      }

      alnProperties.current = null;
      setAlnPropertyOptions(null);
    }
  }, [searchQuery]);

  useEffect(() => {
    fetchALNPropertyHelloData();
  }, [selectedALNProperty]);

  const defaultFormData: null | Partial<Building> = useMemo(() => {
    if (selectedALNProperty && alnProperties.current) {
      const foundProperty: IAlnData =
        alnProperties.current[selectedALNProperty];

      const { property_name, pm_software, property_type } = foundProperty;
      const { building_website } = propertyHelloData || {};

      const prevState = getValues();

      return {
        ...defaultValues,
        alnSearchQuery: prevState.alnSearchQuery,
        selectedALNProperty: prevState.selectedALNProperty,
        name: property_name,
        pm_software,
        property_type,
        propertyWebsiteURL: building_website
      };
    }

    return defaultValues;
  }, [selectedALNProperty, propertyHelloData]);

  const informationConfig: FormInputConfig[] = [
    {
      label: CobuConstants.createBuilding.buildingName,
      id: 'name' as keyof Building,
      pattern: isValidBuildingName(),
      patternErrorMsg: CobuFormErrors.namePatternError,
      placeholder: 'Building Name'
    },
    {
      label: CobuConstants.createBuilding.displayShortName,
      id: 'shortName' as keyof Building,
      pattern: isValidBuildingName(),
      patternErrorMsg: CobuFormErrors.namePatternError,
      placeholder: 'Building Short Name'
    },
    {
      label: CobuConstants.createBuilding.timeZone,
      id: 'timezone' as keyof Building,
      inputType: 'select',
      selectOptions: timezoneOptions
    },
    {
      label: CobuConstants.createBuilding.vcm,
      id: 'VCM' as keyof Building,
      readOnly: true,
      isRequired: true,
      placeholder: 'Number of VCM'
    },
    {
      label: CobuConstants.createBuilding.residentPortalLink,
      id: 'residentPortalURL' as keyof Building,
      pattern: uriRegex(),
      isRequired: false,
      patternErrorMsg: CobuFormErrors.uriPatternError,
      placeholder: 'Resident portal link'
    },
    {
      label: CobuConstants.createBuilding.contactInfoUrl,
      id: 'contactInfoURL' as keyof Building,
      pattern: uriRegex(),
      isRequired: false,
      patternErrorMsg: CobuFormErrors.uriPatternError,
      placeholder: 'Contact info link'
    },
    {
      label: CobuConstants.createBuilding.pollsLink,
      id: 'pollsLink' as keyof Building,
      pattern: uriRegex(),
      isRequired: false,
      patternErrorMsg: CobuFormErrors.uriPatternError,
      placeholder: 'Polls link'
    },
    {
      label: CobuConstants.createBuilding.rafflesLink,
      id: 'rafflesLink' as keyof Building,
      pattern: uriRegex(),
      isRequired: false,
      patternErrorMsg: CobuFormErrors.uriPatternError,
      placeholder: 'Raffles link'
    },
    {
      label: 'PMS Software',
      id: 'pm_software' as keyof Building,
      isRequired: false
    },
    {
      label: 'Property Type',
      id: 'property_type' as keyof Building,
      isRequired: false,
      ...(buildingKeys && buildingKeys['Property Type'].length > 0
        ? {
            sibling: (
              <div>{`Values: ${buildingKeys['Property Type'].join(', ')}`}</div>
            )
          }
        : {}),
      styles: { inputStyles: { width: '100%' } }
    },
    {
      label: 'Property Website URL',
      id: 'propertyWebsiteURL' as keyof Building,
      inputType: 'text',
      isRequired: false
    }
  ];

  const gotoOnBoarding = () => {
    if (!createdBuilding.current) {
      return;
    }

    setShowNextSteps(false);

    history.push(`/building/${createdBuilding.current.uuid}/on-boarding`);
  };

  const gotoDashboard = () => {
    if (!createdBuilding.current) {
      return;
    }

    setShowNextSteps(false);

    history.push(`/building/${createdBuilding.current.uuid}`);
  };

  const createNewBuilding = () => {
    createdBuilding.current = null;
    reset(defaultValues);

    setShowNextSteps(false);
  };

  return {
    alnPropertyOptions,
    defaultFormData,
    loader,
    createBuilding,
    informationConfig,
    gotoOnBoarding,
    gotoDashboard,
    createNewBuilding,
    showNextSteps
  };
};
