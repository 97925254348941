import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import ActionDropDown from '~/components/ActionDropDown/ActionDropDown';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import CobuSwitcher from '~/components/CobuSwitcher/CobuSwitcher';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import Spinner from '~/components/Spinner/Spinner';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';
import CobuConstants from '~/helpers/cobuConstants';

import { ResidentUIModel } from '~/types/residentManagement';
import { CellProps } from 'react-table';
import MoreActions from '~/components/MoreActions/MoreActions';
import Spacer from '~/components/Spacer/Spacer';
import GenericModal from '~/components/GenericModal/GenericModal';
import CreateResidentForm from '~/components/CreateResidentForm/CreateResidentForm';
import useResident from '~/hooks/resident/useResident';

import classes from './ResidentManagement.module.css';
import { getCreateUserFormConfig } from '~/helpers/formHelpers';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { Tabs } from '~/components/Tabs';
import { toast } from 'react-toastify';
import { createAdminsRequest } from '~/api/admin';
import { Roles } from '~/enums/Roles';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/store';

const ResidentManagement = () => {
  /* Params */
  const { buildingId } = useParams<{ buildingId: string }>();
  const selectedBuilding = getBuildingFromStore(buildingId);
  const [selectedUserStatusState, setselectedUserStatusState] = useState<
    'ACTIVE' | 'DEACTIVATED' | string
  >('ACTIVE');

  /* Data logics */
  const {
    residentState,
    residentTableConfig,

    createBuildingResident,
    deactivateBuildingResident,
    deactivateMultipleBuildingResident,
    activateBuildingResident,
    activateMultipleBuildingResident,
    welcomeMultipleBuildingResident,
    sendReviewInvitationForResident,
    removeFromReviewOutreach,

    toggleActiveState,
    showHideUserCreateModal,
    isCreatePMUser
  } = useResident(buildingId);
  const { loader } = useFullScreenLoader();

  const { userRole } = useSelector((state: RootState) => ({
    userRole: state.auth.userRole
  }));

  const sendReviewInvitationForResidentWithSms = (user: string) => {
    loader.show();
    sendReviewInvitationForResident({ user, sendInvitationSms: true }).finally(
      () => loader.hide()
    );
  };

  const sendReviewInvitationForResidentWithoutSms = (user: string) => {
    loader.show();
    sendReviewInvitationForResident({ user, sendInvitationSms: false }).finally(
      () => loader.hide()
    );
  };

  const removeFromGoogleReviewOutreach = (user: string) => {
    loader.show();

    removeFromReviewOutreach(user).finally(() => loader.hide());
    toast('Resident has been removed from future outreach');
  };

  /* UI Components */
  const createUserAction = () => {
    return userRole !== Roles.PM ? [
      <button
        className='btn-primary btn-standard bold'
        onClick={() => {
          isCreatePMUser.current = false;

          showHideUserCreateModal();
        }}
      >
        {CobuConstants.residentManagement.createUser}
      </button>,
      <button
        className='btn-primary btn-standard bold'
        onClick={() => {
          isCreatePMUser.current = true;

          showHideUserCreateModal();
        }}
      >
        {'Create PM'}
      </button>
    ] : [];
  };

  const getCobuTableInstance = (
    data: ResidentUIModel[],
    placeholder: string
  ) => {
    return (
      <CobuTableAdvanced
        columns={[
          ...residentTableConfig,
          ...(userRole !== Roles.PM
            ? [
              {
                id: 'more',
                Cell: (cell: CellProps<ResidentUIModel>) => (
                  <MoreActions
                    title={CobuConstants.residentManagement.actions}
                    options={
                      selectedUserStatusState === 'ACTIVE'
                        ? [
                          {
                            label:
                              CobuConstants.residentManagement.deactivate,
                            action: () => {
                              deactivateBuildingResident(
                                cell.row.original.uuid
                              );
                            }
                          },
                          {
                            label:
                              CobuConstants.residentManagement
                                .sendWelcomeMail,
                            action: () => {
                              welcomeMultipleBuildingResident([
                                cell.row.original.uuid
                              ]);
                            }
                          },
                          {
                            label:
                              CobuConstants.residentManagement
                                .sendReviewInvitationWithSms,
                            action: () => {
                              sendReviewInvitationForResidentWithSms(
                                cell.row.original.uuid
                              );
                            }
                          },
                          {
                            label:
                              CobuConstants.residentManagement
                                .sendReviewInvitationWithoutSms,
                            action: () => {
                              sendReviewInvitationForResidentWithoutSms(
                                cell.row.original.uuid
                              );
                            }
                          },
                          {
                            label:
                              CobuConstants.residentManagement
                                .removeFromReviewOutreach,
                            action: () => {
                              removeFromGoogleReviewOutreach(
                                cell.row.original.uuid
                              );
                            }
                          }
                        ]
                        : [
                          {
                            label:
                              CobuConstants.residentManagement.activate,
                            action: () => {
                              activateBuildingResident(
                                cell.row.original.uuid
                              );
                            }
                          }
                        ]
                    }
                  />
                )
              }
            ]
            : [])
        ]}
        data={data}
        noData={{
          label: placeholder,
          action: undefined,
          image: undefined
        }}
        search={{
          searchField: 'firstName',
          placeholder: 'Name'
        }}
        actions={createUserAction()}
        actionsStyle={{ top: '160px' }}
      />
    );
  };

  const createResidentModal = () => {
    return (
      residentState.showCreateModal && (
        <GenericModal
          showModal={residentState.showCreateModal}
          title={
            isCreatePMUser.current
              ? CobuConstants.residentManagement.createPMIn(
                selectedBuilding.name
              )
              : CobuConstants.residentManagement.createresidentsIn(
                selectedBuilding.name
              )
          }
          content={
            <CreateResidentForm
              formConfig={getCreateUserFormConfig(isCreatePMUser.current)}
              cancel={() => {
                showHideUserCreateModal();

                isCreatePMUser.current = false;
              }}
              createResident={(resident) => {
                createBuildingResident({
                  ...resident,
                  building: buildingId,
                  verified: true,
                  verifiedPhone: true,
                  isPMUser: isCreatePMUser.current,
                  markAsPMCommentor: resident.doNotDeactivate
                });
              }}
              checkboxTitle={
                isCreatePMUser.current
                  ? 'Make building user'
                  : CobuConstants.createResident.doNotDeactivate
              }
            />
          }
          hideActionButton
          canceActionButton
          actionText={CobuConstants.residentManagement.createActionTitle}
          actionFunction={() => { }}
          onChange={() => { }}
        />
      )
    );
  };

  if (residentState.loading)
    return (
      <div className={classes.loadingContainer}>
        <Spinner />
        <h3 style={{ textAlign: 'center' }}>{residentState.loadingString}</h3>
      </div>
    );

  return (
    <div className='content-container'>
      <Breadcrumbs />
      <h1>
        {CobuConstants.residentManagement.residentsIn(selectedBuilding.name)}
      </h1>
      <div className='margin-bottom-16' style={{ width: '340px' }}>
        <Tabs.Container
          value={selectedUserStatusState}
          onChange={setselectedUserStatusState}
        >
          <Tabs.Option
            selected={selectedUserStatusState == 'ACTIVE'}
            value='ACTIVE'
          >
            Active Residents
          </Tabs.Option>
          <Tabs.Option
            selected={selectedUserStatusState == 'DEACTIVATED'}
            value='DEACTIVATED'
          >
            Deactivated Residents
          </Tabs.Option>
        </Tabs.Container>
      </div>
      {selectedUserStatusState == 'ACTIVE'
        ? getCobuTableInstance(
          residentState.activeResidents,
          CobuConstants.residentManagement.noActiveUsers
        )
        : getCobuTableInstance(
          residentState.deactivatedResidents,
          CobuConstants.residentManagement.noDeactivatedUsers
        )}
      {userRole !== Roles.PM && <ActionDropDown
        title={CobuConstants.residentManagement.selectAction}
        options={
          selectedUserStatusState == 'ACTIVE'
            ? [
              {
                label: CobuConstants.residentManagement.deactivate,
                action: () => {
                  deactivateMultipleBuildingResident(
                    residentState.selectedIds.activeSelectedIds
                  );
                }
              },
              {
                label: CobuConstants.residentManagement.sendWelcomeMail,
                action: () => {
                  welcomeMultipleBuildingResident(
                    residentState.selectedIds.activeSelectedIds
                  );
                }
              }
            ]
            : [
              {
                label: CobuConstants.residentManagement.activate,
                action: () => {
                  activateMultipleBuildingResident(
                    residentState.selectedIds.deactivatedSelectedIds
                  );
                }
              }
            ]
        }
      />}
      <Spacer height={80} />
      {createResidentModal()}
    </div>
  );
};

export default withFullScreenLoader(ResidentManagement);
