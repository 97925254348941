import { useEffect, useMemo, useState } from 'react';
import {
  getQuestionnairResult,
  IAnswerMeta,
  IQuestionnaireQuestionWithAnswerValue
} from '~/api/questionnair';
import { QuestionsTypes, QuestionSubType } from '../constants';
import { indexOf, sortBy } from 'lodash';
import useGetBuildingDetails from '~/hooks/building/useGetBuildingDetails';
import { Building } from '~/types/building';

export default (questionnaireId: string, buildingId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questionWithAnswerValue, setQuestionWithAnswerValue] = useState<
    null | IQuestionnaireQuestionWithAnswerValue[]
  >(null);

  const buildingDetails: null | Partial<Building> =
    useGetBuildingDetails(buildingId);

  const fetchQuestionnaireResult = async () => {
    try {
      setIsLoading(true);
      const res = await getQuestionnairResult(questionnaireId);

      setIsLoading(false);
      setQuestionWithAnswerValue(res.data);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const {
    otherCCI,
    cci,
    questionWithAnswers,
    npsWithScores
  }: {
    cci?: Record<
      string,
      {
        value: number;
        total: number;
        meta: IAnswerMeta;
        stack: string;
        label?: string;
      }
    >;
    otherCCI?: Record<string, Record<string, { total: number }> | number>;
    questionWithAnswers?: {
      question: string;
      answers: { answer: string; id: string }[];
      id: string;
    }[];
    npsWithScores?: {
      id: string;
      question: string;
      totalRes: number;
      averageRating: number;
      totalPromoter: number;
      totalDetractor: number;
      npsScore: number;
    }[];
  } = useMemo(() => {
    let cci = {},
      otherCCI = {},
      other = {},
      nps = {};

    if (!questionWithAnswerValue) {
      return {
        cci: Object.values(cci),
        questionWithAnswers: Object.values(other),
        npsWithScores: Object.values(nps),
        otherCCI
      };
    }

    questionWithAnswerValue.forEach((qa) => {
      switch (qa.questionType) {
        case QuestionsTypes.NPS:
          if (qa.answerValue) {
            //@ts-ignore
            nps[qa.question_id] = {
              //@ts-ignore
              ...(nps[qa.question_id] || {}),
              id: qa.question_id,
              question: qa.question,
              //@ts-ignore
              totalRes: (nps[qa.question_id]?.totalRes || 0) + 1,
              averageRating:
                //@ts-ignore
                nps[qa.question_id]?.totalNpsSum &&
                //@ts-ignore
                nps[qa.question_id]?.totalRes
                  ? //@ts-ignore
                    nps[qa.question_id]?.totalNpsSum /
                    //@ts-ignore
                    nps[qa.question_id]?.totalRes
                  : 0,
              totalNpsSum:
                //@ts-ignore
                (nps[qa.question_id]?.totalNpsSum || 0) + qa.answerValue.value,
              ...(qa.answerValue.value >= 9
                ? {
                    //@ts-ignore
                    totalPromoter: (nps[qa.question_id]?.totalPromoter || 0) + 1
                  }
                : //@ts-ignore
                  { totalPromoter: nps[qa.question_id]?.totalPromoter || 0 }),
              ...(qa.answerValue.value <= 6
                ? {
                    totalDetractor:
                      //@ts-ignore
                      (nps[qa.question_id]?.totalDetractor || 0) + 1
                  }
                : //@ts-ignore
                  { totalDetractor: nps[qa.question_id]?.totalDetractor || 0 }),
              npsScore:
                //@ts-ignore
                nps[qa.question_id]?.totalRes
                  ? //@ts-ignore
                    ((nps[qa.question_id]?.totalPromoter || 0) /
                      //@ts-ignore
                      nps[qa.question_id]?.totalRes) *
                      100 -
                    //@ts-ignore
                    ((nps[qa.question_id]?.totalDetractor || 0) /
                      //@ts-ignore
                      nps[qa.question_id]?.totalRes) *
                      100
                  : 0
            };
          }
          break;
        case QuestionsTypes.OTHERS:
          //@ts-ignore
          other[qa.question_id] = {
            id: qa.question_id,
            question: qa.question,
            answers: [
              //@ts-ignore
              ...(other[qa.question_id]?.answers || []),
              {
                answer: qa.answer,
                id: qa.answerUuid
              }
            ]
          };
          break;
        case QuestionsTypes.CCI:
          if (
            qa.questionSubType === QuestionSubType.COMMUNITY_IMPORTANCE ||
            qa.questionSubType === QuestionSubType.OVERALL_SATISFACTION
          ) {
            //@ts-ignore
            otherCCI[qa.questionSubType] = {
              //@ts-ignore
              ...(otherCCI[qa.questionSubType] || {}),
              [qa.answer]: {
                //@ts-ignore
                ...(otherCCI[qa.questionSubType]?.answer || {}),
                total:
                  //@ts-ignore
                  (otherCCI[qa.questionSubType]?.[qa.answer]?.total || 0) + 1
              },
              overallTotal:
                //@ts-ignore
                (otherCCI[qa.questionSubType]?.overallTotal || 0) + 1
            };
            return;
          }

          //@ts-ignore
          cci[qa.questionSubType] = {
            //@ts-ignore
            ...(cci[qa.questionSubType] || {}),
            value:
              //@ts-ignore
              (cci[qa.questionSubType]?.value || 0) +
              (qa.answerValue?.value || 0),
            //@ts-ignore
            total: (cci[qa.questionSubType]?.total || 0) + 1,
            meta: qa.meta,
            stack: (buildingDetails as unknown as Building)?.name || 'Building',
            label: qa.questionSubType
          };
      }
    });

    return {
      npsWithScores: Object.values(nps),
      questionWithAnswers: Object.values(other),
      cci,
      otherCCI
    };
  }, [questionWithAnswerValue, (buildingDetails as unknown as Building)?.name]);

  const barChatData = useMemo(() => {
    if (!cci || !Object.keys(cci).length) {
      return null;
    }

    const cciKeyValues = Object.entries(cci);

    const filterKeys = [
      QuestionSubType.MEMBERSHIP,
      QuestionSubType.CONNECTION,
      QuestionSubType.INFLUENCE,
      QuestionSubType.NEEDS
    ];

    const sortedData = sortBy(cciKeyValues, ([key]) =>
      indexOf(filterKeys, key)
    );

    sortedData.unshift([
      QuestionSubType.NEEDS,
      {
        value: 9.1,
        total: 1,
        stack: 'Cobu Benchmark',
        label: QuestionSubType.NEEDS,
        meta: { maxValue: 18, scale: 25 }
      }
    ]);

    sortedData.unshift([
      QuestionSubType.INFLUENCE,
      {
        value: 7.2,
        total: 1,
        stack: 'Cobu Benchmark',
        label: QuestionSubType.INFLUENCE,
        meta: { maxValue: 18, scale: 25 }
      }
    ]);

    sortedData.unshift([
      QuestionSubType.CONNECTION,
      {
        value: 8.4,
        total: 1,
        stack: 'Cobu Benchmark',
        label: QuestionSubType.CONNECTION,
        meta: { maxValue: 18, scale: 25 }
      }
    ]);

    sortedData.unshift([
      QuestionSubType.MEMBERSHIP,
      {
        value: 5.9,
        total: 1,
        stack: 'Cobu Benchmark',
        label: QuestionSubType.MEMBERSHIP,
        meta: { maxValue: 18, scale: 25 }
      }
    ]);

    const chartData: Record<string, any> = {};

    sortedData.forEach(([key, data]) => {
      const {
        value,
        meta: { maxValue, scale },
        total,
        stack,
        label
      } = data;

      const barValue =
        maxValue && scale ? (value / (total * maxValue)) * scale : value;

      chartData[stack] = {
        //@ts-ignore
        ...(chartData[stack] || {}),
        label: stack,
        //@ts-ignore
        [label]: barValue % 1 === 0 ? barValue : barValue.toFixed(1)
      };
    });

    return chartData;
  }, [cci]);

  useEffect(() => {
    fetchQuestionnaireResult();
  }, []);

  return {
    isLoading,
    questionWithAnswerValue,
    barChatData,
    otherCCI,
    npsWithScores,
    questionWithAnswers,
    buildingName: (buildingDetails as unknown as Building)?.name || ''
  };
};
