import React from 'react';
import { toast } from 'react-toastify';
import Spacer from '~/components/Spacer/Spacer';
import { FormInputConfig } from '~/types/residentManagement';
import { IntroParagraphMarkups } from '../constants';

export enum QuestionnaireEmailMarkups {
  questionnaireLink = '{{questionnaireLink}}'
}

export const scheduleFormConfig: FormInputConfig[] = [
  {
    id: 'uuid',
    isRequired: false,
    inputType: 'text',
    displayNone: true
  },
  {
    id: 'schedule_option',
    label: 'User List',
    isRequired: true,
    inputType: 'select',
    selectOptions: [
      { label: 'all users (active user + tenants)', value: 'allUsers' },
      { label: 'active users', value: 'activeUsers' },
      { label: 'tenants', value: 'tenants' }
    ]
  },
  {
    id: 'schedule_time',
    label: 'Schedule time (9 AM Building Time)',
    isRequired: true,
    inputType: 'date'
  },
  {
    id: 'emailSubject',
    label: 'Email Subject',
    isRequired: true,
    inputType: 'text',
    sibling: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px 0',
          margin: '10px 0'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>{`Merge Fileds (tap to copy them):`}</div>
          <Spacer width={5} />
          {Object.values(IntroParagraphMarkups).map((mergeField) => (
            <div
              key={mergeField}
              className='cursor-pointer'
              onClick={() => {
                navigator.clipboard.writeText(`${mergeField}`);
                toast('Merge field copied!');
              }}
            >{`${mergeField}`}</div>
          ))}
        </div>
      </div>
    ),
    styles: {
      contentStyles: {
        display: 'flex',
        flexDirection: 'column-reverse'
      }
    }
  },
  {
    id: 'emailBody',
    label: 'Email Body',
    isRequired: true,
    inputType: 'textarea',
    sibling: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px 0',
          margin: '10px 0'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>{`Merge Fileds (tap to copy them):`}</div>
          <Spacer width={5} />
          {Object.values(QuestionnaireEmailMarkups).map((mergeField) => (
            <div
              key={mergeField}
              className='cursor-pointer'
              onClick={() => {
                navigator.clipboard.writeText(`${mergeField}`);
                toast('Merge field copied!');
              }}
            >{`${mergeField}`}</div>
          ))}
        </div>
      </div>
    ),
    styles: {
      contentStyles: {
        display: 'flex',
        flexDirection: 'column-reverse'
      }
    },
    validations: (emailBody: string) => {
      if (emailBody) {
        const isMergeFieldAdded = emailBody.includes(
          QuestionnaireEmailMarkups.questionnaireLink
        );

        if (isMergeFieldAdded) {
          return true;
        }

        return 'Please add questionnaire link merge field';
      }

      return true;
    }
  }
];
